(() => {
    'use strict';

    angular
        .module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('userManagementUpdate', {
                parent: 'base',
                url: '/userManagement/:token/Update/:extra',
                template: require('./UserManagementUpdate.html'),
                resolve: {
                    userData: ['$http', 'base', '$stateParams', function ($http, base, $stateParams) {
                        if (base) {
                            return $http.get('/UserManagement/GetUser?token=' + $stateParams.extra +
                                '&accModuleToken=' + $stateParams.token);
                        }
                    }],
                    userSettings: ['$http', 'base', '$stateParams', function ($http, base, $stateParams) {
                        if (base) {
                            return $http.get('/UserManagement/GetSettings?token=' + $stateParams.token);
                        }
                    }]
                },
                controller: 'UserManagementUpdateController as UserManagementUpdateCtrl'
            });
        }])
        .controller('UserManagementUpdateController', ['$rootScope', '$scope', '$stateParams', 'Page',
            'userSettings', 'userData', '$translate', '$http', 'ToastFactory', 'ModalService', 'BasicHelper',
            'events', '$filter', 'ConfirmPopupService', 'UserManagementService',
            function ($rootScope, $scope, $stateParams, Page, userSettings, userData, $translate, $http, ToastFactory,
                      ModalService, BasicHelper, events, $filter, ConfirmPopupService,
                      UserManagementService) {
                var ctrl = this;

                $scope.pickPhoneCode = pickPhoneCode;
                $scope.sendLoginInfo = sendLoginInfo;
                $scope.deleteUser = deleteUser;
                $scope.approveUser = approveUser;
                $scope.declineUser = declineUser;

                // Run Init
                InitController();

                function InitController() {
                    // Show the form to be submitable
                    $rootScope.ShowFormSubmit = true;
                    // Reset validation data
                    $scope.validationData = [];

                    // Hook up to for submit event
                    var userSubmit = $rootScope.$on('GlobalFormSubmit', function () {
                        $scope.validationData = UserManagementService.validate($scope.renderModel, $scope.settings.Settings);
                        if ($scope.validationData.length === 0) {
                            saveUser();
                        }
                    });
                    var userCancel = $rootScope.$on('GlobalFormCancel', function () {
                        Page.stateGoPrevious('userManagement', $stateParams.token);
                    });
                    $scope.$on('$destroy', userSubmit);
                    $scope.$on('$destroy', userCancel);

                    $translate('USER_MANAGEMENT.UPDATE_TITLE').then(function (translation) {
                        Page.setTitle(translation);
                    });
                    Page.trackPageView();

                    $scope.renderModel = {
                        UserToken: null,
                        AccountModuleToken: null,
                        Name: '',
                        Title: '',
                        Alias: '',
                        Phone: '',
                        PhoneCountryId: 1,
                        PhoneCode: 45,
                        Email: '',
                        UserGroups: [],
                        AdditionalDepartments: []
                    };

                    // handle custom user alias label (can be defined under UserModuleSettings)
                    if (!(userSettings.data.UserAliasLabel)) {
                        $translate('USER_MANAGEMENT.ALIAS').then(function (translation) {
                            userSettings.data.UserAliasLabel = translation;
                        });
                    }

                    // add "formatet" array with job titles
                    $scope.settings = userSettings.data;
                    $scope.User = userData.data.User;
                    $scope.AllowDelete = userData.data.AllowDelete;

                    $scope.departmentSettings = {
                        departments: userSettings.data.AllowedDepartments
                    };

                    const selectableRoles =
                        userSettings.data.Settings.SelectableRoles?.length
                            ? userSettings.data.Settings.SelectableRoles
                            : userSettings.data.Settings.DefaultRoleList;

                    let lockedGroups = [];

                    if (userData.data.User.ImportedUserGroups?.length) {
                        lockedGroups = lockedGroups.concat(userData.data.User.ImportedUserGroups);
                    }
                    if (userSettings.data.Settings.DefaultRoleList) {
                        lockedGroups = lockedGroups.concat(userSettings.data.Settings.DefaultRoleList);
                    };

                    lockedGroups = lockedGroups.filter(value => $scope.User.SelectedUserGroups?.includes(value));

                    $scope.userGroupSettings = {
                        usergroups: userSettings.data.AllowedUserGroups,
                        locked: lockedGroups,
                        selectableRoles: selectableRoles
                    };

                    // Map properties to renderModel
                    $scope.renderModel.UserToken = $scope.User.UserToken;
                    $scope.renderModel.AccountModuleToken = $stateParams.token;
                    $scope.renderModel.Name = $scope.User.Name;
                    $scope.renderModel.Title = $scope.User.Title;
                    $scope.renderModel.Alias = $scope.User.Alias;
                    $scope.renderModel.Email = $scope.User.Email;
                    $scope.renderModel.PhoneCountryId = $scope.User.PhoneCountryId;
                    $scope.renderModel.Phone = $scope.User.Phone;
                    $scope.renderModel.PhoneCode = $scope.User.PhoneCode;
                    $scope.renderModel.UserGroups = $scope.User.SelectedUserGroups;
                    $scope.renderModel.DepartmentId = $scope.User.DepartmentId;
                    $scope.renderModel.AdditionalDepartments = $scope.User.AdditionalDepartments;

                    // Listen for events
                    $scope.$on(events.DEPARTMENT_CHANGED, function () {
                        Page.stateGoBack('userManagement', $stateParams.token);
                    });
                }

                // Methods
                function pickPhoneCode() {
                    ModalService.showModal({
                        templateUrl: '/Profile/PhoneCodePicker',
                        controller: 'PhoneCodePickerController',
                        inputs: {countryId: $scope.renderModel.PhoneCountryId}
                    }).then(function (modal) {
                        // Returns the modal for further options if needed
                        modal.scope.$watch('selectedCountry', function (newVal) {
                            if (newVal !== null) {
                                $scope.renderModel.PhoneCountryId = newVal.CountryId;
                                $scope.renderModel.PhoneCode = newVal.PhoneCode;
                            }
                        });
                    });
                }

                function sendLoginInfo() {
                    if (!$scope.isSaving) {
                        $scope.isSaving = true;

                        var opts = {
                            title: $filter('translate')('USER_MANAGEMENT.SEND_LOGIN_INFO'),
                            message: $filter('translate')('CONFIRM.ARE_YOU_SURE')
                        };

                        ConfirmPopupService.open(opts).then(function () {
                            // Confirmed
                            Page.startLoading();
                            $http.post('/UserManagement/SendInfo', {
                                token: $scope.renderModel.UserToken,
                                accModuleToken: $scope.renderModel.AccountModuleToken
                            }).then(function (response) {
                                var successMessage = $filter('translate')('USER_MANAGEMENT.SEND_LOGIN_INFO_DONE');
                                $scope.isSaving = false;
                                Page.stopLoading();
                                ToastFactory.success(successMessage);
                            }, function (errorBody) {
                                var errorMessage = $filter('translate')(errorBody.data[0].Message);
                                $scope.isSaving = false;
                                Page.stopLoading();
                                ToastFactory.error(errorMessage);
                            });
                        }, function () {
                            // Cancelled
                            $scope.isSaving = false;
                        });
                    }
                }

                function deleteUser() {
                    if (!$scope.isSaving) {
                        $scope.isSaving = true;

                        BasicHelper.translateStrings(
                            ['USER_MANAGEMENT.DELETE_USER', 'USER_MANAGEMENT.DELETE_USER_CONFIRM', 'OK', 'CANCEL']
                        ).then(function (translations) {

                            var opts = {
                                title: translations[0],
                                message: translations[1],
                                yesText: translations[2],
                                noText: translations[3]
                            };

                            ConfirmPopupService.open(opts).then(function () {
                                // Confirmed
                                Page.startLoading();
                                $http.get('/UserManagement/DeleteUser?token=' + $scope.User.UserToken +
                                    '&accModuleToken=' + $stateParams.token).then(function (response) {
                                    Page.stopLoading();
                                    if (response.data) {
                                        $translate('USER_MANAGEMENT.DELETED').then(function (translation) {
                                            ToastFactory.success(translation);
                                        });
                                        Page.stateGoPrevious('userManagement', $stateParams.token);
                                    } else {
                                        $scope.isSaving = false;
                                        $translate('ERROR.GENERAL').then(function (translation) {
                                            ToastFactory.error(translation);
                                        });
                                    }
                                }, function () {
                                    Page.stopLoading();
                                    $scope.isSaving = false;
                                    $translate('ERROR.GENERAL').then(function (translation) {
                                        ToastFactory.error(translation);
                                    });
                                });
                            }, function () {
                                // Cancelled
                                $scope.isSaving = false;
                            });
                        });
                    }
                }

                function saveUser() {
                    if (!$scope.isSaving) {
                        $scope.isSaving = true;
                        Page.startLoading();

                        $http.post('/UserManagement/SaveUser', $scope.renderModel).then(function (response) {
                            $scope.isSaving = false;
                            Page.stopLoading();

                            // Success, user saved
                            ToastFactory.successTranslated('USER_MANAGEMENT.SAVED');
                            Page.stateGoPrevious('userManagement', $stateParams.token);

                        }, function (response) {
                            $scope.isSaving = false;
                            Page.stopLoading();

                            // Failed
                            if (response.status === 400) {
                                // Invalid data
                                $scope.validationData = response.data;
                                ToastFactory.warningTranslatedReplace('INPUT_ERROR');
                            } else {
                                // Unknown error
                                ToastFactory.errorTranslated('ERROR.GENERAL');
                            }
                        });
                    }
                }

                function approveUser() {
                    UserManagementService.approveUser($scope.User.UserToken)
                        .then(() => {
                            Page.stateGoPrevious('userManagement', $stateParams.token)
                            $translate('USER_MANAGEMENT.APPROVE_MESSAGE').then(text => {
                                ToastFactory.success(text + ' ' + $scope.User.Name);
                            })
                        })
                }

                function declineUser() {
                    UserManagementService.declineUser($scope.User.UserToken)
                        .then(() => {
                            Page.stateGoPrevious('userManagement', $stateParams.token);
                            $translate('USER_MANAGEMENT.DECLINE_MESSAGE').then(text => {
                                ToastFactory.warning($scope.User.Name + ' ' + text);
                            })
                        })
                }
            }
        ]);

})();
