(function () {
    'use strict';

    angular.module('App')
        .component('addUsers', {
            template: require('./AddUsersComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$element', 'ChatPopupsService', 'ChatConnectionService', 'ChatDataService',
                'Profile', AddUsersController],
            bindings: {
                channel: '<',
                closePrevious: '<'
            }
        });

    function AddUsersController($rootScope, $element, ChatPopupsService, ChatConnectionService, ChatDataService,
                                Profile) {
        let ctrl = this, popup;
        const currentUserToken = Profile.getProfile().UserToken,
            pushOpenWatcher = $rootScope.$on('CHAT.OPEN_FROM_PUSH', () => close());

        ctrl.fetching = false;
        ctrl.selectedUsers = [];
        ctrl.excludeUsersIds = [
            ...ctrl.channel.members
            .filter(member => member.userId !== currentUserToken)
            .map(member => member.userId),
            ...ctrl.channel.blockedUsers
        ];

        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onOpenPopup = onOpenPopup;
        ctrl.close = close;
        ctrl.addUsers = addUsers;
        ctrl.loadUsers = loadUsers;

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function onOpenPopup() {
            ctrl.users = loadUsers(0, 100, null, ctrl.excludeUsersIds).then(function (resp) {
                ctrl.moreUsers = resp.MoreUsers;

                return resp.Users
            });
        }

        function loadUsers(offset, limit, name, usersToExclude) {
            return ChatConnectionService.getAllUsers(offset, limit, name, usersToExclude).then(resp => {
                ctrl.usersLoading = false;
                return resp;
            });
        }

        function addUsers() {
            if (!ctrl.fetching) {
                ctrl.fetching = true;
                if (ctrl.channel.item.isGroupChat) {
                    ChatDataService
                        .addUsersToChannel(ctrl.channel.item.channelId, ctrl.selectedUsers.map(user => user.userId), ctrl.shareHistory)
                        .then(function () {
                            ctrl.fetching = false;
                            close();
                        })
                        .catch(function (e) {
                            ctrl.fetching = false;
                        });
                } else {
                    ChatDataService
                        .createChannel([...ctrl.channel.members.filter(member => member.userId !== currentUserToken),
                            ...ctrl.selectedUsers])
                        .then(function (channelId) {
                            closeAll();
                            $rootScope.$broadcast('CHAT.CHANNEL_CREATED', channelId);
                            ctrl.fetching = false;
                        })
                        .catch(function (e) {
                            ctrl.fetching = false;
                        });
                }
            }
        }

        function close() {
            pushOpenWatcher();
            popup.remove();
            $element.remove();
        }

        function closeAll() {
            ctrl.closePrevious && ctrl.closePrevious();
            close();
        }
    }
})();