(() => {
    'use strict'

    angular
        .module('App')
        .component('bookmarksList', {
            template: require('./BookmarksListComponent.tpl.html'),
            controller: ['ActionSheetService', 'BookmarkService', 'TimeLocale', 'Page', 'ToastFactory',
                BookmarksListController],
            controllerAs: 'ctrl',
            bindings: {
                isPopup: '<'
            }

        });

    function BookmarksListController(ActionSheetService, BookmarkService, TimeLocale, Page, ToastFactory) {
        const ctrl = this;

        ctrl.openBookmark = openBookmark;
        ctrl.showBookmarkOptions = showBookmarkOptions;
        ctrl.getIcon = getIcon;
        ctrl.BookmarkService = BookmarkService;
        ctrl.TimeLocale = TimeLocale;

        function openBookmark(bookmark) {
            if (bookmark.Link && bookmark.Link.PathState) {
                if (bookmark.Link.Active) {
                    Page.stateGo(bookmark.Link.PathState, bookmark.Link.PathToken, bookmark.Link.PathExtra);
                    if (ctrl.closePopup) {
                        ctrl.closePopup();
                    } else {
                        BookmarkService.toggleDropdownVisibility(false);
                    }
                } else {
                    ToastFactory.errorTranslated('ERROR.CONTENT.ITEM_NOT_FOUND');
                }
            }
        }

        function showBookmarkOptions(bookmark, event) {
            var buttons = [
                {
                    text: 'BOOKMARK.DELETE',
                    icon: 'delete',
                    iconClass: 'red',
                    onClick: function () {
                        deleteBookmark(bookmark);
                    }
                }];

            var actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, {name: 'bookmark'});
            actionSheet.show();
        }

        function deleteBookmark(bookmark) {
            BookmarkService.deleteBookmark(bookmark.BookmarkId);
        }

        function getIcon(status) {
            switch (status) {
                case 1:
                    return 'bookmark-published';
                case 4:
                    return 'bookmark-unpublished';
                case -1:
                    return 'bookmark-expired';
                case -2:
                    return 'bookmark-archived';

            }
        }
    }
})();