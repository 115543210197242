(() => {
    'use strict';

    angular
        .module('App')
        .component('taskManagement', {
            template: require('./TaskManagementComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', '$rootScope', '$scope', '$element', 'TaskManagementRenderService',
                'TaskDetailsService', 'TemplateFactory', 'HeaderButtonsFactory', 'CreateUpdateTaskService',
                'FilterTasksService', 'Page', 'ModuleSearchService', '$timeout', 'HowToGuideService',
                'ResponsiveService', TaskManagementController],
            bindings: {
                issues: '<',
                filteredIssues: '<',
                nextOffset: '<',
                categories: '<',
                overdueIssuesToShow: '<',
                feedLink: '<',
                moderateAllowed: '<',
                onSwitchView: '<',
                allowManagerView: '<',
                selectedDepartmentName: '@',
                enableHowToGuide: '<',
                showOverdueViewInMyTasks: '<',
                setDefaultBackButton: '<'
            }
        });

    function TaskManagementController($stateParams, $rootScope, $scope, $element, TaskManagementRenderService, TaskDetailsService,
        TemplateFactory, HeaderButtonsFactory, CreateUpdateTaskService, FilterTasksService, Page,
        ModuleSearchService, $timeout, HowToGuideService, ResponsiveService) {
        const ctrl = this;
        let scrolledContainer, throttledScroll = _.throttle(scrollEvents, 500),
            taskUpdateListener, taskCreateListener, taskDeleteListener, taskFilterListener,
            taskManagementStateChangeListener, taskSeriesDeleteListener;


        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.onScrollNext = onScrollNext;
        ctrl.onScrollPrev = onScrollPrev;
        ctrl.onScrollNextFilter = onScrollNextFilter;
        ctrl.onScrollPrevFilter = onScrollPrevFilter;
        ctrl.changeView = changeView;
        ctrl.openCreateTask = openCreateTask;
        ctrl.openOverdue = openOverdue;
        ctrl.openFilter = openFilter;
        ctrl.removeFilters = removeFilters;

        function init() {
            ctrl.appliedFiltersCount = 0;
            ctrl.isDesktop = ResponsiveService.isDesktop();

            ctrl.VIEW = {
                CALENDAR: 1,
                TASKS: 2,
                MY_TASKS: 3,
                SEARCH: 4,
                OVERDUE: 5,
                MANAGER: 6
            };

            ctrl.initialModel = {
                Token: $stateParams.token,
                StartTime: moment().subtract(1, 'months'),
                EndTime: moment().add(7, 'd'),
                SeparateOverdueTasks: true
            }

            ctrl.currentView = ctrl.VIEW.MY_TASKS;
            ctrl.foundTasksFromFiltering = true;

            ctrl.processing = true;
            ctrl.processingOverdueTab = true;
            ctrl.processingCalendarTab = true;
            ctrl.isOverdueProcessed = false;
            ctrl.isCalendarProcessed = false;
            ctrl.selectedDayMyTasks = [];
            ctrl.selectedDayOverlookingTasks = [];
            ctrl.currentView = ctrl.VIEW.MY_TASKS;
            ctrl.serviceFormPopupId = TaskDetailsService.serviceFormPopupId;
            ctrl.isNative = TemplateFactory.isNative();
            ctrl.calendarData = {};

            initListeners();
            loadTasks().then(function () {
                if (ctrl.isNative || ctrl.isDesktop) {
                    HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                }
            });

            scrolledContainer = $element.find('.view-type-today');
            scrolledContainer.on('scroll', throttledScroll);

            $scope.$watch('ctrl.selectedDay', day => {
                if (!ctrl.hasFilter) {
                    day?.issues.sort(function (a, b) {
                        return a.IsCompletedByCurrentUser - b.IsCompletedByCurrentUser ||
                            new Date(a.EndTimeLocal) - new Date(b.EndTimeLocal);
                    });
                    showCalendarDay(day);
                }
            }, true)

            $scope.$watch('ctrl.selectedFilteredDay', day => {
                if (ctrl.hasFilter) {
                    showCalendarDay(day);
                }
            }, true)
        }

        function initListeners() {
            taskUpdateListener = $rootScope.$on('TaskManagement:taskUpdated', (ev, issue, resort) => {
                taskUpdated(issue, resort);
            });
            taskCreateListener = $rootScope.$on('TaskManagement:taskCreated', () => {
                Page.stateReload();
            });
            taskFilterListener = $rootScope.$on('TaskManagement:tasksFiltered', (ev, response, model, filtersCount, isReset) => {
                handleFiltering(response, model, filtersCount, isReset);
            });
            taskDeleteListener = $rootScope.$on('TaskManagement:taskDeleted', (ev, issue) => {
                taskDeleted(issue);
            });
            taskSeriesDeleteListener = $rootScope.$on('TaskManagement:taskSeriesDeleted', (ev, issue) => {
                taskSeriesDeleted(issue);
            });
            taskManagementStateChangeListener = $scope.$on('TaskManagement:stateChanged', () => {
                if (ctrl.currentView === ctrl.VIEW.OVERDUE) {
                    Page.showBackButtonFunction(() => {
                        ctrl.changeView(ctrl.VIEW.MY_TASKS);
                    });
                } else {
                    ctrl.setDefaultBackButton && ctrl.setDefaultBackButton();
                }
            });
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
            ModuleSearchService.disableSearch();
            taskUpdateListener();
            taskCreateListener();
            taskFilterListener();
            taskDeleteListener();
            taskManagementStateChangeListener();
            taskSeriesDeleteListener();
        }

        function loadTasks() {
            return TaskManagementRenderService.getAllTasks(ctrl.initialModel).then(resp => {
                const overdueIssues = getCompletableOverdueIssues(resp.OverdueTasks)
                ctrl.issues = resp.Tasks;
                ctrl.overdueIssuesToShow = TaskManagementRenderService.mergeIssuesWithCategories(overdueIssues);
            }).finally(() => {
                generateCalendar(ctrl.issues);
            });
        }

        function loadMoreTasks() {
            if (ctrl.loadingMoreTasks || ctrl.hasFilter) {
                return;
            }

            ctrl.loadingMoreTasks = true;

            const newStartTime = moment(ctrl.endTime).add(1, 'days'),
                newEndTime = moment(newStartTime).add(7, 'days'),
                moreTasksModel = {
                    Token: $stateParams.token,
                    StartTime: newStartTime,
                    EndTime: newEndTime,
                    SeparateOverdueTasks: true
                }

            ctrl.startTime = newStartTime;
            ctrl.endTime = newEndTime;

            return TaskManagementRenderService.getAllTasks(moreTasksModel).then(resp => {
                resp.Tasks.forEach(task => {
                    const index =
                        ctrl.issues.findIndex(issue => issue.IssueId === task.IssueId);
                    if (index === -1) {
                        ctrl.issues.push(task);
                    }
                });
                ctrl.loadingMoreTasks = false;
            }).catch(() => {
                ctrl.loadingMoreTasks = false;
            })
        }

        function scrollEvents() {
            if (scrolledContainer.scrollTop() + scrolledContainer.innerHeight() > scrolledContainer[0].scrollHeight - 50) {
                $timeout(() => {
                    loadMoreTasks();
                }, 50)
            }
        }

        function generateCalendar(issues) {
            TaskManagementRenderService.generateCalendar(issues, ctrl.categories, true)
                .then(calendarData => {
                    ctrl.calendarData = calendarData;
                    ctrl.processing = false;
                })
                .catch(() => {
                    ctrl.processing = false;
                });
        }

        function getCompletableOverdueIssues(tasks) {
            return tasks.filter(issue => issue.IsUserAllowedToCompleteIssue);
        }

        function prepareOverdueTab() {
            if (!ctrl.isOverdueProcessed) {
                ctrl.processingOverdueTab = true;
                $timeout(() => {
                    ctrl.processingOverdueTab = false;
                    ctrl.isOverdueProcessed = true;
                }, 10);
            }
        }

        function prepareCalendarTab() {
            if (!ctrl.isCalendarProcessed) {
                ctrl.processingCalendarTab = true;

                $timeout(() => {
                    if (ctrl.hasFilter) {
                        ctrl.processingCalendarTab = false;
                    } else {
                        TaskManagementRenderService.fillCalendarWithIssues(ctrl.calendarData).then(function () {
                            ctrl.processingCalendarTab = false;
                            ctrl.isCalendarProcessed = true;
                        }).catch(() => {
                            ctrl.processingCalendarTab = false;
                            ctrl.isCalendarProcessed = true;
                        });
                    }
                }, 10);
            }
        }

        function getHeaderButtons() {
            const subItems = [
                {
                    title: 'TASK_MANAGEMENT.MENU.MY_TASKS',
                    onClick: () => {
                        ctrl.changeView(ctrl.VIEW.MY_TASKS);
                    },
                    active: ctrl.currentView === ctrl.VIEW.MY_TASKS
                },
                {
                    title: 'TASK_MANAGEMENT.MENU.MONTHLY',
                    onClick: openCalendar,
                    active: ctrl.currentView === ctrl.VIEW.CALENDAR
                },
                {
                    title: 'SEARCH.TITLE',
                    onClick: () => {
                        ctrl.changeView(ctrl.VIEW.SEARCH)
                    },
                    active: ctrl.currentView === ctrl.VIEW.SEARCH
                }
            ];

            if (ctrl.allowManagerView) {
                subItems.push({
                    title: 'TASK_MANAGEMENT.MENU.MANAGER_VIEW',
                    onClick: () => {
                        ctrl.changeView(ctrl.VIEW.MANAGER);
                    },
                    active: ctrl.currentView === ctrl.VIEW.MANAGER
                })
            }

            if (ctrl.enableHowToGuide && ctrl.isNative) {
                subItems.push({
                    title: 'TASK_MANAGEMENT.MENU.HOW_TO',
                    onClick: () => {
                        HowToGuideService.renderHowToGuidePopup($stateParams.token);
                    }
                })
            }

            if (ctrl.isDesktop) {
                subItems.push({
                    title: 'TASK_MANAGEMENT.FILTER.FILTERS',
                    onClick: () => {
                        ctrl.openFilter();
                    },
                    badges: ctrl.appliedFiltersCount
                });
            }

            const definition = [
                {
                    icon: 'arrow-bottom',
                    activeIcon: 'arrow-top',
                    caption: 'TASK_MANAGEMENT.MENU.TITLE',
                    place: 'title',
                    items: subItems,
                    badges: !!(ctrl.isDesktop && ctrl.appliedFiltersCount && ctrl.appliedFiltersCount.length)
                }
            ];

            if (!ctrl.isDesktop && ctrl.currentView !== ctrl.VIEW.OVERDUE) {
                definition.push({
                    icon: 'filter',
                    onClick: () => {
                        ctrl.openFilter();
                    },
                    badges: ctrl.appliedFiltersCount
                });
            }

            return definition;
        }

        function changeView(view) {
            ctrl.prevView = ctrl.currentView !== ctrl.VIEW.SEARCH ? ctrl.currentView : ctrl.prevView;
            ctrl.currentView = view;

            ctrl.setDefaultBackButton();
            HeaderButtonsFactory.createButtonsList(getHeaderButtons);

            var translate = '';
            switch (view) {
                case ctrl.VIEW.MY_TASKS:
                    translate = 'TASK_MANAGEMENT.MENU.MY_TASKS';
                    break;
                case ctrl.VIEW.CALENDAR:
                    translate = 'TASK_MANAGEMENT.MENU.MONTHLY';
                    break;
                case ctrl.VIEW.OVERDUE:
                    translate = 'TASK_MANAGEMENT.MENU.OVERDUE';
                    break;
                case ctrl.VIEW.SEARCH:
                    translate = 'SEARCH.TITLE';
                    break;
                case ctrl.VIEW.MANAGER:
                    translate = 'TASK_MANAGEMENT.MENU.MANAGER_VIEW';
                    break;
            }

            ctrl.onSwitchView(translate, view);
        }

        function onScrollPrev(data) {
            return TaskManagementRenderService.getPreviousMonth(data, true);
        }

        function onScrollNext(data) {
            return TaskManagementRenderService.getNextMonth(data, true);
        }

        function onScrollPrevFilter(data) {
            return TaskManagementRenderService.getPreviousMonth(data, false, ctrl.filteredIssues);
        }

        function onScrollNextFilter(data) {
            return TaskManagementRenderService.getNextMonth(data, false, ctrl.filteredIssues);
        }

        function openCreateTask() {
            CreateUpdateTaskService.openCreateUpdatePopup();
        }

        function openFilter() {
            FilterTasksService.openFilterTasksPopup(ctrl.currentFilterModel);
        }

        function handleFiltering(data, model, filtersCount, isReset) {
            if (isReset) {
                removeFilters();
                return;
            }

            ctrl.currentFilterModel = model;
            ctrl.filteredIssues = data.Tasks.filter(issue => issue);
            ctrl.filteredOverlookingIssues = data.Tasks.filter(issue => !issue.IsUserAllowedToCompleteIssue);
            ctrl.nextOffset = data.NextOffset;
            ctrl.selectedFilteredDay = null;
            ctrl.selectedDayMyTasks = [];
            ctrl.selectedDayOverlookingTasks = [];
            ctrl.hasFilter = true;

            if (ctrl.isCalendarProcessed) {
                ctrl.processingCalendarTab = true;
            }

            TaskManagementRenderService
                .generateCalendar(ctrl.filteredIssues, ctrl.categories, true, model.StartTime, model.EndTime)
                .then(calendarData => {
                    ctrl.filteredCalendarData = calendarData;

                    if (ctrl.isCalendarProcessed) {
                        ctrl.processingCalendarTab = false;
                    }
                })
                .catch(() => {
                    if (ctrl.isCalendarProcessed) {
                        ctrl.processingCalendarTab = false;
                    }
                });

            ctrl.filteredIssues = data.Tasks.filter(issue => issue.IsUserAllowedToCompleteIssue);

            ctrl.foundTasksFromFiltering = data.Tasks.length;

            ctrl.appliedFiltersCount = filtersCount;
            HeaderButtonsFactory.createButtonsList(getHeaderButtons);
        }

        function removeFilters() {
            ctrl.selectedDayMyTasks = [];
            ctrl.selectedDayOverlookingTasks = [];
            ctrl.hasFilter = false;
            ctrl.currentFilterModel = null;

            ctrl.appliedFiltersCount = 0;
            HeaderButtonsFactory.createButtonsList(getHeaderButtons);

            if (!ctrl.isCalendarProcessed && ctrl.currentView === ctrl.VIEW.CALENDAR) {
                // Prepare the calendar as it has not been done before
                prepareCalendarTab();
            } else {
                showCalendarDay(ctrl.selectedDay);
            }
        }

        function showCalendarDay(day) {
            if (day) {
                ctrl.selectedDayMyTasks = day.issues.filter(issue => issue.IsUserAllowedToCompleteIssue);
                ctrl.selectedDayOverlookingTasks = day.issues.filter(issue => !issue.IsUserAllowedToCompleteIssue);
            }
        }

        function openCalendar() {
            ctrl.changeView(ctrl.VIEW.CALENDAR);
            prepareCalendarTab();
        }

        function openOverdue() {
            ctrl.changeView(ctrl.VIEW.OVERDUE);

            Page.showBackButtonFunction(function () {
                ctrl.changeView(ctrl.VIEW.MY_TASKS);
            });

            prepareOverdueTab();
        }

        function taskUpdated(issue, resort) {
            ctrl.issues = updateIssue(ctrl.issues, issue);
            TaskManagementRenderService.updateIssuesToDays(ctrl.calendarData.flatDays, [issue]);

            if (ctrl.hasFilter && ctrl.filteredIssues && ctrl.filteredIssues.length) {
                ctrl.filteredIssues = updateIssue(ctrl.filteredIssues, issue);
                TaskManagementRenderService.updateIssuesToDays(ctrl.filteredCalendarData.flatDays, [issue]);
            }

            if (!issue.IsOverdue) {
                _.remove(ctrl.overdueIssuesToShow, { IssueId: issue.IssueId });
            } else {
                ctrl.overdueIssuesToShow = updateIssue(ctrl.overdueIssuesToShow, issue);
            }

            if (resort) {
                ctrl.issues.sort(function (a, b) {
                    return a.IsCompletedByCurrentUser - b.IsCompletedByCurrentUser ||
                        new Date(a.EndTimeLocal) - new Date(b.EndTimeLocal);
                });
            }
        }

        function updateIssue(issues, issue) {
            const issueIndex = issues.findIndex(i => i.IssueId === issue.IssueId);

            if (issueIndex || issueIndex === 0) {
                issues[issueIndex] = issue;
            }

            return issues;
        }

        function taskDeleted(issue) {
            _.remove(ctrl.issues, { IssueId: issue.IssueId });
            _.remove(ctrl.overdueIssuesToShow, { IssueId: issue.IssueId });
            TaskManagementRenderService.removeIssuesFromDays(ctrl.calendarData.flatDays, [issue], false);

            if (ctrl.hasFilter && ctrl.filteredIssues && ctrl.filteredIssues.length) {
                _.remove(ctrl.filteredIssues, { IssueId: issue.IssueId });
                TaskManagementRenderService.removeIssuesFromDays(ctrl.filteredCalendarData.flatDays, [issue], false);
            }
        }

        function taskSeriesDeleted(issue) {
            _.remove(ctrl.issues, { ParentIssueId: issue.ParentIssueId });
            _.remove(ctrl.overdueIssuesToShow, { ParentIssueId: issue.ParentIssueId });
            TaskManagementRenderService.removeIssuesFromDays(ctrl.calendarData.flatDays, [issue], true);
            if (ctrl.hasFilter && ctrl.filteredIssues && ctrl.filteredIssues.length) {
                _.remove(ctrl.filteredIssues, { IssueId: issue.ParentIssueId });
                TaskManagementRenderService.removeIssuesFromDays(ctrl.filteredCalendarData.flatDays, [issue], true);
            }
        }
    }
})();
