(function () {
    'use strict';

    angular
        .module('App')
        .constant('FILLING_VIEW', {
            'FORM': 1,
            'FEEDBACK': 2,
            'SCORE': 3
        })
        .factory('FillingPreviewService', ['$q', '$http', 'PopupWrapperService', 'ActionSheetCustomService',
            'ServiceFormRenderService', 'TranslationService', 'ReactionsService',
            function ($q, $http, PopupWrapperService, ActionSheetCustomService, ServiceFormRenderService,
                      TranslationService, ReactionsService) {

                return {
                    approveStage: approveStage,
                    declineStage: declineStage,
                    openReactionsViewer: openReactionsViewer,
                    openCommentsPopup: openCommentsPopup,
                    openStageFeedbackPopup: openStageFeedbackPopup,
                    showWantRate: showWantRate,
                    showRatePopup: showRatePopup,
                    createFeedback: createFeedback,
                    rateReport: rateReport,
                    openFormFeedbackComments: openFormFeedbackComments,
                    onStageClick: onStageClick,
                    initTranslationSettings: initTranslationSettings,
                    toggleTranslation: toggleTranslation,
                    translatePdf: translatePdf,
                    follow: follow,
                    unfollow: unfollow,
                };

                function approveStage(stageToken, fillingId, comment) {
                    return $http.post('/ServiceForm/ApproveStage', {
                        stageToken: stageToken,
                        fillingId: fillingId,
                        comment: comment
                    })
                }

                function declineStage(stageToken, fillingId, comment) {
                    return $http.post('/ServiceForm/RejectStage', {
                        stageToken: stageToken,
                        fillingId: fillingId,
                        comment: comment
                    })
                }

                function openReactionsViewer(settings, token) {
                    ReactionsService.openReactionsViewer(token, true, 'ServiceForm')
                }

                function openCommentsPopup(filling, fillingToken, commentId, moduleToken) {
                    PopupWrapperService.createDynamic(
                        `<service-form-comments-popup
                                        filling="filling"
                                        filling-token="fillingToken"
                                        stage-token="stageToken"
                                        comment-id="commentId"
                                        module-token="moduleToken"
                                    ></service-form-comments-popup>`,
                        {
                            filling: filling,
                            fillingToken: fillingToken,
                            stageToken: filling.ServiceFormStageToken,
                            commentId: commentId,
                            moduleToken: moduleToken
                        }
                    )
                }

                function openStageContentPopup(stage, data, onStageUpdate) {
                    PopupWrapperService.createDynamic(
                        `<stage-content-popup 
                                        stage="stage"
                                        filling="filling"
                                        on-stage-update="onStageUpdate"
                                        feedback-data="feedbackData"
                                        page-navigation="pageNavigation"
                                        fields-with-rule="fieldsWithRule"
                                    ></stage-content-popup>`,
                        {
                            stage: stage,
                            filling: data.Filling,
                            onStageUpdate: onStageUpdate,
                            feedbackData: data.FeedbackData,
                            pageNavigation: data.PageNavigation,
                            fieldsWithRule: data.FieldsWithRuleActionsToLimitDataListUsage
                        }
                    )
                }

                function openStageFeedbackPopup(stage, fillingToken) {
                    PopupWrapperService.createDynamic(
                        `<stage-feedback-popup 
                                        stage="stage" 
                                        filling-token="fillingToken"
                                    ></stage-feedback-popup>`,
                        {
                            stage: stage,
                            fillingToken: fillingToken
                        }
                    )
                }

                function showWantRate(fillingId, onRateCallback, onNo, averageScore) {
                    ActionSheetCustomService.create(
                        `<want-to-rate-popup on-yes="onYes" on-no="onNo"></want-to-rate-popup>`,
                        {
                            onYes: () => {
                                showRatePopup('SERVICEFORM.FEEDBACK.RATE_FORM', onRateForm(fillingId, onRateCallback), null, null, averageScore);
                            },
                            onNo: onNo
                        }
                    )
                }

                function showRatePopup(title, onRate, isEdit, selectedRating, averageRating) {
                    ActionSheetCustomService.create(
                        `<rate-popup title="title" on-rate="onRate" is-edit="isEdit" selected-rating="selectedRating" average-rating="averageRating"></rate-popup>`,
                        {
                            title: title,
                            onRate: onRate,
                            isEdit: isEdit,
                            selectedRating: selectedRating,
                            averageRating
                        }
                    )
                }

                function onRateForm(fillingId, onRateCallback) {
                    return (rating, message) => {
                        return createFeedback(fillingId, rating, message)
                            .then(data => {
                                onRateCallback && onRateCallback(data);
                            })
                    }
                }

                function createFeedback(fillingId, rating, message, fieldId) {
                    return $http.post('ServiceForm/CreateFeedback/', {
                        ServiceFormFillingId: fillingId,
                        ServiceFormFieldId: fieldId,
                        Rating: rating,
                        Comment: message,
                    })
                }

                function rateReport(fillingId, onRateCallback, averageRating) {
                    return showRatePopup('SERVICEFORM.FEEDBACK.RATE_FORM', (rating, message) => {
                        return createFeedback(fillingId, rating, message).then(data => {
                            onRateCallback && onRateCallback(data);
                        })
                    }, false, null, averageRating)
                }

                function openFormFeedbackComments(feedback, replyingMessage, commentId, serviceFormFieldId, serviceFormFillingId, moduleToken) {
                    PopupWrapperService.createDynamic(
                        `<feedback-comments-popup
                                        feedback="feedback"
                                        replying-message="replyingMessage"
                                        comment-id="commentId"
                                        service-form-field-id="serviceFormFieldId"
                                        service-form-filling-id="serviceFormFillingId"
                                        module-token="moduleToken"
                                    ></feedback-comments-popup>`,
                        {
                            feedback: feedback,
                            replyingMessage: replyingMessage,
                            commentId: commentId,
                            serviceFormFieldId: serviceFormFieldId,
                            serviceFormFillingId: serviceFormFillingId,
                            moduleToken: moduleToken
                        }
                    )
                }

                function onStageClick(stage, data, callback) {
                    if (stage.ReviewStatus === 0) {
                        PopupWrapperService
                            .getPopup(ServiceFormRenderService.updateFillingPopupId)
                            .open({
                                Filling: data.Filling,
                                RuleActions: data.RuleActions,
                                Stage: stage,
                                PageNavigation: data.PageNavigation.find(stg => stage.ServiceFormStageId === stg.ServiceFormStageId).Pages,
                                FieldsWithRuleActionsToLimitDataListUsage: data.FieldsWithRuleActionsToLimitDataListUsage
                            });
                    } else {
                        openStageContentPopup(stage, data, callback)
                    }
                }

                function initTranslationSettings({Filling, ScoreReport, Stages}) {
                    const {PreferTranslatedFillings} = ServiceFormRenderService.fillingInteractionSettings,
                        settings = {};

                    if (Stages?.length) {
                        settings.showTranslationButton = Stages.some(stage => stage.CanBeTranslated)
                            || isScoreReportCanBeTranslated(ScoreReport);
                        settings.showOriginalLabel = PreferTranslatedFillings && isStagesTranslated(Stages, ScoreReport);
                    } else {
                        settings.showTranslationButton = isFillingCanBeTranslated(Filling, ScoreReport);
                        settings.showOriginalLabel = PreferTranslatedFillings && isFillingTranslated(Filling, ScoreReport);
                    }
                    settings.showOriginal = !PreferTranslatedFillings;

                    return settings
                }

                function isFillingCanBeTranslated({Fields, Groups}, scoreReport) {
                    return Fields
                            .filter(field => [1, 2, 4].includes(field.FieldType) && field.Values?.length)
                            .some(field => {
                                return field.CanBeTranslated
                                    || field.Values.some(value => value.CanBeTranslated)
                                    || field.FeedbackData?.Feedback?.Comments.some(comment => comment.CanBeTranslated)
                            })
                        || (Groups && Groups.some(group => group.CanBeTranslated))
                        || isScoreReportCanBeTranslated(scoreReport);
                }

                function isScoreReportCanBeTranslated(scoreReport) {
                    return scoreReport && (scoreReport.Categories.some(category => category.CanBeTranslated)
                        || scoreReport?.PerformanceRange?.CanBeTranslated)
                }

                function isStagesTranslated(stages, scoreReport) {
                    return stages.every(stage => stage.TranslatedTitle && stage.TranslatedDescription)
                        && (!scoreReport || isScoreReportTranslated(scoreReport))
                        && isLabelTranslated();
                }

                function isFillingTranslated(filling, scoreReport) {
                    const {Fields, Groups} = filling;

                    return Fields
                            .filter(field => field.Values?.length)
                            .every(field => {
                                return isFieldTranslated(field)
                                    && (![1, 2, 4].includes(field.FieldType) || isValuesTranslated(field.Values))
                                    && isCommentsTranslated(field)
                            })
                        && (Groups && Groups.every(group => group.TranslatedTitle || !group.CanBeTranslated))
                        && (!scoreReport || isScoreReportTranslated(scoreReport))
                        && isLabelTranslated();
                }

                function isLabelTranslated() {
                    return ServiceFormRenderService.getLabelSettings().TranslatedEntityNameSingular?.length
                        || !ServiceFormRenderService.getLabelSettings().EntityNameSingular?.length
                }


                function isScoreReportTranslated({Categories, PerformanceRange}) {
                    return Categories.every(category => {
                            return category.TranslatedTitle || !category.Title || !category.CanBeTranslated
                        })
                        && (!PerformanceRange || PerformanceRange.TranslatedTitle || !PerformanceRange.Title || !PerformanceRange.CanBeTranslated)
                }

                function isFieldTranslated(field) {
                    const {
                        Title, HelpText, BodyHtml, NumberSuffix,
                        TranslatedTitle, TranslatedHelpText, TranslatedBodyHtml, TranslatedNumberSuffix
                    } = field;

                    return (Title && TranslatedTitle || !Title)
                        && (HelpText && TranslatedHelpText || !HelpText)
                        && (BodyHtml && TranslatedBodyHtml || !BodyHtml)
                        && (NumberSuffix && TranslatedNumberSuffix || !NumberSuffix)
                        || !field.CanBeTranslated
                }

                function isValuesTranslated(values) {
                    return values.every(value => {
                        return (value.DataListItem ? value.DataListItem.TranslatedTitle : value.TranslatedValue)
                            || !value.CanBeTranslated
                    })
                }

                function isCommentsTranslated(field) {
                    return !field.FeedbackData?.Feedback?.Comments
                        || field.FeedbackData?.Feedback?.Comments.every(comment => comment.TranslatedText || !comment.CanBeTranslated)
                }

                function toggleTranslation(isTranslated, data) {
                    const {Stages, Filling, ScoreReport} = data,
                        defer = $q.defer();

                    if (Stages?.length) {
                        if (isStagesTranslated(Stages, ScoreReport) || isTranslated) {
                            defer.resolve({wasTranslated: false});
                        } else {
                            translateStages(Filling.ServiceFormFillingToken, Stages, ScoreReport)
                                .then(() => defer.resolve({wasTranslated: true}))
                        }
                    } else {
                        if (isFillingTranslated(Filling, ScoreReport) || isTranslated) {
                            defer.resolve({wasTranslated: false});
                        } else {
                            translateFilling(Filling, ScoreReport)
                                .then(() => defer.resolve({wasTranslated: true}))
                        }
                    }

                    return defer.promise;
                }

                function translateStages(token, stages, scoreReport) {
                    return TranslationService
                        .translateServiceForm(getModelToTranslate({token, stages, scoreReport}))
                        .then(({Stages, LabelSettings, PerformanceRange, ScoreCategories}) => {
                            if (Stages.length) {
                                applyStagesTranslation(Stages)
                            }
                            if (PerformanceRange || ScoreCategories.length) {
                                applyPerformanceTranslation(scoreReport, PerformanceRange, ScoreCategories);
                            }
                            if (LabelSettings) {
                                applyLabelsTranslation(LabelSettings);
                            }
                        })

                    function applyStagesTranslation(Stages) {
                        Stages.forEach(translatedStage => {
                            const stageToTranslate = stages
                                .find(stage => stage.ServiceFormStageId === translatedStage.ServiceFormStageId);
                            if (stageToTranslate) {
                                stageToTranslate.TranslatedTitle = translatedStage.Title;
                                stageToTranslate.TranslatedDescription = translatedStage.Description;
                            }
                        })
                    }
                }

                function translateFilling(filling, scoreReport, hideError) {
                    return TranslationService
                        .translateServiceForm(getModelToTranslate({filling, scoreReport}), hideError)
                        .then(data => {
                            const {
                                Fields, FeedbackComments, FieldValues, DataListItems, PerformanceRange,
                                ScoreCategories, FieldGroups, LabelSettings
                            } = data;

                            if (Fields.length || FeedbackComments.length || FieldValues.length || DataListItems.length) {
                                applyFieldsTranslation(Fields, FeedbackComments, DataListItems, FieldValues)
                            }
                            if (PerformanceRange || ScoreCategories.length) {
                                applyPerformanceTranslation(scoreReport, PerformanceRange, ScoreCategories);
                            }
                            if (FieldGroups.length) {
                                applyGroupTranslations(FieldGroups)
                            }
                            if (LabelSettings) {
                                applyLabelsTranslation(LabelSettings);
                            }
                        });

                    function applyFieldsTranslation(Fields, FeedbackComments, DataListItems, FieldValues) {
                        filling.Fields.forEach(field => {
                            if (Fields.length) {
                                const translatedFieldIndex = Fields
                                    .findIndex(el => el.ServiceFormFieldId === field.ServiceFormFieldId);

                                if (translatedFieldIndex !== -1) {
                                    Fields[translatedFieldIndex].BodyHtml && (field.TranslatedBodyHtml = Fields[translatedFieldIndex].BodyHtml);
                                    Fields[translatedFieldIndex].HelpText && (field.TranslatedHelpText = Fields[translatedFieldIndex].HelpText);
                                    Fields[translatedFieldIndex].NumberSuffix && (field.TranslatedNumberSuffix = Fields[translatedFieldIndex].NumberSuffix);
                                    Fields[translatedFieldIndex].Title && (field.TranslatedTitle = Fields[translatedFieldIndex].Title);

                                    Fields.splice(translatedFieldIndex, 1);
                                }
                            }

                            if (field.FeedbackData?.Feedback?.Comments.length && FeedbackComments.length) {
                                field.FeedbackData.Feedback.Comments.forEach(comment => {
                                    const translatedCommentIndex = FeedbackComments
                                        .findIndex(el => el.ServiceFormFeedbackCommentId === comment.ServiceFormFeedbackCommentId)
                                    if (translatedCommentIndex !== -1) {
                                        comment.TranslatedText = FeedbackComments[translatedCommentIndex].Text;
                                        FeedbackComments.splice(translatedCommentIndex, 1);
                                    }
                                })
                            }

                            if (DataListItems.length || FieldValues.length) {
                                field.Values.forEach(value => {
                                    if (value.DataListItem) {
                                        const translatedDatalistItemIndex = DataListItems
                                            .findIndex(el => el.DataListItemId === value.DataListItem.DataListItemId)
                                        if (translatedDatalistItemIndex !== -1) {
                                            value.DataListItem.TranslatedTitle = DataListItems[translatedDatalistItemIndex].Title;
                                            DataListItems.splice(translatedDatalistItemIndex, 1);
                                        }
                                    } else {
                                        const translatedFieldValueIndex = FieldValues
                                            .findIndex(el => el.ServiceFormFillingValueId === value.ServiceFormFillingValueId);
                                        if (translatedFieldValueIndex !== -1) {
                                            value.TranslatedValue = FieldValues[translatedFieldValueIndex].Value;
                                            FieldValues.splice(translatedFieldValueIndex, 1);
                                        }
                                    }
                                })
                            }
                        })
                    }

                    function applyGroupTranslations(FieldGroups) {
                        filling.Groups.forEach(group => {
                            const translatedGroupIndex =
                                FieldGroups.findIndex(el => el.ServiceFormFieldGroupId === group.ServiceFormFieldGroupId);
                            if (translatedGroupIndex !== -1) {
                                group.TranslatedTitle = FieldGroups[translatedGroupIndex].Title;
                                FieldGroups.splice(translatedGroupIndex, 1);
                            }
                        })
                    }
                }

                function applyPerformanceTranslation(scoreReport, PerformanceRange, ScoreCategories) {
                    scoreReport.Categories.forEach(category => {
                        const translatedCategoryIndex = ScoreCategories
                            .findIndex(el => el.ServiceFormScoreCategoryId === category.ServiceFormScoreCategoryId);
                        if (translatedCategoryIndex !== -1) {
                            category.TranslatedTitle = ScoreCategories[translatedCategoryIndex].Title;
                            ScoreCategories.splice(translatedCategoryIndex, 1);
                        }
                    })

                    if (PerformanceRange) {
                        scoreReport.PerformanceRange.TranslatedTitle = PerformanceRange.Title;
                    }
                }

                function getModelToTranslate({filling, scoreReport, stages, token}) {
                    let translationModel;

                    if (stages) {
                        translationModel = {
                            ServiceFormFillingToken: token,
                            LabelSettings: getLabelsToTranslate(),
                            Stages: stages.filter(stage => {
                                return !stage.TranslatedTitle || !stage.TranslatedDescription
                            }).map(stage => ({
                                ServiceFormStageId: stage.ServiceFormStageId,
                                Title: !stage.TranslatedTitle && stage.Title,
                                Description: !stage.TranslatedDescription && stage.Description
                            })),
                            ScoreCategories: scoreReport && getCategories(scoreReport),
                            PerformanceRange: scoreReport && getPerformanceRange(scoreReport),
                        }

                    } else {
                        const {ServiceFormFillingToken, Groups, Fields} = filling,
                            {Values, DatalistItems, FeedbackComments, FieldsToTranslate} = getFieldsValues(Fields);

                        translationModel = {
                            ServiceFormFillingToken: ServiceFormFillingToken,
                            LabelSettings: getLabelsToTranslate(),
                            FieldValues: Values,
                            DataListItems: DatalistItems,
                            FeedbackComments: FeedbackComments,
                            Fields: FieldsToTranslate,
                            ScoreCategories: scoreReport && getCategories(scoreReport),
                            PerformanceRange: scoreReport && getPerformanceRange(scoreReport),
                            FieldGroups: getFieldGroups(Groups)
                        }
                    }

                    return translationModel;

                    function getFieldsValues(fields) {
                        const Values = [], DatalistItems = [], FeedbackComments = [], FieldsToTranslate = [];

                        fields
                            .filter(field => field.Values?.length)
                            .forEach(field => {
                                field.CanBeTranslated && !isFieldTranslated(field) && FieldsToTranslate.push({
                                    ServiceFormFieldId: field.ServiceFormFieldId,
                                    Title: !field.TranslatedTitle ? field.Title : '',
                                    HelpText: !field.TranslatedHelpText ? field.HelpText : '',
                                    BodyHtml: !field.TranslatedBodyHtml ? field.BodyHtml : '',
                                    NumberSuffix: !field.TranslatedNumberSuffix ? field.NumberSuffix : '',
                                })

                                if (field.FeedbackData?.Feedback) {
                                    field.FeedbackData.Feedback.FirstComments
                                        .forEach(({
                                                      ServiceFormFeedbackCommentId,
                                                      Text,
                                                      TranslatedText,
                                                      CanBeTranslated
                                                  }) => {
                                            CanBeTranslated && !TranslatedText &&
                                            FeedbackComments.push({
                                                ServiceFormFeedbackCommentId,
                                                Text
                                            })
                                        })
                                }

                                [1, 2, 4].includes(field.FieldType) && field.Values
                                    .filter(value => value.CanBeTranslated)
                                    .forEach(value => {
                                        if (value.DataListItem) {
                                            !value.DataListItem.TranslatedTitle && DatalistItems.push({
                                                DataListItemId: value.DataListItem.DataListItemId,
                                                Title: value.DataListItem.Title
                                            })
                                        } else {
                                            !value.TranslatedValue && Values.push({
                                                ServiceFormFillingValueId: value.ServiceFormFillingValueId,
                                                Value: value.Value
                                            })
                                        }
                                    })
                            })

                        return {
                            Values, DatalistItems, FeedbackComments, FieldsToTranslate
                        }
                    }

                    function getCategories(scoreReport) {
                        return scoreReport.Categories
                            .filter(category => category.CanBeTranslated && category.Title && !category.TranslatedTitle)
                            .map(category => ({
                                ServiceFormScoreCategoryId: category.ServiceFormScoreCategoryId,
                                Title: category.Title
                            }))
                    }

                    function getPerformanceRange({PerformanceRange}) {
                        if (PerformanceRange && PerformanceRange.CanBeTranslated && !PerformanceRange.TranslatedTitle) {
                            return {
                                ServiceFormPerformanceRangeId: PerformanceRange.ServiceFormPerformanceRangeId,
                                Title: PerformanceRange.Title
                            }
                        }
                    }

                    function getFieldGroups(groups) {
                        return groups.filter(group => group.CanBeTranslated && !group.TranslatedTitle)
                            .map(({ServiceFormFieldGroupId, Title}) => ({
                                ServiceFormFieldGroupId,
                                Title
                            }))
                    }
                }

                function getLabelsToTranslate() {
                    const labels = {};

                    if (!ServiceFormRenderService.getLabelSettings()?.TranslatedEntityNameSingular) {
                        labels.EntityNameSingular = ServiceFormRenderService.getLabelSettings()?.EntityNameSingular
                    }

                    return labels
                }

                function applyLabelsTranslation(labels) {
                    ServiceFormRenderService.updateLabelSettings({
                        TranslatedEntityNameSingular: labels.EntityNameSingular
                    });
                }

                function translatePdf({Filling, ScoreReport}) {
                    const url = '/ServiceForm/GetFillingPdfReport/' + Filling.ServiceFormFillingToken + '?showTranslated=true'
                    if (isFillingCanBeTranslated(Filling, ScoreReport) && !isFillingTranslated(Filling, ScoreReport)) {
                        translateFilling(Filling, ScoreReport, true).then(() => {
                            location.href = url;
                        })
                    } else {
                        location.href = url;
                    }
                }

                function follow(fillingToken) {
                    return $http.post('/ServiceForm/Follow/' + fillingToken)
                }

                function unfollow(fillingToken) {
                    return $http.post('/ServiceForm/Unfollow/' + fillingToken)
                }
            }]);
})();