(function () {
    'use strict';

    angular.module('App')
        .factory('TaskManagementRestService', ['$q', '$http', '$stateParams', TaskManagementRestService]);

    function TaskManagementRestService($q, $http, $stateParams) {
        var service = {
            getAllTasks: getAllTasks,
            getTasks: getTasks,
            getTaskPreviewData: getTaskPreviewData,
            getTaskPreviewStatisticsData: getTaskPreviewStatisticsData,
            taskComplete: taskComplete,
            taskReopen: taskReopen,
            getTasksBeforeAfter: getTasksBeforeAfter,
            findIssues: findIssues,
            getManagerTabTasks: getManagerTabTasks,
            getManagerTab: getManagerTab
        };

        return service;

        function post(path, data) {
            return $http.post(path, data).then(getData).catch(catchData);
        }

        function get(path) {
            return $http.get(path).then(getData).catch(catchData);
        }

        function getData(response) {
            return response.data;
        }

        function catchData(response) {
            throw response.data;
        }

        function getTasksBeforeAfter(issueId, limit, isAfter) {
            return post('/TaskManagement/GetIssuesBeforeAfter', {
                token: $stateParams.token,
                model: {
                    IssueId: issueId,
                    Limit: limit,
                    IsAfter: isAfter
                }
            })
        }

        function getAllTasks(model) {
            return post('TaskManagement/GetTasks', {
                model: model
            });
        }

        function getTasks(months) {
            const periodFrom = _.head(months).fullDate.startOf('month').format(),
                  periodTo = _.last(months).fullDate.endOf('month').format();

            return post('/TaskManagement/GetTasks', {
                model: {
                    Token: $stateParams.token,
                    StartTime: periodFrom,
                    EndTime: periodTo
                }
            });
        }

        function getTaskPreviewData(issueToken) {
            return get('/TaskManagement/IssuePreview/' + issueToken);
        }


        function getTaskPreviewStatisticsData(issueToken) {
            return get('/TaskManagement/IssuePreviewStatistics/' + issueToken);
        }

        function taskComplete(issueToken) {
            return post('/TaskManagement/Complete', {
                token: issueToken,
            });
        }
        
        function taskReopen(issueToken) {
            return post('/TaskManagement/Reopen', {
                token: issueToken
            })
        }
        
        function findIssues(searchString) {
            return post('/TaskManagement/GetTasks', {
                model: {
                    Token: $stateParams.token,
                    SearchString: searchString
                }
            })
        }
        
        function getManagerTabTasks(startTime, endTime) {
            return post('/TaskManagement/TaskListManagerView', {
                token: $stateParams.token,
                startTime: moment(startTime).format('YYYY-MM-DD[T]HH:mm:ss'),
                endTime: moment(endTime).format('YYYY-MM-DD[T]HH:mm:ss')
            })
        }

        function getManagerTab(model) {
            model.DepartmentIds = _.map(model.Departments, function (department) {
                return department.DepartmentId
            });

            model.UserGroupIds = _.map(model.UserGroups, function (userGroup) {
                return userGroup.UserGroupId
            });

            return post('/TaskManagement/ManagerView', {
                model: {
                    StartTime: model.StartTime ? moment(model.StartTime).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                    EndTime: model.EndTime ? moment(model.EndTime).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                    Token: $stateParams.token,
                    DepartmentId: model.DepartmentId,
                    CategoryIds: model.CategoryIds,
                    UserGroupIds: model.UserGroupIds
                }
            })
        }
    }
})();