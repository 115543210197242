(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('timeAgoTimeZone', ['TimeLocale',
        function (TimeLocale) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    var localTimeString = scope.$eval(attrs.timeLocalString);
                    var dateCreatedUtc = moment(scope.$eval(attrs.timeAgoTimeZone));
                    var dateCreatedTicks = Number(dateCreatedUtc.format('X'));
                    var nowTicks = Number(moment().utc().format('X'));

                    if (nowTicks < (dateCreatedTicks + 86400)) {
                        $(element).html(TimeLocale.timeAgo(dateCreatedUtc));
                    } else {
                        $(element).html(localTimeString);
                    }
                }
            }
        }
    ]);
})();