(() => {
    'use strict';
    angular
        .module('App')
        .directive('profileImage', ['ProfileViewerService', 'DepartmentViewerService', profileImage]);

    function profileImage(ProfileViewerService, DepartmentViewerService) {
        return {
            restrict: 'E',
            template: require('./Templates/ProfileImage.tpl.html'),
            scope: {
                item: '<',
                usertoken: '<',
                highscoreLevel: '<',
                lazyLoading: '<',
                userName: '@',
                disableProfilePopup: '<',
                departmentToken: '<'
            },
            controller: ['$scope', function ($scope) {
                $scope.showProfileCard = ShowProfileCard;

                if ($scope.highscoreLevel && $scope.highscoreLevel.HighscoreLevelId) {
                    $scope.style = {
                        borderColor: $scope.highscoreLevel.HighscoreLevelColor
                    };
                    $scope.cssClass = ['has-highscore-level', 'highscore-level-class-' + $scope.highscoreLevel.HighscoreLevelAlias];
                }

                function ShowProfileCard(ev) {
                    if ($scope.usertoken !== undefined &&
                        $scope.usertoken !== null &&
                        $scope.usertoken.length > 0) {
                        ev.stopPropagation();
                        ProfileViewerService.showProfileCard($scope.usertoken);
                    } else if ($scope.departmentToken) {
                        ev.stopPropagation();
                        DepartmentViewerService.showDepartmentCard($scope.departmentToken)
                    }
                }

            }]
        }
    }
})();
