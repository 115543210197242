(() => {
    'use strict';

    angular
        .module('App')
        .component('filterTasksPopup', {
            template: require('./FilterTasksComponent.html'),
            controller: ['$scope', '$element','$rootScope', 'ToastFactory', 'Page', 'FilterTasksService', FilterTasksController],
            controllerAs: 'ctrl',
            bindings: {
                onPopupRegistered: '<'
            }
        });

    function FilterTasksController($scope, $element, $rootScope, ToastFactory, Page, FilterTasksService) {
        var ctrl = this, popup;

        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.setCurrentFilter = setCurrentFilter;
        ctrl.applyFilter = applyFilter;
        ctrl.validate = validate;
        ctrl.isLoadingFilteredTasks = false;
        ctrl.resetFilters = resetFilters;

        function onOpen(currentFilterModel, popupCtrl) {
            ctrl.task = {
                StartTime: new Date(moment().startOf('month').hour(0).minutes(0).seconds(0).milliseconds(0)),
                EndTime: new Date(moment().endOf('month').hour(0).minutes(0).seconds(0).milliseconds(0)),
                Departments: [],
                UserGroups: [],
                CategoryIds: []
            };

            FilterTasksService.getFilterPage(ctrl.issueToken).then(function (resp) {
                ctrl.departments = resp.DepartmentsAndUsergroups.Data.Departments;
                ctrl.userGroups = resp.DepartmentsAndUsergroups.Data.UserGroups;
                ctrl.categories = resp.Categories;
                ctrl.isModerator = resp.IsModerator;
                ctrl.privateTasksEnabled = resp.PrivateTasksEnabled;
            }).finally(function () {
                ctrl.isLoading = false;
                if (currentFilterModel) {
                    ctrl.setCurrentFilter(currentFilterModel);
                }
            });
            ctrl.issueToken = null;

            popup = popupCtrl;
        }

        function close() {
            ctrl.task = {};
            ctrl.categories = {};
            popup.remove();
            $element.remove()
            ctrl.destroyPermissions && ctrl.destroyPermissions();
        }

        function resetFilters() {
            var amountOfCurrentFilters = getFiltersCount(ctrl.task);

            if (amountOfCurrentFilters > 0) {
                $rootScope.$emit('TaskManagement:tasksFiltered', null, null, null, true);
                close();
            }
        }

        function applyFilter(model) {
            var filtersCount = getFiltersCount(model);
            ctrl.isLoadingFilteredTasks = true;

            FilterTasksService.filterTasks(model).then(function (resp) {
                $rootScope.$emit('TaskManagement:tasksFiltered', resp, model, filtersCount, false);
                ctrl.isLoadingFilteredTasks = false;
                close();
            }).catch(function (err) {
                ctrl.isLoadingFilteredTasks = false;
                ToastFactory.error(err);
            });
        }

        function getFiltersCount(model) {
            var count = 0;

            if (model.StartTime) {
                count++;
            }

            if (model.EndTime) {
                count++;
            }

            if (model.CategoryIds.length !== 0) {
                count++;
            }

            if (model.Departments.length !== 0) {
                count++;
            }

            if (model.UserGroups.length !== 0) {
                count++;
            }

            if (model.CreatedByMe) {
                count++;
            }

            if (model.AssignedToMe) {
                count++;
            }

            if (model.AssignedToMyStore) {
                count++;
            }

            if (model.ShowOnlyPrivateTasks) {
                count++
            }

            return count;
        }

        function setCurrentFilter(model) {
            ctrl.task.StartTime = new Date(model.StartTime);
            ctrl.task.EndTime = new Date(model.EndTime);
            ctrl.task.CreatedByMe = model.CreatedByMe;
            ctrl.task.AssignedToMe = model.AssignedToMe;
            ctrl.task.AssignedToMyStore = model.AssignedToMyStore;
            ctrl.task.ShowOnlyPrivateTasks = model.ShowOnlyPrivateTasks;

            ctrl.categories.forEach(function (cat) {
                if (model.CategoryIds.includes(cat.IssueCategoryId)) {
                    cat.isSelected = true;
                }
            });

            ctrl.departments.forEach(function (dep) {
                if (model.Departments.map(department => department.DepartmentId).includes(dep.DepartmentId)) {
                    ctrl.task.Departments.push(dep);
                }
            });

            ctrl.userGroups.forEach(function (ug) {
                if (model.UserGroups.map(userGroup => userGroup.UserGroupId).includes(ug.UserGroupId)) {
                    ctrl.task.UserGroups.push(ug);
                }
            });
        }

        function validate() {
            if (ctrl.isLoadingFilteredTasks)
                return;

            if (ctrl.task.StartTime && ctrl.task.EndTime) {
                if (!moment(ctrl.task.StartTime).isAfter(moment(ctrl.task.EndTime))) {
                    applyFilter(ctrl.task);
                } else {
                    ToastFactory.warningTranslated('TASK_MANAGEMENT.FILTER.STARTDATE_EXCEEDS_ENDTIME');
                }
                // TODO - Consider if we should keep the date limit or not
                //var dateDifference = moment(new Date(ctrl.task.EndTime)).diff(moment(new Date(ctrl.task.StartTime)), 'days');
                //if (dateDifference < 31) {
                //    applyFilter(ctrl.task);
                //} else {
                //    ToastFactory.warningTranslated('TASK_MANAGEMENT.FILTER.DATE_RANGE_WARNING');
                //}
                //var monthDifference = moment(new Date(ctrl.task.EndTime)).diff(new Date(ctrl.task.StartTime), 'months');
                //if (monthDifference <= 3) {
                //    applyFilter(ctrl.task);
                //} else {
                //    ToastFactory.warningTranslated('TASK_MANAGEMENT.FILTER.DATE_INTERVAL_WARNING');
                //}
            } else {
                ToastFactory.warningTranslated('TASK_MANAGEMENT.FILTER.DATE_RANGE_MISSING');
            }
        }
    }
})();
