(() => {
    'use strict';

    angular
        .module('App')
        .component('moduleSearch', {
            template: require('./SearchComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$timeout', '$scope', SearchController],
            bindings: {
                onSearch: '<',
                onClose: '<',
                onSearchOpen: '<',
                onModelChange: '<',
                model: '=?',
                settings: '<',
                desktopSearch: '<',
                isStatic: '<'
            }
        });

    function SearchController($rootScope, $timeout, $scope) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.onSearchClick = onSearchClick;
        ctrl.onCloseClick = onCloseClick;
        ctrl.onSubmit = onSubmit;
        ctrl.change = change;
        ctrl.isRTL = $rootScope.isRTL;

        function init() {
            if (ctrl.model && ctrl.model.length) {
                ctrl.isSearchFieldActive = true;
            }

            if (ctrl.settings) {
                initSearch(ctrl.settings);
            }
        }

        function change(model) {
            if (ctrl.settings) {
                ctrl.settings.model = model;
            }
            if (!_.isUndefined(model) && ctrl.onModelChange) {
                ctrl.onModelChange(model);
            }
        }

        function onSearchClick() {
            ctrl.onSearchOpen && ctrl.onSearchOpen();
            ctrl.isSearchFieldActive = true;
            angular.element('.search-field input').focus();
        }

        function onCloseClick() {
            ctrl.isSearchFieldActive = false;
            ctrl.onClose && ctrl.onClose();
            ctrl.model = '';
            change(ctrl.model);
        }

        function onSubmit(text) {
            ctrl.onSearch && ctrl.onSearch(text);
        }

        function initSearch(settings) {
            ctrl.onSearch = _.get(settings, 'onSearch');
            ctrl.onClose = _.get(settings, 'onClose');
            ctrl.onSearchOpen = _.get(settings, 'onSearchOpen');
            ctrl.onModelChange = _.get(settings, 'onModelChange');
        }

        // re-init search if change listener was modified
        $scope.$watch('ctrl.settings.onModelChange', function (newVal) {
            init();
            ctrl.isSearchFieldActive = false;
            ctrl.model = '';
        })
    }
})();