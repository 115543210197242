(function () {
    'use strict';

    angular.module('App')
        .factory('ReactionsService', ['$http', '$filter', 'REACTION_TYPE_ID', 'PopupWrapperService',
            function ($http, $filter, REACTION_TYPE_ID, PopupWrapperService) {
                let reactions = [], unfilteredReactions = [];

                return {
                    openReactionsViewer: openReactionsViewer,
                    getReactions: getReactions,
                    getUnfilteredReactions: getUnfilteredReactions,
                    loadReactions: loadReactions,
                    getByTypeId: getByTypeId
                }

                function openReactionsViewer(token, allowExtended, module, isComments, onDelete) {
                    PopupWrapperService.createDynamic(
                        `<reactions-viewer 
                                        allow-extended="allowExtended"
                                        module="{{ module }}"
                                        is-comments="isComments"
                                        token="token"
                                        on-delete="onDelete"
                                    ></reactions-viewer>`,
                        {
                            allowExtended: allowExtended,
                            token: token,
                            module: module,
                            isComments: isComments,
                            onDelete: onDelete,
                        }
                    )
                }

                function getUnfilteredReactions() {
                    return unfilteredReactions;
                }

                function getReactions() {
                    return reactions;
                }

                function loadReactions() {
                    if (!reactions || !reactions.length) {
                        return $http.get('/Reaction/GetReactions').then(resp => {
                            unfilteredReactions = formatReactions(resp.data.Reactions);
                            reactions = unfilteredReactions.filter(reaction => reaction.IsActive);
                        })
                    }
                }

                function formatReactions(reactions) {
                    return reactions.map((reaction) => ({
                        ...reaction,
                        Image: reaction.Image ? reaction.Image.ImageFormats["W100"] : getReactionImage(reaction),
                        Title: reaction.Title || getReactionTitle(reaction)
                    }))
                }

                function getReactionImage(reaction) {
                    switch (reaction.ReactionTypeId) {
                        case REACTION_TYPE_ID.LIKE:
                            return '/Content/Graphics/Reactions/like.png'
                        case REACTION_TYPE_ID.LOVE:
                            return '/Content/Graphics/Reactions/love.png'
                        case REACTION_TYPE_ID.HAHA:
                            return '/Content/Graphics/Reactions/haha.png'
                        case REACTION_TYPE_ID.WOW:
                            return '/Content/Graphics/Reactions/wow.png'
                        case REACTION_TYPE_ID.SAD:
                            return '/Content/Graphics/Reactions/sad.png'
                        case REACTION_TYPE_ID.ANGRY:
                            return '/Content/Graphics/Reactions/angry.png'
                    }
                }

                function getReactionTitle(reaction) {
                    return 'SOCIAL.REACTIONS.' + Object.keys(REACTION_TYPE_ID)[reaction.ReactionTypeId - 1]
                }

                function getByTypeId(id) {
                    return unfilteredReactions.find(reaction => reaction.ReactionTypeId === parseInt(id));
                }
            }
        ]);
})();   