(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
            function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
                $stateProvider
                    .state('serviceForm', {
                        parent: 'base',
                        url: '/serviceForm/:token/:extra',
                        params: {
                            backState: null,
                            data: {}
                        },
                        template: `<div class="service-form-page">
                                    <service-form
                                        class="service-form service-form-view-{{ctrl.formData.Form.ServiceFormId}}"
                                        service-form-data="ctrl.formData"
                                        filling-data="ctrl.fillingData"
                                    ></service-form>
                                </div>`,
                        resolve: {
                            formData: ['$http', '$stateParams', '$q', '$state', 'base', 'ServiceFormRestService',
                                function ($http, $stateParams, $q, $state, base, ServiceFormRestService) {
                                    if (base) {
                                        let requests = [];
                                        const urlSearchParams = new URLSearchParams($stateParams.extra)

                                        requests.push(
                                            $http.post('/ServiceForm/GetAllServiceFormData/' + $stateParams.token, {
                                                serviceFormId: urlSearchParams.get('ServiceFormId')
                                            })
                                        );

                                        if (urlSearchParams.get('ServiceFormFillingToken')) {
                                            requests.push(ServiceFormRestService.getFillingData(urlSearchParams.get('ServiceFormFillingToken')))
                                        }

                                        return $q.all(requests).then(function (resp) {
                                            const response = {
                                                PageData: resp[0].data
                                            };

                                            if (urlSearchParams.get('ServiceFormFillingToken')) {
                                                response.FillingData = resp[1];
                                            }

                                            response.ServiceFormFieldId = urlSearchParams.get('ServiceFormFieldId');
                                            response.ShowFeedbackPage = urlSearchParams.get('ShowFeedbackPage') === "true";
                                            response.CommentId = urlSearchParams.get('CommentId');

                                            return response
                                        })
                                    }
                                }]
                        },
                        controller: 'ServiceFormController as ctrl'
                    })
            }
        ])
        .controller("ServiceFormController", ['$scope', '$state', '$stateParams', 'formData', 'Page', 'events', 'Menu',
            'ServiceFormRenderService',
            function ($scope, $state, $stateParams, formData, Page, events, Menu, ServiceFormRenderService) {
                var ctrl = this;

                Page.setTitle(formData.PageData.PageTitle);

                // Update menu activity

                const navigationBadge = formData.PageData.NavigationBadge;

                Menu.removeActivity($stateParams.data.badge, 'serviceFormOverview', $state.params.token);
                Menu.removeLandingBadge($stateParams.data.badge, navigationBadge.AccountModuleId, navigationBadge.ModuleId, navigationBadge.ModuleItemId);

                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else {
                    Page.showBackButton('home');
                }

                // Bind data to scope
                if (formData.FillingData) {
                    ctrl.fillingData = formData.FillingData;
                    ctrl.fillingData.ShowFeedbackPage = formData.ShowFeedbackPage;
                    ctrl.fillingData.ScrollToFeedback = formData.ServiceFormFieldId;
                    ctrl.fillingData.CommentId = formData.CommentId;
                    ctrl.fillingData.Filling = ServiceFormRenderService.formatFillingItemMedia(ctrl.fillingData.Filling);
                }
                ctrl.formData = formData.PageData;
                ctrl.formData.openCreateFilling = $stateParams.data.openCreateFilling;

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function (args) {
                    Page.stateReload();
                });
            }
        ]);
})();