(function () {
    'use strict';

    angular
        .module('App')
        .component('stageContentPopup', {
            template: require('./StageContentPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$element', '$filter', 'FillingPreviewService', 'PromptPopupService', 'ActionSheetService',
                'PopupWrapperService', 'ServiceFormRenderService', StageContentPopupController],
            bindings: {
                stage: '<',
                filling: '<',
                onStageUpdate: '<',
                feedbackData: '<',
                pageNavigation: '<',
                fieldsWithRule: '<',
            }
        })

    function StageContentPopupController($scope, $element, $filter, FillingPreviewService, PromptPopupService,
                                         ActionSheetService, PopupWrapperService, ServiceFormRenderService) {
        const ctrl = this;
        let popup;

        ctrl.popupId = 'stageContentPopupId';
        ctrl.hasFeedback = hasFeedback();

        ctrl.$onInit = init;
        ctrl.close = close;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.openOptions = openOptions;
        ctrl.approveStage = approveStage;
        ctrl.declineStage = declineStage;
        ctrl.openStatusPopup = openStatusPopup;
        ctrl.toggleTranslation = toggleTranslation;

        function init() {
            ctrl.stage.ServiceFormFillingId = ctrl.filling.ServiceFormFillingId;
            ctrl.hasMedia = ctrl.stage.Fields.some(field => field.MediaValues && field.MediaValues.length);
            ctrl.allowAutomaticTranslation = ServiceFormRenderService.fillingInteractionSettings.AllowAutomaticTranslation;

            const {
                showTranslationButton,
                showOriginalLabel,
                showOriginal
            } = FillingPreviewService.initTranslationSettings({Filling: ctrl.stage});

            ctrl.showTranslationButton = showTranslationButton;
            ctrl.showOriginalLabel = showOriginalLabel;
            ctrl.showOriginal = showOriginal;
        }

        function toggleTranslation() {
            ctrl.loadTranslation = !ctrl.isTranslated;
            FillingPreviewService.toggleTranslation(ctrl.isTranslated, {
                Filling: {
                    ...ctrl.stage,
                    ServiceFormFillingToken: ctrl.filling.ServiceFormFillingToken
                }
            })
                .then(data => {
                    if (data.wasTranslated) {
                        ctrl.isTranslated = true;
                        ctrl.showOriginalLabel = true;
                        ctrl.showOriginal = false;
                    } else {
                        ctrl.showOriginalLabel = !ctrl.showOriginalLabel;
                        ctrl.showOriginal = !ctrl.showOriginal;
                    }
                    $scope.$broadcast('comments:toggleTranslate', {showTranslated: !ctrl.showOriginal})
                })
                .finally(() => {
                    ctrl.loadTranslation = false;
                })
        }

        function openOptions() {
            ActionSheetService
                .create([{
                    text: ctrl.filling.IsDraft ? 'SERVICEFORM.FILLING.CONTINUE' : 'SERVICEFORM.FILLING.EDIT',
                    icon: 'edit',
                    onClick: () => {
                        close();
                        PopupWrapperService
                            .getPopup('updateFillingPopupId')
                            .open({
                                Filling: ctrl.filling,
                                Stage: ctrl.stage,
                                PageNavigation: ctrl.pageNavigation.find(stg => ctrl.stage.ServiceFormStageId === stg.ServiceFormStageId).Pages,
                                FieldsWithRuleActionsToLimitDataListUsage: ctrl.fieldsWithRule
                            });
                    }
                }])
                .show();
        }

        function close() {
            popup.remove();
            $element.remove();
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function approveStage() {
            return PromptPopupService.open({
                icon: 'save',
                iconPrefix: 'fas',
                placeholderText: $filter('translate')('SERVICEFORM.STAGES.APPROVE_PLACEHOLDER'),
                popupClass: 'stage-review approve',
                popupTitle: $filter('translate')('SERVICEFORM.STAGES.APPROVE_QUESTION'),
                buttonText: $filter('translate')('SERVICEFORM.STAGES.APPROVE'),
            }).then(function (comment) {
                return FillingPreviewService.approveStage(ctrl.stage.ServiceFormStageToken, ctrl.filling.ServiceFormFillingId, comment)
                    .then(function (resp) {
                        ctrl.stage.ReviewStatus = 2;
                        ctrl.onStageUpdate && ctrl.onStageUpdate(resp.data.Stages)
                    })
            })

        }

        function declineStage() {
            return PromptPopupService.open({
                icon: 'close',
                isTextRequired: true,
                iconPrefix: 'fas',
                placeholderText: $filter('translate')('SERVICEFORM.STAGES.REJECT_PLACEHOLDER'),
                popupClass: 'stage-review reject',
                popupTitle: $filter('translate')('SERVICEFORM.STAGES.REJECT_QUESTION'),
                buttonText: $filter('translate')('SERVICEFORM.STAGES.REJECT'),
            }).then(function (comment) {
                return FillingPreviewService.declineStage(ctrl.stage.ServiceFormStageToken, ctrl.filling.ServiceFormFillingId, comment)
                    .then(function (resp) {
                        ctrl.stage.ReviewStatus = 3;
                        ctrl.onStageUpdate && ctrl.onStageUpdate(resp.data.Stages)
                    })
            })
        }

        function openStatusPopup() {
            FillingPreviewService.openStageFeedbackPopup(ctrl.stage, ctrl.filling.ServiceFormFillingToken)
        }

        function hasFeedback() {
            return ctrl.feedbackData && !!ctrl.feedbackData.FieldsFeedback.find(feedback => feedback.Feedback !== null)
        }
    }
})();