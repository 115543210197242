(() => {
    'use strict';

    angular
        .module('App')
        .component('createEvent', {
            template: require('./CreateUpdateEventComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$filter', '$scope', '$timeout', '$element', 'CreateUpdateEventService',
                'CalendarRenderService', 'ToastFactory', 'Page', 'Profile', 'LanguageSelectorService',
                'TranslationService', CreateEventController],
            bindings: {
                calendars: '<',
                timezones: '<',
                selectedDay: '<',
                timezoneEnabled: '<',
                signupLimitEnabled: '<',
                categories: '<',
                accountModuleToken: '<'
            }
        });

    function CreateEventController($rootScope, $filter, $scope, $timeout, $element, CreateUpdateEventService,
                                   CalendarRenderService, ToastFactory, Page, Profile, LanguageSelectorService,
                                   TranslationService) {
        const ctrl = this
        let popup, translationWatcher, isEventSaving = false, initializing, removeAllEventsWatcher, eventChangeWatcher,
            loadingWatcher;

        ctrl.uploadUrl = Page.getSettings().MediaServerDomain + '/Upload'
        ctrl.uploadParams = {
            AccountToken: Profile.getProfile().AccountToken,
            UserToken: Profile.getProfile().UserToken
        }

        ctrl.translatedText = [];
        ctrl.warningMessage = '';
        ctrl.eventTypes = {
            Event: 1,
            ToDo: 2
        };

        ctrl.popupId = 'CreateEventPopup';
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.save = save;
        ctrl.deleteImage = deleteImage;
        ctrl.changeCalendar = changeCalendar;
        ctrl.getTranslation = getTranslation;
        ctrl.isTranslateDisabled = isTranslateDisabled;
        ctrl.selectLanguage = LanguageSelectorService.selectLanguage;
        ctrl.validateLanguages = validateLanguages;
        ctrl.requireUserLanguage = !Page.getSettings().CulturesRework;
        ctrl.setEventType = setEventType;
        ctrl.languageLoaded = languageLoaded;
        ctrl.initEventChangeWatcher = initEventChangeWatcher;

        function onPopupRegistered(popupSrc) {
            popup = popupSrc;
            popup.open();
        }

        function onOpen() {
            initializing = true;

            CreateUpdateEventService.getCreateEventData().then(data => {
                ctrl.settings = data.Settings;
                ctrl.languages = data.AvailableCultures;
                ctrl.AllowAutomaticTranslation = data.Settings.AllowAutomaticTranslation;
                ctrl.AllowAutomaticTranslation && initTranslationWatcher();
                ctrl.RemindersEnabled = data.RemindersEnabled;
                ctrl.preferTranslate = data.Settings.AllowAutomaticTranslation && data.Settings.PreferTranslateEvent;
                ctrl.event.AttendancePointsAmount = data.Settings.AttendanceDefaultPointsAmount || 0;

                if (ctrl.RemindersEnabled) {
                    ctrl.event.ReminderDuration = data.Settings.ReminderDuration;
                    ctrl.event.ReminderPeriodTypeId = data.Settings.ReminderPeriodTypeId.toString();
                }
                !ctrl.languages && languageLoaded();
            });

            ctrl.showPermissions = false;
            ctrl.event = {};
            ctrl.categories.forEach(category => {
                category.isSelected = false;
            });
            ctrl.event.CalendarEventTypeId = 1;
            ctrl.event.MatchAllGroups = false;
            ctrl.event.selectedLanguages = [];
            ctrl.availableCalendars = ctrl.calendars.filter(getAvailableCalendars);

            if (ctrl.selectedDay) {
                const startTime = ctrl.selectedDay.fullDate;

                startTime
                    .hours(moment().hours())
                    .minutes(moment().minutes())
                    .seconds(0)
                    .milliseconds(0);

                ctrl.event.Start = new Date(startTime.add(1, 'h').format());
                ctrl.event.End = new Date(startTime.add(2, 'h').format());
            } else {
                ctrl.event.Start = new Date(moment().format());
                ctrl.event.End = new Date(moment().add(1, 'h').format());
            }

            removeAllEventsWatcher = $scope.$watch('ctrl.event.allDay', function (allDay) {
                if (initializing) {
                    initializing = false;
                    return false;
                }
                if (allDay) {
                    ctrl.event.AllDayStart = new Date(moment(ctrl.event.Start).startOf('day'));
                    ctrl.event.AllDayEnd = new Date(moment(ctrl.event.End).endOf('day'));
                } else {
                    ctrl.event.Start = new Date(moment(ctrl.event.Start)
                        .day(moment(ctrl.event.AllDayStart).day())
                        .date(moment(ctrl.event.AllDayStart).date())
                        .year(moment(ctrl.event.AllDayStart).year()).seconds(0).millisecond(0));

                    ctrl.event.End = new Date(moment(ctrl.event.End)
                        .day(moment(ctrl.event.AllDayEnd).day())
                        .date(moment(ctrl.event.AllDayEnd).date())
                        .year(moment(ctrl.event.AllDayEnd).year()).seconds(0).millisecond(0));

                    ctrl.event.registrationStart = new Date(moment(ctrl.event.registrationStart)
                        .day(moment(ctrl.event.AllowRegisterStartDate).day())
                        .date(moment(ctrl.event.AllowRegisterStartDate).date())
                        .year(moment(ctrl.event.AllowRegisterStartDate).year()).seconds(0).millisecond(0));

                    ctrl.event.registrationEnd = new Date(moment(ctrl.event.registrationEnd)
                        .day(moment(ctrl.event.AllowRegisterEndDate).day())
                        .date(moment(ctrl.event.AllowRegisterEndDate).date())
                        .year(moment(ctrl.event.AllowRegisterEndDate).year()).seconds(0).millisecond(0));
                }
            });
            
            loadingWatcher = $scope.$watch('ctrl.isLanguageLoaded', () => {
                if (ctrl.isLanguageLoaded) {
                    initEventChangeWatcher();
                }
            }, true);
        }

        function initEventChangeWatcher() {
            let initializing = true;

            eventChangeWatcher = $scope.$watch(() => ctrl.event,
                () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isChanged = true;
                    }
                }, true)
        }

        function getAvailableCalendars(calendar) {
            return calendar.IsModerationAllowed;
        }

        function deleteImage() {
            ctrl.event.image = null;
        }

        function close() {
            translationWatcher && translationWatcher();
            popup.remove()
            $element.remove()
            ctrl.warningMessage = '';
            ctrl.destroyPermissions && ctrl.destroyPermissions();
            removeAllEventsWatcher && removeAllEventsWatcher();
            eventChangeWatcher && eventChangeWatcher();
            loadingWatcher && loadingWatcher();
        }

        function save(event) {
            if (!ctrl.eventForm.$valid || !ctrl.validateLanguages()) {
                ctrl.showValidation = true;

                $timeout(function () {
                    $element.find('multiple-permission-selector-wrapper.ng-invalid').length &&
                    $element.find('.scroll-container').scrollTop($element.find('multiple-permission-selector-wrapper.ng-invalid')[0].offsetTop);
                });

                return false;
            }

            const eventModel = CreateUpdateEventService.prepareDataForServer({
                ...event, AreMultiplePermissionsSet: ctrl.settings.MultiplePermissionSetsEnabled
            }, ctrl.RemindersEnabled);

            if (!isEventSaving) {
                isEventSaving = true;
                CalendarRenderService.createEvent(eventModel).then(function () {
                    isEventSaving = false;
                    $rootScope.$broadcast('event-created');
                    ToastFactory.success($filter('translate')('CALENDAR.EVENT.CREATED'));
                    ctrl.warningMessage = '';
                    close();
                }).catch(function (error) {
                    isEventSaving = false;
                    ctrl.warningMessage = error.Message;
                });
            }
        }

        function changeCalendar(calendar) {
            ctrl.showPermissions = false;

            if (calendar) {
                ctrl.permissionOptions = {
                    hideUserGroups: false,
                    hideDepartments: false,
                    requirePermissionId: null,
                    departmentFilterType: null,
                    requireDepartment: false,
                    matchAllGroups: false,
                    userGroupsLimited: true // always true on the calendar module
                };

                ctrl.IndividualInvitationsEnabled = calendar.IndividualInvitationsEnabled;
                ctrl.event.Permissions = null;

                CreateUpdateEventService.getPermissionsSettings(calendar.CalendarId).then(resp => {
                    ctrl.permissionOptions.departmentFilterType = resp.DepartmentFilterType;
                    ctrl.permissionOptions.requireDepartment = resp.RequireDepartmentFilter;
                    ctrl.permissionOptions.matchAllGroups = resp.MatchAllGroups;
                    ctrl.permissionOptions.requirePermissionId = resp.PermissionSettingType;
                    ctrl.departmentFilterType = resp.DepartmentFilterType;
                    ctrl.hideDepartments = !resp.RequireDepartmentFilter;
                    ctrl.showPermissions = true;
                });
            }
        }

        function getTranslation() {
            if (ctrl.translatedText?.length && isShowTranslationChangeWarning()) {
                const currentCultureId = ctrl.event.selectedLanguages[ctrl.currentIndex]?.CultureId
                const languages = ctrl.event.selectedLanguages
                    .filter(culture => culture.AllowAutoTranslation && culture.CultureId !== currentCultureId)
                    .map(culture => culture.Name).join(', ');
                TranslationService.confirmTranslations(languages).then(translate)
            } else {
                translate();
            }
        }

        function translate() {
            ctrl.isTranslating = true;

            TranslationService.translateEvent(ctrl.accountModuleToken, ctrl.event.selectedLanguages, ctrl.currentIndex).then(translations => {
                ctrl.event.selectedLanguages = ctrl.event.selectedLanguages.map(language => ({
                    ...language,
                    AutoTranslated: false
                }));
                translations.translations && translations.translations.forEach(translation => {
                    const locale = ctrl.event.selectedLanguages.find(culture => culture.CultureId === translation.CultureId);

                    locale.Title = translation.Title;
                    locale.Summary = translation.Summary;
                    locale.AutoTranslated = true;
                })

                ctrl.translatedText = _.cloneDeep(ctrl.event.selectedLanguages);
            }).finally(() => {
                ctrl.isTranslating = false;
                ctrl.translateDisabled = true;
                ctrl.event.selectedLanguages[ctrl.currentIndex].AutoTranslated = false;
            })
        }

        function isTranslateDisabled() {
            return ctrl.isTranslating || ctrl.translateDisabled
        }

        function initTranslationWatcher() {
            translationWatcher = $scope.$watch(() => {
                    return ctrl.event.selectedLanguages[ctrl.currentIndex];
                },
                (newVal) => {
                    const currentItem = ctrl.translatedText.find((item) => item.CultureId === newVal?.CultureId);
                    ctrl.translateDisabled = !newVal ||
                        ((newVal?.Title ?? '').trim() === (currentItem?.Title ?? '').trim() &&
                            (newVal?.Summary ?? '').trim() === (currentItem?.Summary ?? '').trim()) ||
                        ctrl.event.selectedLanguages.length < 2;
                    if (newVal && !ctrl.translateDisabled) {
                        ctrl.event.selectedLanguages[ctrl.currentIndex].AutoTranslated = false;
                    }
                }, true
            )
        }

        function isShowTranslationChangeWarning() {
            return ctrl.event.selectedLanguages
                .some(lang => {
                    const item = ctrl.translatedText?.find((item) => item.CultureId === lang.CultureId);
                    return lang.Title?.trim() !== item?.Title?.trim() ||
                        lang.Summary?.trim() !== item?.Summary?.trim();
                })
        }

        function validateLanguages() {
            if (!ctrl.event.selectedLanguages.length) {
                return false;
            }

            if (Page.getSettings().CulturesRework) {
                return ctrl.event.selectedLanguages.some(x => x.Title);
            }

            return ctrl.event.selectedLanguages[0].Title;
        }

        function setEventType(eventType) {
            ctrl.event.CalendarEventTypeId = eventType;
        }

        function languageLoaded() {
            ctrl.isLanguageLoaded = true;
        }
    }
})();