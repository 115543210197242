(function () {
    'use strict';

    angular.module('App')
        .factory('ConversationService', ['$http', 'ChatConnectionService', 'BasicHelper', 'ToastFactory',
            ConversationService]);

    function ConversationService($http, ChatConnectionService, BasicHelper, ToastFactory) {

        return {
            sendMessage: sendMessage,
            updateMessage: updateMessage,
            deleteMessage: deleteMessage,
            sendReaction: sendReaction,
            removeReaction: removeReaction,
            onChannelMarkedAsRead: onChannelMarkedAsRead,
            onNewMessage: onNewMessage,
            onTyping: onTyping,
            typing: typing,
            onConversationClose: onConversationClose,
            isFirstMessageInRow: isFirstMessageInRow,
            isDifferentDays: isDifferentDays,
            getMessageReads: getMessageReads,
            onUpdateMessage: onUpdateMessage,
            onReactionUpdate: onReactionUpdate,
            copyMessageText: copyMessageText
        };

        function sendMessage(data) {
            return $http.post('/chat/v2/createMessage', {
                text: data.text,
                medias: getMediaTokens(data.medias),
                replyToMessageId: data.replyToMessageId,
                taggedUsers: getTaggedUsers(data.mentions),
                IgnoreContentSafetyWarning: data.ignoreContentSafetyWarning,
                ChannelId: data.channelId,
            })
        }

        function updateMessage(data) {
            return $http.post('/chat/v2/updateMessage', {
                channelId: data.channelId,
                MessageId: data.messageId,
                Text: data.text,
                Medias: getMediaTokens(data.medias),
                taggedUsers: getTaggedUsers(data.mentions),
                IgnoreContentSafetyWarning: data.ignoreContentSafetyWarning
            }).then(resp => resp.data)
        }

        function deleteMessage(messageId) {
            return $http.post('/chat/v2/deleteMessage', {
                MessageId: messageId
            })
        }

        function sendReaction(messageId, reactionTypeId) {
            return $http.post('/chat/v2/reaction', {
                messageId, reactionTypeId
            })
        }

        function removeReaction(messageId) {
            return $http.post('/chat/v2/removeReaction', {
                messageId
            })
        }

        function chatClient() {
            return ChatConnectionService.getChatClient()
        }

        function onNewMessage(channelId, callback) {
            chatClient().on('newMessage', message => {
                if (message.item.channelId === channelId) {
                    callback(message)
                }
            })
        }

        function onChannelMarkedAsRead(channelId, callback) {
            chatClient().on('channelMarkedAsRead', data => {
                if (data.channelId === channelId) {
                    callback(data)
                }
            })
        }

        function onTyping(channelId, callback) {
            chatClient().on('userStartTyping', data => {
                if (data.channelId === channelId) {
                    callback(data)
                }
            });
        }

        function onUpdateMessage(callback) {
            chatClient().on('updateMessage', data => callback(data));
        }

        function onReactionUpdate(channelId, callback) {
            chatClient().on('updateReactionsMessage', data => {
                if (data.channelId === channelId) {
                    callback(data)
                }
            })
        }

        function typing(channelId) {
            chatClient().send('typing', channelId);
        }

        function onConversationClose() {
            chatClient().off('channelMarkedAsRead')
            chatClient().off('newMessage')
            chatClient().off('userStartTyping')
            chatClient().off('updateMessage')
            chatClient().off('updateReactionsMessage')
        }


        function isFirstMessageInRow(message, prevMessage) {
            return isDifferentAuthors(message, prevMessage) || isDifferentDays(message, prevMessage) || isAfterDelay(message, prevMessage);
        }

        function isDifferentDays(message, prevMessage) {
            return !prevMessage || (moment(prevMessage.dateCreated).dayOfYear() !== moment(message.dateCreated).dayOfYear());
        }

        function isDifferentAuthors(message, prevMessage) {
            return !prevMessage || (prevMessage.authorId !== message.authorId);
        }

        function isAfterDelay(message, prevMessage) {
            // check if delay between messages more than 1h
            return !prevMessage || (Math.abs(moment(prevMessage.dateCreated).valueOf() - moment(message.dateCreated).valueOf()) / 3600000 > 1);
        }

        function getMessageReads(channelId, messageId) {
            return $http.get(`/chat/v2/getMessageReadStats/${channelId}?messageId=${messageId}`)
                .then(resp => resp.data)
        }

        function getMediaTokens(items) {
            return items.length ? items.map(media => media.MediaToken) : null
        }

        function getTaggedUsers(mentions) {
            if (mentions?.length > 0) {
                return mentions.map(mention => mention.userId) || [];
            }
        }

        function copyMessageText(message) {
            const regex = /<[^>]*?class\s*=\s*["']?\s*post-tag-user-token\s*["']?[^>]*?>.*?<\/[^>]*?>|<[^>]*?>/g,
                text = message[0].innerHTML.replace(regex, '');

            BasicHelper.copyTextToClipboard(text).then(() => {
                ToastFactory.successTranslated('CHAT.MESSAGE_COPIED');
            }).catch(() => {
                ToastFactory.errorTranslated();
            });
        }
    }
})();