(function () {
    'use strict';

    angular
        .module('App')
        .component('reactionsViewer', {
            template: require('./ReactionsViewerComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$element', '$http', 'Profile', ReactionsViewerController],
            bindings: {
                allowExtended: '<',
                token: '<',
                isComments: '<',
                onDelete: '<',
                module: '@',
                popupId: '@'
            }
        });

    function ReactionsViewerController($rootScope, $element, $http, Profile) {
        let popup;
        const ctrl = this;

        ctrl.onOpen = onOpen;
        ctrl.deleteReaction = deleteReaction;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.applyFilter = applyFilter;
        ctrl.close = close;

        function onOpen() {
            ctrl.currentUserToken = Profile.getProfile().UserToken;
            ctrl.currentReactionTypeId = null;
            ctrl.reactions = [];
            ctrl.filteredReactions = [];
            ctrl.reactionTypes = [];
            ctrl.loading = true;

            $http.get('/' + ctrl.module + buildUrl(ctrl.token)).then(function (response) {
                ctrl.reactions = response.data;
                ctrl.filteredReactions = response.data;
                ctrl.reactionTypes = _.groupBy(response.data, 'ReactionTypeId');
                ctrl.reactionTypesLength = Object.keys(ctrl.reactionTypes).length;
                ctrl.loading = false;
            }, function () {
                ctrl.loading = false;
            });
        }

        function deleteReaction(reaction) {
            ctrl.onDelete && ctrl.onDelete(reaction);
            close();
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function applyFilter(reactionTypeId) {
            ctrl.currentReactionTypeId = reactionTypeId;

            if (!reactionTypeId) {
                ctrl.filteredReactions = ctrl.reactions;
                return false;
            }

            ctrl.filteredReactions = ctrl.reactions
                .filter(reaction => reaction.ReactionTypeId === parseInt(reactionTypeId));
        }

        function buildUrl(token) {
            if (ctrl.module === 'Chat') {
                return '/v2/GetReactions?messageId=' + token;

            } else {
                return ctrl.isComments
                    ? '/GetCommentReactions/?commentId=' + token
                    : '/GetReactions/' + token;
            }
        }

        function close() {
            popup.remove();
            $element.remove();
        }
    }
})();