(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('serviceFormOverview', {
                        parent: 'base',
                        url: '/serviceFormOverview/:token',
                        params: {
                            backState: null,
                            data: {}
                        },
                        template: `<forms-overview forms="ctrl.forms" options="ctrl.options"></forms-overview>`,
                        resolve: {
                            formsData: ['$http', '$stateParams', 'base', 'Page',
                                function ($http, $stateParams, base, Page) {
                                    if (base) {
                                        return $http.post('/ServiceForm/GetData/' + $stateParams.token)
                                            .then(resp => {
                                                if (resp.data.Forms.length === 1) {
                                                    Page.stateGo(
                                                        'serviceForm',
                                                        $stateParams.token,
                                                        `?ServiceFormId=${resp.data.Forms[0].ServiceFormId}`,
                                                        Page.getPageTransition(),
                                                        Object.assign({badge: resp.data.Forms[0].Badge}, $stateParams.data)
                                                    );
                                                } else {
                                                    return resp.data;
                                                }
                                            });
                                    }
                                }]
                        },
                        controller: 'FormsOverviewController as ctrl'
                    })
            }
        ])
        .controller("FormsOverviewController", ['$scope', '$stateParams', 'formsData', 'Page', 'events',
            function ($scope, $stateParams, formsData, Page, events) {
                const ctrl = this;
                Page.setTitle(formsData.PageTitle);

                ctrl.forms = formsData.Forms;
                ctrl.options = $stateParams.data;

                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token);
                } else {
                    Page.showBackButton('home');
                }

                $scope.$on(events.DEPARTMENT_CHANGED, function (args) {
                    Page.stateReload();
                });
            }]);
})();