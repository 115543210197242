(() => {
    'use strict';

    angular.module('App')
        .component('messageItem', {
            template: require('./MessageItem.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$filter', 'MESSAGE_STATUS', 'ReactionsService', 'ActionSheetService', 'ChatPopupsService',
                'ChatDataService', 'ConfirmPopupService', 'ConversationService', 'MentionService', 'ReportPopupService', 'Page',
                'ResponsiveService', MessageItemController],
            bindings: {
                isFirstInRow: '<',
                isDifferentDays: '<',
                currentUserToken: '<',
                message: '<',
                channel: '<',
                onReply: '<',
                onEdit: '<',
                goToReplyingMessage: '<',
                onOptionsOpen: '<',
                getMember: '<',
                messageReads: '<',
                isOptionsVisible: '=?'
            }
        })

    function MessageItemController($element, $filter, MESSAGE_STATUS, ReactionsService, ActionSheetService, ChatPopupsService,
                                   ChatDataService, ConfirmPopupService, ConversationService, MentionService, ReportPopupService, Page,
                                   ResponsiveService) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.hideOptions = hideOptions;
        ctrl.openReactionsView = openReactionsView;
        ctrl.showMessageReads = showMessageReads;
        ctrl.hideMessageAuthorForUser = hideMessageAuthorForUser;
        ctrl.getTime = getTime;
        ctrl.sendReaction = sendReaction;
        ctrl.isUserNotBlocked = isUserNotBlocked;
        ctrl.getBodyText = getBodyText;
        ctrl.isEdited = isEdited;
        ctrl.showTime = showTime;
        ctrl.editMessage = editMessage;
        ctrl.replyMessage = replyMessage;

        ctrl.visibleViewersLength = 3;
        ctrl.MESSAGE_STATUS = MESSAGE_STATUS;
        ctrl.isDesktop = ResponsiveService.isDesktop();

        function init() {
            if (ctrl.channel.reactionsEnabled) {
                ctrl.reactions = ReactionsService.getReactions();
            }
            ctrl.optionButtons = getOptionButtons();
        }
        
        function getOptionButtons() {
            const buttons = [];

            ctrl.message.item.text && buttons.push({
                text: 'CHAT.COPY_MESSAGE',
                icon: 'copy',
                onClick: (_, ev) => {
                    ev.stopPropagation();
                    ConversationService.copyMessageText($element.find('.message-body'))
                    hideOptions();
                }
            });

            if (ctrl.message.item.authorId === ctrl.currentUserToken) {
                buttons.push({
                    text: 'CHAT.EDIT_MESSAGE',
                    icon: 'edit',
                    onClick: (_, ev) => {
                        ev.stopPropagation();
                        ctrl.onEdit(ctrl.message)
                        hideOptions();
                    }
                });

                buttons.push({
                    text: 'CHAT.DELETE_MESSAGE',
                    icon: 'delete',
                    iconClass: 'red',
                    onClick: (_, ev) => {
                        ev.stopPropagation();
                        deleteMessage();
                        hideOptions();
                    }
                });
            } else {
                buttons.push({
                    text: 'CHAT.REPLY',
                    icon: 'reply',
                    onClick: (_, ev) => {
                        ev.stopPropagation();
                        ctrl.onReply(ctrl.message)
                        hideOptions();
                    }
                });

                if (Page.getSettings()?.ContentReportingEnabled) {
                    buttons.push({
                        text: 'REPORT.TITLE',
                        icon: 'exclamation-circle',
                        onClick: (_, ev) => {
                            ev.stopPropagation();
                            reportMessage(ctrl.message)
                            hideOptions();
                        }
                    });
                }
            }
            
            return buttons;
        }

        function editMessage() {
            ctrl.onEdit(ctrl.message);
            hideOptions();
        }

        function replyMessage() {
            ctrl.onReply(ctrl.message);
            hideOptions();
        }

        function isEdited() {
            return ctrl.message.status === MESSAGE_STATUS.EDITED || ctrl.message.item.dateModified;
        }

        function onReportSubmit(model, itemId) {
            return ReportPopupService.reportChatMessage({
                messageId: itemId,
                model,
            })
        }

        function onCloseReport(submitted) {
            ReportPopupService.onReportSubmit(submitted);
        }

        function reportMessage(message) {
            ReportPopupService.openReportPopup(onCloseReport, onReportSubmit, message.item.messageId, 'messageItem');
        }

        function getBodyText(message) {
            return message && MentionService.parseMentionsInText(message, usersForMention());
        }

        function usersForMention() {
            return ctrl.channel.members?.filter(member => !(ctrl.channel.blockedUsers ?? []).includes(member.userId));
        }

        function showTime(ev) {
            ev && ev.stopPropagation();
            if (!ctrl.isOptionsVisible) {
                ctrl.timeVisible = !ctrl.timeVisible;
            }
        }

        function hideOptions(ev) {
            ev && ev.stopPropagation();
            ctrl.isOptionsVisible = false;
            ctrl.timeVisible = false;
            ctrl.isMenuActive = false;
            $element.find('.hover')[0]?.classList.remove('hover');
        }

        function openReactionsView(ev) {
            ev.stopPropagation();
            ReactionsService.openReactionsViewer(ctrl.message.item.messageId, true, 'Chat', false, () => {
                ConversationService.removeReaction(ctrl.message.item.messageId);
            });
        }

        function sendReaction(ev, reactionId) {
            ev.stopPropagation();
            ConversationService.sendReaction(ctrl.message.item.messageId, reactionId)
            hideOptions();
        }


        function deleteMessage() {
            ConfirmPopupService.open({title: 'CHAT.DELETE_MESSAGE_CONFIRMATION'}).then(() => {
                ConversationService
                    .deleteMessage(ctrl.message.item.messageId)
                    .catch(() => {
                        ToastFactory.errorTranslated();
                    })
            })
        }

        function showMessageReads(channelId, messageId) {
            ChatPopupsService.openMessageViewersPopup(channelId, messageId, ctrl.currentUserToken, ctrl.channel.blockedUsers);
        }

        function hideMessageAuthorForUser(userToken) {
            if (ctrl.channel.item.appLinkId && ctrl.channel.item.creatorUserId === ctrl.currentUserToken) {
                return ctrl.channel.item.hideAuthor && userToken !== ctrl.currentUserToken;
            }
            return false
        }

        function getTime(timestamp) {
            const now = moment();
            const targetDate = moment(timestamp);

            switch (true) {
                case now.isSame(targetDate, 'day'):
                    return targetDate.format('HH:mm');

                case now.clone().subtract(1, 'day').isSame(targetDate, 'day'):
                    return $filter('translate')('YESTERDAY') + ' ' + targetDate.format('HH:mm');

                case now.diff(targetDate, 'days') <= 7:
                    return targetDate.format('dddd HH:mm');

                case now.diff(targetDate, 'months') > 1:
                    return targetDate.format('DD MMM HH:mm');

                case now.diff(targetDate, 'years') > 1:
                    return targetDate.format('DD MMM YYYY HH:mm');
            }

            return targetDate.format('DD MMM YYYY HH:mm');
        }

        function isUserNotBlocked(userId) {
            return !(ctrl.message.isBlocked || ctrl.channel.isBlocked || ctrl.channel.blockedUsers?.includes(userId));
        }
    }
})();
