(function () {
    'use strict';

    angular.module('App')
        .factory('ChatPopupsService', ['PopupWrapperService', ChatPopupsService]);

    function ChatPopupsService(PopupWrapperService) {

        return {
            openCreateChatPopup: openCreateChatPopup,
            openConversationPopup: openConversationPopup,
            openChatSettingsPopup: openChatSettingsPopup,
            openAddUsersPopup: openAddUsersPopup,
            openMessageViewersPopup: openMessageViewersPopup
        };

        function openCreateChatPopup() {
            PopupWrapperService.createDynamic('<create-chat></create-chat>');
        }

        function openConversationPopup(channelId, channel) {
            PopupWrapperService.createDynamic(
                '<conversation channel-id="channelId" channel="channel"></conversation>',
                {
                    channelId: channelId,
                    channel: channel
                }
            );
        }

        function openChatSettingsPopup(channel, closeConversation) {
            PopupWrapperService.createDynamic('<chat-settings channel="channel"' +
                ' close-conversation="closeConversation"></chat-settings>', {
                channel: channel,
                closeConversation: closeConversation
            });
        }

        function openAddUsersPopup(channel, closePreviousPopup) {
            PopupWrapperService.createDynamic('<add-users channel="channel"' +
                ' close-previous="closePrevious"></add-users>',
                {
                    channel: channel,
                    closePrevious: closePreviousPopup
                });
        }

        function openMessageViewersPopup(channelId, messageId, userId, blockedUsers) {
            PopupWrapperService
                .createDynamic(
                    `<message-viewers 
                                    channel-id="{{ channelId }}"
                                    message-id="{{ messageId }}"
                                    current-user-id="{{ userId }}"
                                    blocked-users="blockedUsers"
                                ></message-viewers>`,
                    {
                        channelId: channelId,
                        messageId: messageId,
                        userId: userId,
                        blockedUsers: blockedUsers
                    }
                );
        }
    }
})();