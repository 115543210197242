(function () {
    'use strict';

    angular
        .module('App')
        .factory('TranslationService', ['$filter', '$http', 'ToastFactory', 'ConfirmPopupService', '$q', TranslationService]);

    function TranslationService($filter, $http, ToastFactory, ConfirmPopupService, $q) {
        return {
            translateServiceForm: translateServiceForm,
            translateSocial: translateSocial,
            translateContent: translateContent,
            translateComment: translateComment,
            translateTask: translateTask,
            translateSubTask: translateSubTask,
            translateSubtaskToUserCulture: translateSubtaskToUserCulture,
            translateTaskToUserCulture: translateTaskToUserCulture,
            confirmTranslations: confirmTranslations,
            translateEvent: translateEvent,
            translateEventToUserCulture: translateEventToUserCulture,
            translateEventUpdate: translateEventUpdate,
            translateCancelReason: translateCancelReason,
            translateCatalogItem: translateCatalogItem,
            getCurrentTranslateableLocale: getCurrentTranslateableLocale,
            switchTranslateableLocale: switchTranslateableLocale,
            switchTranslatableLocaleSocial: switchTranslatableLocaleSocial,
            switchTranslatableLocaleContent: switchTranslatableLocaleContent,
            getSocialPostTranslation: getSocialPostTranslation,
            getContentPostTranslation: getContentPostTranslation,
            switchTranslatableLocaleSurvey: switchTranslatableLocaleSurvey,
            switchCatalogTranslatableLocale: switchCatalogTranslatableLocale,
            switchCalendarTranslatableLocale: switchCalendarTranslatableLocale,
        };

        function translateSocial(token, localeList, index = 0) {
            return $http.post('/Social/GetPostTranslations', {
                AccountModuleToken: token,
                CultureIds: getCultureIdsToTranslate(localeList, localeList[index].CultureId),
                FromCultureId: localeList[index].CultureId,
                Text: localeList[index]?.text,
                PollTitle: localeList[index]?.PollTitle,
                PollOptions: localeList[index]?.PollOptions?.map(lang => lang.Text)
            }).then(checkTranslationErrors);
        }

        function translateContent(token, selectedLanguages, index) {
            return $http.post('/ContentModule/TranslateContentPost', {
                AccountModuleToken: token,
                CultureId: selectedLanguages[index].CultureId,
                TitleFrom: selectedLanguages[index].Title,
                BodyTextFrom: selectedLanguages[index].Body,
                TranslateTo: getCultureIdsToTranslate(selectedLanguages, selectedLanguages[index].CultureId)
            }).then(checkTranslationErrors)
        }

        function translateComment(module, postId, commentId, text) {
            let url = '';
            const options = {
                Text: text
            };

            if (module === 'Social') {
                url = '/Social/GetCommentTranslation';
                options.SocialPostId = postId;
                options.SocialCommentId = commentId;
                //TODO: remove text property after final implementation of unified logic
                delete options.Text;
            }
            if (module === 'ContentModule') {
                url = '/ContentModule/TranslateContentComment'
                options.ContentId = postId;
                options.ContentCommentId = commentId;
                delete options.Text;
            }
            if (module === 'TaskManagement') {
                url = '/TaskManagement/TranslateCommentToUserCulture'
                options.IssueCommentId = commentId
                options.IssueId = postId

                delete options.Text;
            }
            if (module === 'ServiceForm') {
                url = '/Translation/TranslateServiceFormFillingCommentToUserCulture'
                options.ServiceFormFillingCommentId = commentId
            }
            if (module === 'ServiceFormFeedback') {
                url = '/Translation/TranslateServiceFormFeedbackCommentToUserCulture'
                options.ServiceFormFeedbackCommentId = commentId
            }
            if (module === 'ServiceFormStage') {
                url = '/Translation/TranslateServiceFormStageCommentToUserCulture'
                options.ServiceFormStageCommentId = commentId
            }

            return $http.post(url, options)
        }

        function translateSurvey(module, surveyQuesionId) {
            let url = '';
            const options = {};

            if (module === 'Survey') {
                url = '/Survey/TranslateSurveyQuestionToUserCulture';
                options.SurveyQuestionId = surveyQuesionId;
            }

            return $http.post(url, options)
        }

        function translateCatalogItem(module, dataListItemToken) {
            let url = '';
            const options = {
                CatalogItemToken: dataListItemToken
            };

            if (module === 'Catalog') {
                url = '/Catalog/TranslateCatalogItemToUserCulture';
            }

            return $http.post(url, options)
        }

        function translateTask(AccountModuleToken, LocaleList, index) {

            return $http.post('/TaskManagement/TranslateTask', {
                AccountModuleToken,
                CultureId: LocaleList[index].CultureId,
                TitleFrom: LocaleList[index].Title,
                SummaryFrom: LocaleList[index].Summary,
                TranslateTo: getCultureIdsToTranslate(LocaleList, LocaleList[index].CultureId)
            }).then(checkTranslationErrors)
        }

        function translateSubTask(token, localeList, index) {
            return $http.post('/TaskManagement/TranslateSubtask', {
                IssueToken: token,
                CultureId: localeList[index].CultureId,
                TitleFrom: localeList[index].Title,
                SummaryFrom: localeList[index].Summary,
                TranslateTo: getCultureIdsToTranslate(localeList, localeList[index].CultureId)
            }).then(checkTranslationErrors)
        }

        function translateSubtaskToUserCulture(model) {
            return $http.post('/TaskManagement/TranslateSubtaskToUserCulture', model)
                .then(resp => {
                    if (resp.data.Subtask.LocalizedAlternativeLocale) {
                        ToastFactory.successTranslated('TRANSLATION.TRANSLATION_SUCCEED');
                        return resp.data.Subtask;
                    } else {
                        ToastFactory.errorTranslated('TRANSLATION.CANT_TRANSLATE');
                    }
                })
        }

        function translateTaskToUserCulture(IssueId) {
            return $http.post('/TaskManagement/TranslateTaskToUserCulture ', {IssueId: IssueId}).then(resp => {
                if (resp) {
                    ToastFactory.successTranslated('TRANSLATION.TRANSLATION_SUCCEED');
                    return resp.data
                } else {
                    ToastFactory.errorTranslated('TRANSLATION.CANT_TRANSLATE');
                }
            })
        }

        function translateServiceForm(model, hideError) {
            return $http.post('/Translation/TranslateFillingDataToUserCulture', model)
                .then(resp => {
                    if (!hideError && !resp.data.IsAllTranslated) {
                        ToastFactory.errorTranslated('TRANSLATION.SOME_CANT_TRANSLATE');
                    } else {
                        ToastFactory.successTranslated('TRANSLATION.TRANSLATION_SUCCEED');
                    }
                    return resp.data
                })
        }

        function getCultureIdsToTranslate(cultures, id) {
            return cultures
                .filter(culture => culture.AllowAutoTranslation && culture.CultureId !== id)
                .map(culture => culture.CultureId)
        }

        function checkTranslationErrors(resp) {
            if (resp.data.Translations?.length) {
                if (!resp.data.IsAllTranslated) {
                    ToastFactory.errorTranslated('TRANSLATION.SOME_CANT_TRANSLATE');
                } else {
                    ToastFactory.successTranslated('TRANSLATION.TRANSLATION_SUCCEED');
                }

                if (resp.data.ExcludedWords) {
                    return {
                        translations: resp.data.Translations,
                        excludedWords: resp.data.ExcludedWords
                    }
                } else {
                    return resp.data.Translations
                }

            } else {
                ToastFactory.errorTranslated('TRANSLATION.CANT_TRANSLATE');
                return [];
            }
        }

        function confirmTranslations(languages) {
            return ConfirmPopupService.open({
                message: 'TRANSLATION.OVERWRITE_CONFIRMATION',
                notes: languages ? $filter('translate')('TRANSLATION.LIST_LANGUAGES') + ' ' + languages : ''
            })
        }

        function translateEvent(AccountModuleToken, LocaleList, index) {
            return $http.post('/Calendar/TranslateEvent', {
                AccountModuleToken,
                CultureId: LocaleList[index].CultureId,
                TitleFrom: LocaleList[index].Title,
                SummaryFrom: LocaleList[index].Summary,
                TranslateTo: getCultureIdsToTranslate(LocaleList, LocaleList[index].CultureId)
            }).then(checkTranslationErrors)
        }

        function translateEventToUserCulture(token) {
            return $http.post('/Calendar/TranslateEventToUserCulture', {
                EventToken: token
            })
                .then(resp => resp.data)
                .catch(() => ToastFactory.errorTranslated('TRANSLATION.CANT_TRANSLATE'))
        }

        function translateCancelReason(CalendarEventToken, LocaleList, index) {
            let url = '/Calendar/TranslateCancelReason';
            const options = {
                CalendarEventToken: CalendarEventToken,
                CancelReasonFrom: LocaleList[index].CancelReason,
                CultureId: LocaleList[index].CultureId,
                TranslateTo: getCultureIdsToTranslate(LocaleList, LocaleList[index].CultureId)
            }

            return $http.post(url, options).then(checkTranslationErrors);
        }

        function translateEventUpdate(CalendarEventToken, LocaleList, index) {
            let url = '/Calendar/TranslateEventUpdate';
            const options = {
                CalendarEventToken: CalendarEventToken,
                BodyTextFrom: LocaleList[index].BodyText,
                CultureId: LocaleList[index].CultureId,
                TranslateTo: getCultureIdsToTranslate(LocaleList, LocaleList[index].CultureId)
            }

            return $http.post(url, options).then(checkTranslationErrors);
        }

        function getCurrentTranslateableLocale(translatableEntity) {
            // do we have an alternative, and should we prefer the alternative?
            if (translatableEntity.LocalizedAlternativeLocale !== null && translatableEntity.PreferTranslatedVersion) {
                // return alternative
                translatableEntity._currentCultureId = translatableEntity.LocalizedAlternativeLocale.CultureId;
                return translatableEntity.LocalizedAlternativeLocale;
            }

            // we return the default if nothing was returned yet
            translatableEntity._currentCultureId = translatableEntity.LocalizedLocale.CultureId;
            return translatableEntity.LocalizedLocale;
        }

        function switchTranslatableLocaleContent(translatableEntities, token) {
            // if we don't have a current culture ID we will fetch it

            if (!translatableEntities.Post._currentCultureId) {
                getCurrentTranslateableLocale(translatableEntities.Post);
                if (translatableEntities.Category) {
                    getCurrentTranslateableLocale(translatableEntities.Category);
                }
            }

            var defer = $q.defer();

            // should we get the alternative?
            if (translatableEntities.Post._currentCultureId === translatableEntities.Post.LocalizedLocale?.CultureId &&
                (!translatableEntities.Category || translatableEntities.Category._currentCultureId ===
                    translatableEntities.Category.LocalizedLocale?.CultureId)) {
                let hasLocalizedAlternativeLocale = translatableEntities.Post.LocalizedAlternativeLocale ||
                    !translatableEntities.Post.AllowAutoTranslation;

                if (hasLocalizedAlternativeLocale && translatableEntities.Category) {
                    hasLocalizedAlternativeLocale = hasLocalizedAlternativeLocale &&
                        (translatableEntities.Category.LocalizedAlternativeLocale || !translatableEntities.Category.AllowAutoTranslation);
                }

                if (hasLocalizedAlternativeLocale) {
                    // we can return the alternative
                    setAlternativeLocaleValuesContent(translatableEntities);
                    defer.resolve(translatableEntities);
                } else {
                    // we need to generate a new translation
                    getContentPostTranslation(token).then(res => {
                        translatableEntities = res.data;

                        setAlternativeLocaleValuesContent(translatableEntities);
                        defer.resolve(translatableEntities);
                    }).catch(() => {
                        defer.reject();
                    });
                }
            } else {
                // we should return the "original"
                translatableEntities.Post._currentCulture = translatableEntities.Post && translatableEntities.Post.LocalizedLocale;
                translatableEntities.Post._currentCultureId = translatableEntities.Post && translatableEntities.Post.LocalizedLocale.CultureId;

                if (translatableEntities.Category) {
                    translatableEntities.Category._currentCulture = translatableEntities.Category && translatableEntities.Category.LocalizedLocale;
                    translatableEntities.Category._currentCultureId = translatableEntities.Category && translatableEntities.Category.LocalizedLocale.CultureId;
                }
                
                defer.resolve(translatableEntities);
            }

            return defer.promise;
        }

        function switchTranslatableLocaleSocial(translatableEntities, token) {
            // if we don't have a current culture ID we will fetch it

            if (translatableEntities.Post && !translatableEntities.Post._currentCultureId) {
                getCurrentTranslateableLocale(translatableEntities.Post);
            }
                
            if (translatableEntities.Poll && !translatableEntities.Poll._currentCultureId) {
                getCurrentTranslateableLocale(translatableEntities.Poll);
            }

            if (translatableEntities.Group && !translatableEntities.Group._currentCultureId) {
                getCurrentTranslateableLocale(translatableEntities.Group);
            }

            if (translatableEntities.PollOptions?.length && !(translatableEntities.PollOptions.every(option => option._currentCultureId))) {
                translatableEntities.PollOptions.forEach(option => getCurrentTranslateableLocale(option));
            }

            var defer = $q.defer();

            const checkIfOriginal = (translatableEntity) =>
                (!translatableEntity || translatableEntity._currentCultureId === translatableEntity.LocalizedLocale?.CultureId);

            // should we get the alternative?
            if (checkIfOriginal(translatableEntities.Post) && checkIfOriginal(translatableEntities.Group)
                && checkIfOriginal(translatableEntities.Poll) 
            ) {
                let hasLocalizedAlternativeLocale = true;
                
                if (translatableEntities.Post) {
                    hasLocalizedAlternativeLocale = translatableEntities.Post.LocalizedAlternativeLocale 
                        || !translatableEntities.Post.AllowAutoTranslation;
                }

                if (hasLocalizedAlternativeLocale && translatableEntities.Group) {
                    hasLocalizedAlternativeLocale = hasLocalizedAlternativeLocale &&
                        (translatableEntities.Group.LocalizedAlternativeLocale || !translatableEntities.Group.AllowAutoTranslation)
                }

                if (hasLocalizedAlternativeLocale && translatableEntities.Poll && translatableEntities.PollOptions?.length) {
                    hasLocalizedAlternativeLocale = hasLocalizedAlternativeLocale && 
                        (translatableEntities.Poll.LocalizedAlternativeLocale || !translatableEntities.Poll.AllowAutoTranslation) &&
                        translatableEntities.PollOptions.every(option => option.LocalizedAlternativeLocale || !option.AllowAutoTranslation);
                }

                if (hasLocalizedAlternativeLocale) {
                    // we can return the alternative
                    setAlternativeLocaleValuesSocial(translatableEntities);
                    defer.resolve(translatableEntities);
                } else {
                    // we need to generate a new translation
                    getSocialPostTranslation(token).then(res => {
                        translatableEntities = res.data;

                        setAlternativeLocaleValuesSocial(translatableEntities);
                        defer.resolve(translatableEntities);
                    }).catch(() => {
                        defer.reject();
                    });
                }
            } else {
                // we should return the "original"
                if (translatableEntities.Post) {
                    translatableEntities.Post._currentCulture = translatableEntities.Post && translatableEntities.Post.LocalizedLocale;
                    translatableEntities.Post._currentCultureId = translatableEntities.Post && translatableEntities.Post.LocalizedLocale.CultureId;
                }

                if (translatableEntities.Group) {
                    translatableEntities.Group._currentCulture = translatableEntities.Group && translatableEntities.Group.LocalizedLocale;
                    translatableEntities.Group._currentCultureId = translatableEntities.Group && translatableEntities.Group.LocalizedLocale.CultureId;
                }

                if (translatableEntities.Poll) {
                    translatableEntities.Poll._currentCulture = translatableEntities.Poll && translatableEntities.Poll.LocalizedLocale;
                    translatableEntities.Poll._currentCultureId = translatableEntities.Poll && translatableEntities.Poll.LocalizedLocale.CultureId;
                }

                if (translatableEntities.PollOptions?.length) {
                    translatableEntities.PollOptions = translatableEntities.PollOptions?.length &&
                        setLocalizedLocale(translatableEntities.PollOptions)
                }

                defer.resolve(translatableEntities);
            }

            return defer.promise;
        }

        function switchCalendarTranslatableLocale(translatableEntities, token) {
            if (!translatableEntities.Event._currentCultureId) {
                getCurrentTranslateableLocale(translatableEntities.Event);
            }

            if (translatableEntities.Category?._currentCultureId) {
                getCurrentTranslateableLocale(translatableEntities.Category);
            }

            if (translatableEntities.EventUpdate?._currentCultureId) {
                getCurrentTranslateableLocale(translatableEntities.EventUpdate);
            }

            if (translatableEntities.CancelReason?._currentCultureId) {
                getCurrentTranslateableLocale(translatableEntities.CancelReason);
            }


            var defer = $q.defer();

            if (translatableEntities.Event._currentCultureId === translatableEntities.Event.LocalizedLocale?.CultureId) {
                const hasLocalizedAlternativeLocale = translatableEntities.Event.LocalizedAlternativeLocale &&
                    (!translatableEntities.Category || !translatableEntities.Category?.AllowAutoTranslation) &&
                    (!translatableEntities.CancelReason || !translatableEntities.CancelReason?.AllowAutoTranslation) &&
                    (!translatableEntities.EventUpdate || !translatableEntities.EventUpdate?.AllowAutoTranslation);

                if (hasLocalizedAlternativeLocale) {
                    // we can return the alternative
                    setLocalizedAlternativeLocale(translatableEntities.Event);

                    if (translatableEntities.EventUpdate) {
                        setLocalizedAlternativeLocale(translatableEntities.EventUpdate);
                    }

                    if (translatableEntities.Category) {
                        setLocalizedAlternativeLocale(translatableEntities.Category);
                    }

                    if (translatableEntities.CancelReason) {
                        setLocalizedAlternativeLocale(translatableEntities.CancelReason);
                    }

                    defer.resolve(translatableEntities);
                } else {
                    // we need to generate a new translation
                    translateEventToUserCulture(token).then(res => {
                        translatableEntities = res;

                        setLocalizedAlternativeLocale(translatableEntities.Event);

                        if (translatableEntities.EventUpdate) {
                            setLocalizedAlternativeLocale(translatableEntities.EventUpdate);
                        }

                        if (translatableEntities.Category) {
                            setLocalizedAlternativeLocale(translatableEntities.Category);
                        }

                        if (translatableEntities.CancelReason) {
                            setLocalizedAlternativeLocale(translatableEntities.CancelReason);
                        }

                        defer.resolve(translatableEntities);
                    }).catch(() => {
                        defer.reject();
                    });
                }
            } else {
                setEventLocalizedLocale(translatableEntities.Event);

                if (translatableEntities.EventUpdate) {
                    setEventLocalizedLocale(translatableEntities.EventUpdate);
                }

                if (translatableEntities.Category) {
                    setEventLocalizedLocale(translatableEntities.Category);
                }

                if (translatableEntities.CancelReason) {
                    setEventLocalizedLocale(translatableEntities.CancelReason);
                }

                defer.resolve(translatableEntities);
            }

            return defer.promise;
        }

        function setEventLocalizedLocale(translatableEntity) {
            translatableEntity._currentCulture = translatableEntity && translatableEntity.LocalizedLocale;
            translatableEntity._currentCultureId = translatableEntity && translatableEntity.LocalizedLocale.CultureId;
        }

        function setLocalizedAlternativeLocale(translatableEntity) {
            translatableEntity._currentCulture = translatableEntity.LocalizedAlternativeLocale || translatableEntity.LocalizedLocale;
            translatableEntity._currentCultureId = (translatableEntity.LocalizedAlternativeLocale || translatableEntity.LocalizedLocale).CultureId;
        }

        function setAlternativeLocaleValuesSocial(translatableEntities) {
            if (translatableEntities.Post) {
                setLocalizedAlternativeLocale(translatableEntities.Post);
            }

            if (translatableEntities.Group) {
                setLocalizedAlternativeLocale(translatableEntities.Group);
            }
            
            if (translatableEntities.Poll) {
                setLocalizedAlternativeLocale(translatableEntities.Poll);
            }
            
            if (translatableEntities.PollOptions?.length) {
                translatableEntities.PollOptions = translatableEntities.PollOptions.length &&
                    setAlternativeLocale(translatableEntities.PollOptions)
            }
        }

        function setAlternativeLocaleValuesContent(translatableEntities) {
            setLocalizedAlternativeLocale(translatableEntities.Post);
            
            if (translatableEntities.Category) {
                setLocalizedAlternativeLocale(translatableEntities.Category);
            }
        }

        function setAlternativeLocaleValuesSurvey(translatableEntities) {
            translatableEntities.Question._currentCulture = translatableEntities.Question && translatableEntities.Question.LocalizedAlternativeLocale;
            translatableEntities.Question._currentCultureId = translatableEntities.Question.LocalizedAlternativeLocale.CultureId;

            translatableEntities.AnswerOptions = translatableEntities.AnswerOptions?.length &&
                translatableEntities.AnswerOptions.map(option => ({
                    ...option,
                    _currentCulture: (option.LocalizedAlternativeLocale || option.LocalizedLocale),
                    _currentCultureId: (option.LocalizedAlternativeLocale || option.LocalizedLocale).CultureId
                }))
        }

        function getSocialPostTranslation(SocialPostToken) {
            return $http.post('/Social/GetPostTranslation', {SocialPostToken});
        }

        function getContentPostTranslation(ContentId) {
            return $http.post('/ContentModule/TranslateContentPostToUserCulture', {ContentId});
        }

        function switchTranslateableLocale(translatableEntity, translateRequest) {
            // if we don't have a current culture ID we will fetch it
            if (!translatableEntity._currentCultureId) {
                getCurrentTranslateableLocale(translatableEntity);
            }

            var defer = $q.defer();

            // should we get the alternative?
            if (translatableEntity._currentCultureId == translatableEntity.LocalizedLocale?.CultureId) {
                if (translatableEntity.LocalizedAlternativeLocale) {
                    // we can return the alternative
                    translatableEntity._currentCultureId = translatableEntity.LocalizedAlternativeLocale.CultureId;
                    defer.resolve(translatableEntity.LocalizedAlternativeLocale);
                } else {
                    // we need to generate a new translation
                    translateRequest.then(res => {
                        translatableEntity = res;
                        translatableEntity._currentCultureId = res.LocalizedAlternativeLocale.CultureId;
                        defer.resolve(res.LocalizedAlternativeLocale);
                    }).catch(() => {
                        defer.reject();
                    });
                }
            } else {
                // we should return the "original"
                translatableEntity._currentCultureId = translatableEntity.LocalizedLocale.CultureId
                defer.resolve(translatableEntity.LocalizedLocale);
            }

            return defer.promise;
        }

        function switchTranslatableLocaleSurvey(question) {
            // if we don't have a current culture ID we will fetch it
            let translatableEntities = {
                Question: question.TranslatableEntity,
                AnswerOptions: question.SurveyQuestionTypeId !== 3 ? question.AnswerOptions.map(option => option.TranslatableEntity) : []
            }

            if (!translatableEntities.Question._currentCultureId) {
                getCurrentTranslateableLocale(translatableEntities.Question);

                if (translatableEntities.AnswerOptions?.length) {
                    translatableEntities.AnswerOptions.forEach(option => getCurrentTranslateableLocale(option));
                }
            }

            var defer = $q.defer();

            // should we get the alternative?
            if (translatableEntities.Question._currentCultureId === translatableEntities.Question.LocalizedLocale?.CultureId) {
                const hasLocalizedAlternativeLocale = translatableEntities.Question.LocalizedAlternativeLocale &&
                    translatableEntities.AnswerOptions
                        .filter(option => !option.AllowAutoTranslation)
                        .every(option => option.LocalizedAlternativeLocale);

                if (hasLocalizedAlternativeLocale) {
                    // we can return the alternative
                    setAlternativeLocaleValuesSurvey(translatableEntities);
                    defer.resolve(translatableEntities);
                } else {
                    // we need to generate a new translation
                    const surveyId = translatableEntities.Question.LocalizedLocale.SurveyQuestionId;
                    translateSurvey('Survey', question.SurveyQuestionId).then(res => {
                        translatableEntities = res.data;

                        setAlternativeLocaleValuesSurvey(translatableEntities);
                        defer.resolve(translatableEntities);
                    }).catch(() => {
                        defer.reject();
                    });
                }
            } else {
                // we should return the "original"
                translatableEntities.Question._currentCulture = translatableEntities.Question && translatableEntities.Question.LocalizedLocale;
                translatableEntities.Question._currentCultureId = translatableEntities.Question && translatableEntities.Question.LocalizedLocale.CultureId;

                if (question.SurveyQuestionTypeId !== 3) {
                    translatableEntities.AnswerOptions = translatableEntities.AnswerOptions?.length &&
                        translatableEntities.AnswerOptions.map(option => ({
                            ...option,
                            _currentCulture: option.LocalizedLocale,
                            _currentCultureId: option.LocalizedLocale.CultureId
                        }))

                }
                defer.resolve(translatableEntities);
            }

            return defer.promise;
        }

        function switchCatalogTranslatableLocale(translatableEntities, dataListItemToken) {
            const arrayHasCultureId = (array) => array.every(element => element._currentCultureId);

            if (!translatableEntities.Item._currentCultureId) {
                translatableEntities.Item._currentCultureId = translatableEntities.Item._currentCulture.CultureId;
            }

            if (translatableEntities.Categories?.length && !arrayHasCultureId(translatableEntities.Categories)) {
                translatableEntities.Categories.forEach(category => category._currentCultureId = category._currentCulture.CultureId);
            }

            if (translatableEntities.Fields?.length && !arrayHasCultureId(translatableEntities.Fields)) {
                translatableEntities.Fields.forEach(field => {
                    field._currentCultureId = field._currentCulture.CultureId;
                    field.Values.forEach(value => value._currentCultureId = value._currentCulture.CultureId)
                });
            }

            if (translatableEntities.Variants?.length && !arrayHasCultureId(translatableEntities.Variants)) {
                translatableEntities.Variants.forEach(variant => {
                    variant._currentCultureId = variant._currentCulture.CultureId;
                    variant.Values.forEach(value => value._currentCultureId = value._currentCulture.CultureId)
                })
            }

            var defer = $q.defer();

            const itemLocale = translatableEntities.Item._currentCultureId === translatableEntities.Item.TranslatableEntity.LocalizedLocale?.CultureId;
            const categoriesLocale = !translatableEntities.Categories?.length || translatableEntities.Categories.every(category =>
                category._currentCultureId === category.TranslatableEntity.LocalizedLocale?.CultureId);
            const fieldsLocale = !translatableEntities.Fields?.length === 0 || translatableEntities.Fields?.every(field =>
                field._currentCultureId === field?.TranslatableEntity.LocalizedLocale?.CultureId &&
                field.Values?.every(value => value._currentCultureId === value?.TranslatableEntity.LocalizedLocale?.CultureId));
            const variantsLocale = !translatableEntities.Variants?.length === 0 || translatableEntities.Variants?.every(variant =>
                variant._currentCultureId === variant.TranslatableEntity.LocalizedLocale?.CultureId &&
                variant.Values?.every(value => value._currentCultureId === value?.TranslatableEntity.LocalizedLocale?.CultureId));

            // should we get the alternative?
            if (itemLocale && categoriesLocale && fieldsLocale && variantsLocale) {
                if (hasEveryTranslatableEntityAlternativeLocale(translatableEntities)) {
                    // we can return the alternative
                    setAlternativeLocaleValuesCategory(translatableEntities);
                    defer.resolve(translatableEntities);
                } else {
                    // we need to generate a new translation
                    translateCatalogItem('Catalog', dataListItemToken).then(res => {
                        translatableEntities = res.data;

                        setTranslatedAlternativeLocaleValuesCategory(translatableEntities);
                        defer.resolve(translatableEntities);
                    }).catch(() => {
                        defer.reject();
                    });
                }
            } else {
                // we should return the "original"
                setLocalizedLocaleValuesCategory(translatableEntities);

                defer.resolve(translatableEntities);
            }

            return defer.promise;
        }

        function hasEveryTranslatableEntityAlternativeLocale(translatableEntities) {
            return !translatableEntities.Item.TranslatableEntity.AllowAutoTranslation &&
                (translatableEntities.Categories?.length === 0 || translatableEntities.Categories.every(category => !category.TranslatableEntity.AllowAutoTranslation)) &&
                (translatableEntities.Fields?.length === 0 || translatableEntities.Fields.every(field =>
                    !field.TranslatableEntity.AllowAutoTranslation && field.Values.every(value => !value.TranslatableEntity.AllowAutoTranslation))) &&
                (translatableEntities.Variants?.length === 0 || translatableEntities.Variants.every(variant =>
                    !variant.TranslatableEntity.AllowAutoTranslation && variant.Values.every(value => !value.TranslatableEntity.AllowAutoTranslation)));
        }

        function setAlternativeLocaleValuesCategory(translatableEntities) {
            const itemTranslatableEntity = translatableEntities.Item.TranslatableEntity;
            translatableEntities.Item._currentCulture = itemTranslatableEntity.LocalizedAlternativeLocale ?? itemTranslatableEntity.LocalizedLocale;
            translatableEntities.Item._currentCultureId = (itemTranslatableEntity.LocalizedAlternativeLocale ?? itemTranslatableEntity.LocalizedLocale).CultureId;

            translatableEntities.Categories = translatableEntities.Categories?.length &&
                setAlternativeTranslatableEntity(translatableEntities.Categories) || [];

            translatableEntities.Fields = translatableEntities.Fields?.length &&
                setAlternativeLocaleForNestedValues(translatableEntities.Fields) || [];

            translatableEntities.Variants = translatableEntities.Variants?.length &&
                setAlternativeLocaleForNestedValues(translatableEntities.Variants) || [];
        }

        function setTranslatedAlternativeLocaleValuesCategory(translatableEntities) {
            translatableEntities.Item.TranslatableEntity = translatableEntities.Item;
            translatableEntities.Item._currentCulture = translatableEntities.Item.LocalizedAlternativeLocale ?? translatableEntities.Item.LocalizedLocale;
            translatableEntities.Item._currentCultureId = (translatableEntities.Item.LocalizedAlternativeLocale ?? translatableEntities.Item.LocalizedLocale).CultureId;

            translatableEntities.Categories = translatableEntities.Categories?.length &&
                (translatableEntities.Categories[0].TranslatableEntity ? setAlternativeTranslatableEntity(translatableEntities.Categories)
                    : setAlternativeLocale(translatableEntities.Categories)) || [];

            translatableEntities.Fields = translatableEntities.Fields?.length &&
                setAlternativeLocaleForNestedValues(translatableEntities.Fields) || [];

            translatableEntities.Variants = translatableEntities.Variants?.length &&
                setAlternativeLocaleForNestedValues(translatableEntities.Variants) || [];
        }

        function setLocalizedLocaleValuesCategory(translatableEntities) {
            if (translatableEntities.Item.TranslatableEntity.LocalizedLocale) {
                translatableEntities.Item._currentCulture = translatableEntities.Item.TranslatableEntity.LocalizedLocale;
                translatableEntities.Item._currentCultureId = translatableEntities.Item.TranslatableEntity.LocalizedLocale.CultureId;
            }

            translatableEntities.Categories = translatableEntities.Categories?.length &&
                setLocalizedTranslatableEntity(translatableEntities.Categories) || [];

            translatableEntities.Fields = translatableEntities.Fields?.length &&
                setLocalizedLocaleForNestedValues(translatableEntities.Fields) || [];

            translatableEntities.Variants = translatableEntities.Variants?.length &&
                setLocalizedLocaleForNestedValues(translatableEntities.Variants) || [];
        }

        function setAlternativeLocaleForNestedValues(translatableEntitiesArray) {
            return translatableEntitiesArray.map(option => ({
                ...option,
                _currentCulture: option.TranslatableEntity.LocalizedAlternativeLocale || option.TranslatableEntity.LocalizedLocale,
                _currentCultureId: (option.TranslatableEntity.LocalizedAlternativeLocale || option.TranslatableEntity.LocalizedLocale).CultureId,
                Values: option.Values.map(value => ({
                    ...value,
                    _currentCulture: value.TranslatableEntity.LocalizedAlternativeLocale ?? value.TranslatableEntity.LocalizedLocale,
                    _currentCultureId: (value.TranslatableEntity.LocalizedAlternativeLocale ?? value.TranslatableEntity.LocalizedLocale).CultureId
                }))
            }))
        }

        function setLocalizedLocaleForNestedValues(translatableEntitiesArray) {
            return translatableEntitiesArray.map(option => ({
                ...option,
                _currentCulture: option.TranslatableEntity.LocalizedLocale,
                _currentCultureId: option.TranslatableEntity.LocalizedLocale?.CultureId,
                Values: option.Values.map(value => ({
                    ...value,
                    _currentCulture: value.TranslatableEntity.LocalizedLocale,
                    _currentCultureId: value.TranslatableEntity.LocalizedLocale?.CultureId
                }))
            }))
        }

        function setAlternativeLocale(translatableEntitiesArray) {
            return translatableEntitiesArray.map(option => ({
                ...option,
                _currentCulture: option.LocalizedAlternativeLocale || option.LocalizedLocale,
                _currentCultureId: (option.LocalizedAlternativeLocale || option.LocalizedLocale).CultureId
            }))
        }

        function setAlternativeTranslatableEntity(translatableEntitiesArray) {
            return translatableEntitiesArray.map(option => ({
                TranslatableEntity: option.TranslatableEntity,
                _currentCulture: option.TranslatableEntity.LocalizedAlternativeLocale || option.TranslatableEntity.LocalizedLocale,
                _currentCultureId: (option.TranslatableEntity.LocalizedAlternativeLocale || option.TranslatableEntity.LocalizedLocale).CultureId
            }))
        }

        function setLocalizedTranslatableEntity(translatableEntitiesArray) {
            return translatableEntitiesArray.map(option => ({
                ...option,
                TranslatableEntity: option.TranslatableEntity,
                _currentCulture: option.TranslatableEntity.LocalizedLocale,
                _currentCultureId: option.TranslatableEntity.LocalizedLocale?.CultureId
            }))
        }

        function setLocalizedLocale(translatableEntitiesArray) {
            return translatableEntitiesArray.map(option => ({
                ...option,
                _currentCulture: option.LocalizedLocale,
                _currentCultureId: option.LocalizedLocale?.CultureId
            }))
        }
    }
})();