(() => {
    'use strict';

    angular
        .module('App')
        .component('selectRepeat', {
            template: require('./SelectRepeatComponent.html'),
            controller: ['$scope', '$timeout', '$filter', 'CalendarRenderService', selectRepeatController],
            controllerAs: 'ctrl',
            require: {
                ngModelCtrl: 'ngModel'
            },
            bindings: {
                startDate: '<',
                endDate: '<',
                defaultTaskOccurrence: '<',
                openRepeatSelector: '=?',
                showRepeatSelector: '=?',
                isEdit: '<'
            }
        });


    function selectRepeatController($scope, $timeout, $filter, CalendarRenderService) {
        const ctrl = this;

        ctrl.showRepeatSelector = false;
        
        ctrl.repeatOptions = [
            {
                title: 'REPEAT.NEVER',
                label: 'REPEAT.NEVER',
                repeat: 'NEVER',
                disabled: ctrl.isEdit,
                selected: false,
            },
            {
                title: 'REPEAT.DAILY',
                label: 'REPEAT.DAILY',
                repeat: 'DAILY',
                selected: false,
            },
            {
                title: 'REPEAT.WEEKLY',
                label: 'REPEAT.WEEKLY',
                repeat: 'WEEKLY',
                selected: false,
            },
            {
                title: 'REPEAT.MONTHLY',
                label: 'REPEAT.MONTHLY',
                repeat: 'MONTHLY',
                selected: false,
            }
        ];

        ctrl.weekdayValue = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

        ctrl.regularity = [
            {name: 'REPEAT.FIRST', selected: false, value: '1'}, {name: 'REPEAT.SECOND', selected: false, value: '2'},
            {name: 'REPEAT.THIRD', selected: false, value: '3'}, {name: 'REPEAT.FOURTH', selected: false, value: '4'},
            {name: 'REPEAT.LAST', selected: false, value: '-1'}
        ];

        ctrl.$onInit = init;
        ctrl.back = back;
        ctrl.openRepeatSelector = openRepeatSelector;
        ctrl.selectRepeatOption = selectRepeatOption;
        ctrl.getFrequency = getFrequency;
        ctrl.selectWeekDay = selectWeekDay;
        ctrl.save = save;

        function init() {
            initData();

            $timeout(function () {
                ctrl.disablePopup = ctrl.isEdit && !ctrl.ngModelCtrl.$viewValue;
                setData();

                $scope.$watch('ctrl.repeat', watch, true);
                $scope.$watch('ctrl.startDate', watch);
                $scope.$watch('ctrl.endDate', watch);
                $scope.$watch('ctrl.byMonthDay', watch, true);
            });
        }

        function setData() {
            if (ctrl.ngModelCtrl.$viewValue) {
                setEditData(ctrl.ngModelCtrl.$viewValue);
            } else {
                ctrl.repeatOptions[0].selected = true;
            }
        }

        function initData() {
            cleanData();
            getWeekdays();
            ctrl.repeatOptions = ctrl.repeatOptions.filter(option => !option.disabled );
            ctrl.repeatOptions[0].selected = true;
            ctrl.selectedItem = { ...ctrl.repeatOptions[0] };
            ctrl.byMonthDay = true;
            ctrl.initialValue = {
                frequency: ctrl.selectedItem,
                regularity: ctrl.regularity[0],
                weekDay: ctrl.monthWeek[0],
                monthlyDay: 1,
                every: 1,
                until: setDefaultDate().toDate(),
                untilStr: ' ' + $filter('translate')('REPEAT.UNTIL').toLowerCase() + ' ' + moment(setDefaultDate().toDate()).format('MM/DD/YY')
            };

            ctrl.repeat = {...ctrl.initialValue};
        }

        function checkValidity() {
            const dailyRule = ctrl.repeat.frequency?.repeat && ctrl.repeat.every && ctrl.repeat.until;
            switch (ctrl.repeat.frequency.repeat) {
                case 'NEVER':
                    return true;
                case 'DAILY':
                case 'WEEKLY':
                    return dailyRule;
                case 'MONTHLY':
                    return dailyRule && (ctrl.byMonthDay ?
                        ctrl.repeat.monthlyDay : ctrl.repeat.weekDay?.value && ctrl.repeat.regularity?.value);
                default:
                    return false;

            }
        }

        function getWeekdays() {
            ctrl.week = CalendarRenderService.getShortWeekdays(true)
                .map((dayShort, index) =>
                    ({title: dayShort[0].toUpperCase() + dayShort.slice(1), value: ctrl.weekdayValue[index]}));
            ctrl.monthWeek = CalendarRenderService.getWeekdays(true)
                .map((dayFull, index) =>
                    ({name: dayFull[0].toUpperCase() + dayFull.slice(1), value: ctrl.weekdayValue[index]}));
        }

        function getFrequency() {
            const item = ctrl.repeatOptions.find(option => option.selected);
            switch (item?.repeat) {
                case 'DAILY':
                    return $filter('translate')('REPEAT.DAY').toLowerCase();
                case 'WEEKLY':
                    return $filter('translate')('REPEAT.WEEK').toLowerCase();
                case 'MONTHLY':
                    return $filter('translate')('REPEAT.MONTH').toLowerCase();
                default:
                    return '';
            }
        }

        function selectWeekDay(day) {
            day.selected = !day.selected;
        }

        function setEditData(string) {
            const repeat = string.split(';'), repeatObj = {};

            _.each(repeat, function (option) {
                const optionObj = option.split('=');

                repeatObj[optionObj[0].toLowerCase()] = optionObj[1];
            });

            if (repeatObj.freq) {
                ctrl.repeatOptions = ctrl.repeatOptions.map(option => ({
                    ...option,
                    selected: option.repeat === repeatObj.freq
                }));
                ctrl.repeat.frequency = ctrl.repeatOptions.find(option => option.selected);
                ctrl.labelText = ctrl.repeat.frequency.label;
                ctrl.selectedItem = ctrl.repeat.frequency;
            }

            if (repeatObj.bymonthday) {
                ctrl.byMonthDay = repeatObj.bymonthday;
                ctrl.repeat.monthlyDay = parseInt(repeatObj.bymonthday)
            }

            if (repeatObj.byday) {
                const days = repeatObj.byday.split(',');
                if (ctrl.repeat.frequency.repeat === 'WEEKLY') {
                    ctrl.week = markSelected(ctrl.week, days);
                } else {
                    ctrl.byMonthDay = false;
                    ctrl.monthWeek = markSelected(ctrl.monthWeek, days);
                    ctrl.repeat.weekDay = ctrl.monthWeek.find(week => week.value === days[0]);
                }
            }

            if (repeatObj.bysetpos) {
                ctrl.regularity = markSelected(ctrl.regularity, [repeatObj.bysetpos]);
                ctrl.repeat.regularity = ctrl.regularity.find(element => element.value === repeatObj.bysetpos);
            }

            ctrl.repeat.every = repeatObj.interval ? parseInt(repeatObj.interval) : 1;
            ctrl.repeat.until = (repeatObj.until ? moment(repeatObj.until) : setDefaultDate()).toDate();
            ctrl.repeat.untilStr = ' ' + $filter('translate')('REPEAT.UNTIL').toLowerCase() + ' ' + moment(ctrl.repeat.until).format('MM/DD/YY');

            function markSelected(week, days) {
                return week.map(day => ({...day, selected: days.includes(day.value)}));
            }
        }

        function watch(newVal) {
            ctrl.isValid = checkValidity();

            if (newVal) {
                $timeout(() => {
                    ctrl.repeat.frequency?.repeat === 'MONTHLY' && updateMonthlySelectorValues(newVal)
                }, 100);
            }
        }

        function updateMonthlySelectorValues(newValue) {
            if (newValue.weekDay && newValue.regularity) {
                ctrl.monthWeek = ctrl.monthWeek.map(week => ({
                    ...week,
                    selected: week.value === newValue.weekDay.value
                }));
                ctrl.regularity = ctrl.regularity.map(day => ({
                    ...day,
                    selected: day.value === newValue.regularity.value
                }));
            }
        }

        function back() {
            ctrl.showRepeatSelector = false;
        }
        
        function cleanData() {
            ctrl.repeatOptions = unselectItems(ctrl.repeatOptions);
            ctrl.regularity = unselectItems(ctrl.regularity);
            ctrl.monthWeek = [];
            ctrl.week = [];
        }
        
        function unselectItems(items) {
            return items.map(item => ({ ...item, selected: false}));
        }

        function save() {
            ctrl.ngModelCtrl.$setViewValue(generateRuleString());
            cleanData();
            ctrl.showRepeatSelector = false;
        }

        function openRepeatSelector() {
            if (ctrl.disablePopup) {
                return;
            }

            initData();
            ctrl.ngModelCtrl.$viewValue && setData();
            ctrl.showRepeatSelector = true;
        }

        function selectRepeatOption(option) {
            ctrl.repeat.frequency = option;

            ctrl.repeatOptions = ctrl.repeatOptions.map(item => ({
                ...item,
                selected: option.title === item.title
            }));
        }

        function generateRuleString() {
            let repeatString = '';

            if (ctrl.repeat.frequency.repeat !== null) {
                repeatString += 'FREQ=' + ctrl.repeat.frequency.repeat + ';';

                if (ctrl.startDate) {
                    repeatString += 'DTSTART=' + moment(ctrl.startDate).format('YYYYMMDD[T]HHmmss') + ';';
                }
                if (ctrl.repeat.every) {
                    repeatString += 'INTERVAL=' + ctrl.repeat.every + ';';
                }
                if (ctrl.repeat.frequency?.repeat === 'WEEKLY') {
                    const selectedDays = ctrl.week.filter(day => day.selected);

                    if (selectedDays?.length > 0) {
                        const daysString = selectedDays.map(day => day.value).join(',');
                        repeatString += 'BYDAY=' + daysString + ';';
                    }
                }
                if (ctrl.repeat.frequency?.repeat === 'MONTHLY') {
                    if (ctrl.byMonthDay) {
                        repeatString += 'BYMONTHDAY=' + ctrl.repeat.monthlyDay + ';';
                    } else if (ctrl.repeat.weekDay && ctrl.repeat.regularity) {
                        repeatString += 'BYDAY=' + ctrl.repeat.weekDay.value + ';';
                        repeatString += 'BYSETPOS=' + ctrl.repeat.regularity.value + ';';
                    }
                }
                if (ctrl.repeat.until) {
                    const until = moment(ctrl.repeat.until);

                    ctrl.repeat.untilStr = ' ' + $filter('translate')('REPEAT.UNTIL').toLowerCase() + ' ' + until.format('MM/DD/YY');

                    if (ctrl.endDate) {
                        until.hours(moment(ctrl.endDate).hours())
                            .minutes(moment(ctrl.endDate).minutes())
                            .seconds(0);
                    }
                    repeatString += 'UNTIL=' + moment(until).format('YYYYMMDD[T]HHmmss') + ';';
                } else {
                    const date = setDefaultDate();
                    ctrl.repeat.until = date.toDate();
                    repeatString += 'UNTIL=' + date.format('YYYYMMDD[T]HHmmss') + ';';
                }

                ctrl.labelText = ctrl.repeat.frequency.label;

                return ctrl.labelText === 'REPEAT.NEVER' ? '' : repeatString.slice(0, -1);
            } else {
                delete ctrl.labelText;
            }
        }

        function setDefaultDate() {
            return moment(ctrl.endDate).minutes(0).seconds(0)
                .add(ctrl.defaultTaskOccurrence, 'months').subtract(1, 'days');
        }
    }
})();


