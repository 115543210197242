(function () {
    'use strict';

    angular
        .module('App')
        .factory('BookmarkService', ['$http', '$q', '$filter', '$rootScope', 'Page', 'PopupWrapperService', BookmarkService]);

    function BookmarkService($http, $q, $filter, $rootScope, Page, PopupWrapperService) {
        var bookmarks = [];
        var loadingPromises = [];
        var isLoaded = false,
            isLoading = false,
            isDropdownVisible = false;

        return {
            loadBookmarks: loadBookmarks,
            createBookmark: createBookmark,
            deleteBookmark: deleteBookmark,
            getBookmarks: () => bookmarks,
            isEnabled: isEnabled,
            isLoading: () => isLoading,
            isLoaded: () => isLoaded,
            openPopup: openPopup,
            toggleDropdownVisibility: toggleDropdownVisibility,
            getDropdownVisibility: () => isDropdownVisible
        };

        function loadBookmarks() {
            var d = $q.defer();

            loadingPromises.push(d);

            if (!isLoading) {
                isLoading = true;
                $http.get('/Bookmark/GetList')
                    .then((response) => {
                        bookmarks = response.data;
                        isLoaded = true;
                        isLoading = false;
                        loadingPromises.forEach(p => {
                            p.resolve(true);
                        });
                        loadingPromises = [];
                    })
                    .catch(() => {
                        isLoading = false;
                        loadingPromises.forEach(p => {
                            p.resolve(false);
                        });
                        loadingPromises = [];
                    });
            }

            return d.promise;
        }

        function createBookmark(accountModuleId, moduleId, moduleItemId, title) {
            var d = $q.defer();

            $http.post('/Bookmark/Create', {
                AccountModuleId: accountModuleId,
                ModuleId: moduleId,
                ModuleItemId: moduleItemId,
                Title: title
            }).success(function (response) {
                if (response.created) {
                    bookmarks.unshift(response.bookmark);
                    d.resolve(response.bookmark.BookmarkId);
                } else {
                    d.resolve(false);
                }
            }).error(function () {
                d.resolve(false);
            });

            return d.promise;
        }

        function deleteBookmark(bookmarkId) {
            var d = $q.defer();

            $http.post('/Bookmark/Delete/' + bookmarkId).success(function (response) {
                if (response.deleted) {
                    d.resolve(true);

                    var item = $filter('filter')(bookmarks, {BookmarkId: bookmarkId}, true)[0];
                    if (item) {
                        var index = bookmarks.indexOf(item);
                        if (index >= 0) {
                            bookmarks.splice(index, 1);
                        }
                    }
                } else {
                    d.resolve(false);
                }
            }).error(function () {
                d.resolve(false);
            });

            return d.promise;
        }

        function isEnabled() {
            var settings = Page.getSettings();
            if (settings && settings.Base) {
                return settings.Base.BookmarkingEnabled;
            }
            return false;
        }

        function openPopup() {
            PopupWrapperService.createDynamic(
                '<bookmarks-popup ' +
                'on-close="onClose" ' +
                'on-popup-registered="registeredCallback"></bookmarks-popup>',
                {
                    registeredCallback: function (popup) {
                        popup.open();
                    },
                    onClose: function () {
                        $rootScope.disableVisibilitychange = false;
                    }
                }
            )
        }

        function toggleDropdownVisibility(visible) {
            if(_.isBoolean(visible)){
                isDropdownVisible = visible;
            }else{
                isDropdownVisible = !isDropdownVisible;
            }

            if (isDropdownVisible && !isLoaded) {
                loadBookmarks();
            }
        }
    }
})();