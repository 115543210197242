(function () {
    'use strict';

    angular.module('App')
        .component('conversation', {
            template: require('./ConversationComponent.tpl.html'),
            controllerAs: 'ctrl',
            bindings: {
                channelId: '<',
                channel: '<'
            },
            controller: ['$window', '$rootScope', '$scope', '$element', '$timeout', '$interval', 'ChatDataService',
                'Profile', 'ChatPopupsService', 'ChatConnectionService', 'ToastFactory', 'ProfileViewerService', 'Page',
                'ConversationService', 'PopupWrapperService', 'ResponsiveService', 'MentionService', 'ContentSafetyWarningService',
                'MESSAGE_STATUS', ConversationController]
        });

    function ConversationController($window, $rootScope, $scope, $element, $timeout, $interval, ChatDataService,
                                    Profile, ChatPopupsService, ChatConnectionService, ToastFactory,
                                    ProfileViewerService, Page, ConversationService, PopupWrapperService,
                                    ResponsiveService, MentionService, ContentSafetyWarningService, MESSAGE_STATUS) {

        let popup, scrollContainer, lastScrollPosition, typingTimeout, getChannelPromise, loadPreviousMessages,
            loadNextMessages, loadAfterMessageId, canPublish = true, lastToMessageScroll;

        const ctrl = this, markAsReadDebounced = _.debounce(markAsRead, 1000);
        const removePushOpenWatcher = $rootScope.$on('CHAT.OPEN_FROM_PUSH', () => close());

        ctrl.isDifferentDays = ConversationService.isDifferentDays;
        ctrl.isFirstMessageInRow = ConversationService.isFirstMessageInRow;
        ctrl.mentions = [];

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onOpenPopup = onOpenPopup;
        ctrl.onPopupRendered = onPopupRendered;
        ctrl.close = close;
        ctrl.showChatInfo = showChatInfo;
        ctrl.sendMessage = sendMessage;
        ctrl.typing = typing;
        ctrl.onBlur = onBlur;
        ctrl.openAddUsers = openAddUsers;
        ctrl.onMediaUpdated = onMediaUpdated;
        ctrl.removeMedia = removeMedia;
        ctrl.openChannelInfo = openChannelInfo;
        ctrl.goToReplyingMessage = goToReplyingMessage;
        ctrl.cancelEditing = resetMessage;
        ctrl.openRepliedContent = openRepliedContent;
        ctrl.clearReplyingMessage = clearReplyingMessage;
        ctrl.scrollButtonClick = scrollButtonClick;
        ctrl.replyToMessage = replyToMessage;
        ctrl.editMessage = editMessage;
        ctrl.getMember = getMember;
        ctrl.onOpenOptions = onOpenOptions;
        ctrl.isUserNotBlocked = isUserNotBlocked;
        ctrl.parseMentions = parseMentions;
        ctrl.onRenderCompleted = onRenderCompleted;

        function init() {
            const conversation = $element.find('.popup-content')[0];
            ctrl.messages = [];
            ctrl.users = [];
            ctrl.channel = ctrl.channel || {};
            ctrl.messageViews = {};
            ctrl.uploadedMedia = [];
            ctrl.messageReadStats = [];

            ctrl.currentUserToken = Profile.getProfile().UserToken;
            ctrl.profileCardEnabled = Page.getSettings().Base.ProfileCardEnabled;


            if (!ResponsiveService.isDesktop()) {
                setMobileEvents(conversation);
            } else {
                setDesktopEvents(conversation);
            }

            if (ctrl.channel && ctrl.channel.length) {
                ctrl.currentChannelCompanion = ChatDataService.getChannelCompanion(ctrl.channel);
                ctrl.hideAuthorForCurrentUser = ctrl.channel.item.hideAuthor
                    && ctrl.channel.item.creatorUserId === ctrl.currentUserToken;
            }

            if (ctrl.channel.item) {
                usersForMention();
            }

            $element.on('click', '.post-tag', showMentionProfile);
        }

        function setMobileEvents(conversation) {
            ctrl.btnHammer = new Hammer(conversation);
            ctrl.btnHammer.get('press').set({time: 300});
            ctrl.btnHammer.on('press', showMobileMenu);
            ctrl.btnHammer.on('tap', hideMobileMenu)

            function showMobileMenu(event) {
                if (event.target.closest('.message-body-wrapper')) {
                    event.preventDefault();

                    $scope.$apply(() => {
                        ctrl.messages = ctrl.messages.map(message => ({
                            ...message,
                            isMenuPressed: message.item.messageId === event.target.closest('.message-wrapper').getAttribute('data-index')
                        }))
                    })
                }
            }

            function hideMobileMenu(event) {
                if (event.target.closest('.message-form')) {
                    $scope.$apply(() => {
                        ctrl.messages = ctrl.messages.map(message => ({
                            ...message,
                            isMenuPressed: false
                        }))
                    })
                }
            }
        }
        
        function setDesktopEvents(conversation) {
            let lastHoveredMessage;
            const throttledMove = _.throttle((moveEvent) => {
                const
                    hoveredElement = moveEvent.target.closest('.message-body-wrapper'),
                    message = moveEvent.target.closest('.message'),
                    desktopMenu = moveEvent.target.closest('desktop-message-menu');

                if (hoveredElement && !message.classList.contains('hover')) {
                    lastHoveredMessage && lastHoveredMessage.classList.remove('hover');
                    lastHoveredMessage = message;
                    lastHoveredMessage.classList.add('hover');
                } else if (!hoveredElement && !desktopMenu && lastHoveredMessage && !lastHoveredMessage.classList.contains('highlighted')) {
                    lastHoveredMessage.classList.remove('hover');
                    lastHoveredMessage = undefined;
                }
            }, 200);
            conversation.addEventListener('mousemove', throttledMove);
        }

        function showMentionProfile(ev) {
            ev.stopPropagation();
            var userToken = $(ev.currentTarget).find(".post-tag-user-token");
            if (userToken && userToken[0]) {
                ProfileViewerService.showProfileCard(userToken[0].innerHTML);
            }
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function onOpenPopup() {
            getChannelPromise = ChatDataService.getChannel(ctrl.channelId)
                .then(channels => {
                    ctrl.channel = Object.assign(ctrl.channel, channels);
                    ctrl.channelLoaded = true;

                    if (ctrl.channel.item) {
                        usersForMention();
                    }

                    ctrl.currentChannelCompanion = ctrl.currentChannelCompanion
                        || ChatDataService.getChannelCompanion(ctrl.channel);
                    ctrl.hideAuthorForCurrentUser = ctrl.channel.item.creatorUserId === ctrl.currentUserToken
                        && ctrl.channel.item.hideAuthor;

                    initEvents();
                    markAsRead();

                    $rootScope.$broadcast('CHAT.CHANNEL_MARKED_AS_READ', ctrl.channelId);

                    return channels
                });
        }

        function usersForMention() {
            ctrl.members = ctrl.channel.members?.filter(member => !(ctrl.channel.blockedUsers ?? []).includes(member.userId));
        }

        function parseMentions(message, members) {
            return message && MentionService.parseMentionToText(message, members);
        }

        function initEvents() {
            if (ctrl.channel.item.isGroupChat) {
                ChatConnectionService.onChannelItemEvents(ctrl.channel.item.channelId, channelEventsCallback)
            }

            ConversationService.onChannelMarkedAsRead(ctrl.channel.item.channelId, onChannelMarkAsRead);
            ConversationService.onNewMessage(ctrl.channel.item.channelId, onNewMessage);
            ConversationService.onReactionUpdate(ctrl.channel.item.channelId, onReactionUpdate);
            ConversationService.onTyping(ctrl.channel.item.channelId, onUserTyping);
            ConversationService.onUpdateMessage(onMessageUpdate);
        }

        function onPopupRendered() {
            ctrl.initialLoad = true;
            getChannelMessages().then(() => {
                $('.message-form button').on('touchend', forceSubmit);
                $window.addEventListener('resize', onResize);

                if (window.visualViewport) {
                    window.visualViewport.addEventListener("resize", onResize)
                }

                getChannelPromise.then(channel => {
                    scrollContainer = $element.find('.messages-list-wrapper')[0];
                    lastScrollPosition = scrollContainer.scrollTop;
                    generateMessageReads(channel)
                    scrollToBottom(() => {
                        ctrl.initialLoad = false;
                        $timeout(() => {
                            scrollContainer.addEventListener('scroll', handleScroll);
                            scrollContainer.addEventListener('scroll', handleScrollButton);
                        }, 200)
                    });
                })
            })
        }

        function handleScroll() {
            if (ctrl.fetchingMessages) {
                return
            }
            if (isScrollTop()) {
                if (scrollContainer.scrollTop <= 0 && loadPreviousMessages) {
                    const sh = scrollContainer.scrollHeight;
                    ctrl.fetchingMessages = true;
                    getChannelMessages({beforeMessageId: ctrl.messages[0].item.messageId})
                        .then(({messages}) => {
                            if (messages.length) {
                                $timeout(() => {
                                    scrollContainer.scrollTop = scrollContainer.scrollHeight - sh;
                                    ctrl.fetchingMessages = false;
                                })
                            }
                        });
                }
            } else {
                if (loadNextMessages && (scrollContainer.scrollTop + scrollContainer.offsetHeight) >= fromLoadPosition()) {
                    ctrl.fetchingMessages = true;
                    getChannelMessages({afterMessageId: loadAfterMessageId})
                        .then(_ => ctrl.fetchingMessages = false);
                }
            }

            function fromLoadPosition() {
                return $element.find('.load-messages-after')[0].offsetTop
            }
        }

        function handleScrollButton() {
            $scope.$apply(() => {
                ctrl.showScrollButton = scrollContainer.scrollTop + scrollContainer.offsetHeight <= scrollContainer.scrollHeight - 10;
            })
        }

        function goToReplyingMessage(message, event) {
            event.stopPropagation();

            if (message.isReplyMessageBlocked) {
                return;

            }

            const messageEl = getMessageElement();
            if (messageEl.length) {
                scrollToMessage(messageEl, true)
            } else {
                scrollContainer.removeEventListener('scroll', handleScroll);
                ctrl.fetchingMessages = true;
                getChannelMessages({aroundMessageId: message.replyToMessage.messageId}, true)
                    .then(() => {
                        const interval = $interval(() => {
                            const message = getMessageElement();
                            if (message.length) {
                                scrollToMessage(message, true);
                                $interval.cancel(interval);
                                ctrl.fetchingMessages = false;
                                $timeout(() => {
                                    scrollContainer.addEventListener('scroll', handleScroll);
                                }, 20)
                            }
                        }, 100)
                    });
            }
            lastToMessageScroll = $element.find('.message-wrapper[data-index=' + message.item.messageId + ']');

            function getMessageElement() {
                return $element.find('.message-wrapper[data-index=' + message.replyToMessage.messageId + ']');
            }
        }

        function scrollButtonClick() {
            scrollContainer.removeEventListener('scroll', handleScroll);
            if (lastToMessageScroll) {
                scrollToMessage(lastToMessageScroll)
                lastToMessageScroll = null;
            } else {
                scrollToBottom(() => {
                    $timeout(() => {
                        scrollContainer.addEventListener('scroll', handleScroll);
                    }, 20)
                });
            }
        }

        function getChannelMessages(options = {}, loadReplyMessage) {
            let haveLoadedMessage;

            return ChatDataService.getChannelMessages(ctrl.channelId, options)
                .then(({messages, users}) => {
                    haveLoadedMessage = messages.some(newMessage => ctrl.messages.find(message => message.item.messageId === newMessage.item.messageId))
                    loadPreviousMessages = messages.length === (options.limit || 50);

                    if (options.afterMessageId) {
                        const index = ctrl.messages.findIndex(message => message.item.messageId === options.afterMessageId);
                        ctrl.messages.splice(index, 0, ...addMessages(messages));
                    } else {
                        ctrl.messages = [...addMessages(messages), ...ctrl.messages];
                    }
                    ctrl.users = [...addUsers(users), ...ctrl.users];

                    if (loadReplyMessage || options.afterMessageId) {
                        const lastMessageIndex = ctrl.messages.findIndex(message => message.loadNextMessages);
                        if (lastMessageIndex !== -1) {
                            delete ctrl.messages[lastMessageIndex].loadNextMessages;
                        }
                        if (!haveLoadedMessage) {
                            messages[messages.length - 1].loadNextMessages = true;
                            loadAfterMessageId = messages[messages.length - 1].item.messageId;
                        }
                        loadNextMessages = !haveLoadedMessage
                    }

                    return {
                        messages
                    }
                })

            function addUsers(users) {
                return users.filter(newUser => !ctrl.users.find(user => user.userId === newUser.userId))
            }

            function addMessages(messages) {
                return messages.filter(newMessage => !ctrl.messages.find(message => message.item.messageId === newMessage.item.messageId))
            }
        }

        function isScrollTop() {
            if (lastScrollPosition > scrollContainer.scrollTop) {
                lastScrollPosition = scrollContainer.scrollTop;
                return true
            } else {
                lastScrollPosition = scrollContainer.scrollTop;
                return false
            }
        }

        function channelEventsCallback(channel) {
            ctrl.channel = channel;
            usersForMention();
        }

        function onNewMessage({item, replyToMessage}) {
            const newMessage = {item, replyToMessage, isBlocked: ctrl.channel.blockedUsers?.includes(item.authorId)};
            if (item.authorId !== ctrl.currentUserToken) {
                markAsReadDebounced();
            } else {
                newMessage.status = MESSAGE_STATUS.DELIVERED;
                ctrl.messages = ctrl.messages.map(message => !message.item.dateModified && message.status !== MESSAGE_STATUS.EDITED ?
                    {...message, status: null} : {...message});
            }

            if (item?.mediaIds?.length) {
                ChatDataService.getMedias(item.mediaIds).then(medias => {
                    newMessage.medias = medias;
                });
            }
            if (replyToMessage?.mediaIds?.length) {
                ChatDataService.getMedias(replyToMessage.mediaIds).then(medias => {
                    newMessage.replyToMessageMedias = medias;
                });
            }

            $scope.$apply(() => {
                ctrl.messages.push(newMessage);
                ctrl.channel.item.lastMessageId = newMessage.item.messageId;

                if (item.authorId === ctrl.currentUserToken || (scrollContainer.offsetHeight + scrollContainer.scrollTop >= scrollContainer.scrollHeight)) {
                    scrollToBottom();
                }
            })
        }

        function onMessageUpdate({item}) {
            const messageIndex = ctrl.messages.findIndex(msg => msg.item.messageId === item.messageId),
                repliedToMessageIndexes = ctrl.messages
                    .map((msg, index) => msg.replyToMessage?.messageId === item.messageId ? index : -1)
                    .filter(ind => ind !== -1);

            ctrl.messages = ctrl.messages.map(message => message.item.messageId === item.messageId ?
                {...message, status: MESSAGE_STATUS.EDITED} : {...message});

            if (messageIndex !== -1) {
                $scope.$apply(() => {
                    ctrl.messages[messageIndex].item.text = item.text;
                    ctrl.messages[messageIndex].item.mediaIds = item.mediaIds;
                    ctrl.messages[messageIndex].item.dateDeleted = item.dateDeleted;
                    if (item.dateDeleted) {
                        ctrl.messages[messageIndex].item.reactionsSummary = [];
                    }
                })
            }
            if (repliedToMessageIndexes?.length) {
                $scope.$apply(() => {
                    repliedToMessageIndexes.forEach(msgIndex => {
                        ctrl.messages[msgIndex].replyToMessage = item;
                    })
                })
            }
            if (item?.mediaIds?.length) {
                ChatDataService.getMedias(item.mediaIds).then(medias => {
                    ctrl.messages[messageIndex].medias = medias;
                    if (repliedToMessageIndexes?.length) {
                        repliedToMessageIndexes.forEach(msgIndex => {
                            ctrl.messages[msgIndex].replyToMessageMedias = medias;
                        })
                    }
                });
            }
        }

        function onReactionUpdate(message) {
            const messageIndex = ctrl.messages.findIndex(msg => msg.item.messageId === message.messageId);

            if (messageIndex !== -1) {
                $scope.$apply(() => {
                    ctrl.messages[messageIndex].item.reactionsSummary = message.reactionSummaryList;
                })
            }
        }

        function onChannelMarkAsRead(data) {
            const read = ctrl.channel.item.messageReadStats.find(item => item.userId === data.userId)
            if (read) {
                read.lastReadMessageId = data.lastReadMessageId;
            } else {
                ctrl.channel.item.messageReadStats.push({
                    lastReadMessageId: data.lastReadMessageId, userId: data.userId
                })
            }
            ctrl.messageReadStats = [];
            $scope.$applyAsync(() => {
                generateMessageReads(ctrl.channel)
            })
        }

        function generateMessageReads(channel) {
            channel.item.messageReadStats.forEach(item => {
                if (!ctrl.messageReadStats[item.lastReadMessageId]) {
                    ctrl.messageReadStats[item.lastReadMessageId] = []
                }
                if (item.userId !== ctrl.currentUserToken && !alreadyHaveUser(item) && !isAuthor(item)) {
                    ctrl.messageReadStats[item.lastReadMessageId].push(item.userId);
                }

                function alreadyHaveUser(item) {
                    return !!ctrl.messageReadStats[item.lastReadMessageId].find(id => id === item.userId)
                }

                function isAuthor(item) {
                    if (ctrl.messages) {
                        const message = ctrl.messages.find(message => message.item.messageId === item.lastReadMessageId)
                        return message && message.item.authorId === item.userId
                    }
                }
            })
        }

        function onUserTyping(ev) {
            $scope.$apply(() => {
                ctrl.typingUser = getMember(ev.userId);
                if ((scrollContainer.offsetHeight + scrollContainer.scrollTop >= scrollContainer.scrollHeight)) {
                    scrollToBottom();
                }
            });
            typingTimeout && clearTimeout(typingTimeout);
            typingTimeout = setTimeout(() => {
                $scope.$applyAsync(() => {
                    delete ctrl.typingUser;
                })
            }, 3000);
        }

        function getMember(userId) {
            let user = ctrl.channel.members.find(user => user.userId === userId)
            return user || ctrl.users.find(user => user.userId === userId);
        }

        function markAsRead() {
            ctrl.channel.item.unreadMessagesCount = 0;
            ChatDataService.markChannelsAsRead(ctrl.channel.item.channelId);
        }

        function typing(e) {
            if (e && ResponsiveService.isDesktop() && e.keyCode === 13 && !e.shiftKey) {
                sendMessage(e);
            } else {
                if (ctrl.channel?.item?.channelId && canPublish && ChatConnectionService.isConnected()) {
                    ConversationService.typing(ctrl.channel.item.channelId)
                    canPublish = false;
                    setTimeout(function () {
                        canPublish = true;
                    }, 500);
                }
            }
        }

        function sendMessage(e) {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }

            if ((!ctrl.message && !ctrl.uploadedMedia.length) || ctrl.sending) {
                return false;
            }

            ctrl.sending = true;

            if (ChatConnectionService.isConnected()) {
                sendMessageInner();
            } else {
                ToastFactory.errorTranslated('CHAT.NO_NETWORK');
                ctrl.sending = false;
                ChatConnectionService.reconnect();
            }
        }

        function sendMessageInner() {
            sendFunction()
                .then(() => {
                    resetMessage();
                    ctrl.sending = false;
                    ctrl.ignoreContentSafetyWarning = false;
                })
                .catch((resp) => {
                    ctrl.sending = false;
                    if (resp?.data?.ShowContentSafetyWarning) {
                        ContentSafetyWarningService
                            .openPopup(resp.data.SeverityLevel, resp.data.Action,
                                'CHAT.UPDATE_MESSAGE', () => {
                                    ctrl.ignoreContentSafetyWarning = true;
                                    sendMessageInner();
                                })
                    }
                })
        }

        function sendFunction() {
            if (ctrl.editingMessage) {
                return ConversationService.updateMessage({
                    channelId: ctrl.channel.item.channelId,
                    messageId: ctrl.edittingMessageId,
                    text: ctrl.message,
                    medias: ctrl.uploadedMedia,
                    mentions: ctrl.mentions,
                    ignoreContentSafetyWarning: ctrl.ignoreContentSafetyWarning
                })
            } else {
                return ConversationService.sendMessage({
                    channelId: ctrl.channel.item.channelId,
                    text: ctrl.message,
                    medias: ctrl.uploadedMedia,
                    replyToMessageId: ctrl.replyingMessage?.item.messageId,
                    mentions: ctrl.mentions,
                    ignoreContentSafetyWarning: ctrl.ignoreContentSafetyWarning
                });
            }
        }

        function resetMessage() {
            delete ctrl.edittingMessageId;
            clearReplyingMessage();
            ctrl.editingMessage = false;
            ctrl.message = '';
            ctrl.uploadedMedia = [];
        }

        function openRepliedContent() {
            Page.stateGoLink(ctrl.channel.replyContent.appLinkModel);
            $timeout(() => {
                close();
            }, 1000);
        }

        function showChatInfo() {
            ChatPopupsService.openChatSettingsPopup(ctrl.channel, close);
        }

        function openAddUsers() {
            ChatPopupsService.openAddUsersPopup(ctrl.channel, close);
        }

        function close(isRemoved) {
            ChatConnectionService.destroyChannelItemsEvent();
            ConversationService.onConversationClose();

            scrollContainer?.removeEventListener('scroll', handleScroll);
            scrollContainer?.removeEventListener('scroll', handleScrollButton);

            $window.removeEventListener('resize', onResize);

            $('.message-form button').off('touchend', forceSubmit);


            if (!isRemoved) {
                markAsRead();
            }
            removePushOpenWatcher();
            $scope.$destroy();
            popup.remove();
            $element.remove();
        }

        function scrollToBottom(callback) {
            $timeout(function () {
                scrollContainer.scrollTop = scrollContainer.scrollHeight + 100;
                callback && callback();
            });
        }

        function scrollToMessage(message, highlightMessage) {
            $timeout(function () {
                scrollContainer.scrollTop = message[0].offsetTop - 100;

                if (highlightMessage) {
                    message.removeClass('highlight');
                    message.addClass('highlight');
                    if (scrollContainer.scrollHeight > scrollContainer.offsetHeight) {
                        $timeout(() => {
                            const removeHighlight = () => {
                                message.removeClass('highlight');
                                scrollContainer.removeEventListener('scroll', removeHighlight);
                            };
                            scrollContainer.addEventListener('scroll', removeHighlight)
                        }, 100);
                    } else {
                        $timeout(() => {
                            message.removeClass('highlight');
                        }, 3000)
                    }
                }
            });
        }

        function onMediaUpdated(media) {
            ctrl.uploadedMedia.push(media);
        }

        function removeMedia(index) {
            ctrl.uploadedMedia.splice(index, 1);
        }

        function openChannelInfo() {
            if (ctrl.channel.item.isGroupChat) {
                showChatInfo();
            } else {
                ProfileViewerService.showProfileCard(ctrl.currentChannelCompanion.userId);
            }
        }

        function onResize() {
            scrollToBottom();
        }

        function onBlur() {
            // fix ios viewport shift issue
            window.scrollTo(0, 0);
        }

        function forceSubmit(e) {
            e.stopPropagation();
            sendMessage(e)
            return false;
        }

        function replyToMessage(message) {
            ctrl.replyingMessage = message;
        }

        function editMessage(message) {
            if (message) {
                ctrl.editingMessage = true;
                ctrl.edittingMessageId = message.item.messageId;
                ctrl.message = message.item.text;
                message.item.taggedUsers = MentionService.mentionsFromString(message.item.text);
                ctrl.mentions = ctrl.channel.members.filter(member => message.item.taggedUsers.includes(member.userId));

                $scope.$broadcast('mention:reInit', ctrl.mentions);

                if (message.medias && message.medias.length) {
                    ctrl.uploadedMedia = _.clone(message.medias);
                }
            }
        }

        function clearReplyingMessage() {
            delete ctrl.replyingMessage;
        }

        function onOpenOptions(message) {
            if ($element.find('.message-wrapper:last').data('index') === message.item.messageId) {
                scrollContainer.removeEventListener('scroll', handleScrollButton);
                $timeout(() => {
                    scrollToBottom(() => {
                        $timeout(() => {
                            scrollContainer.addEventListener('scroll', handleScrollButton);
                        })
                    });
                }, 200)
            }
        }

        function isUserNotBlocked(member) {
            return !ctrl.channel.blockedUsers?.includes(member.userId);
        }

        function onRenderCompleted() {
            PopupWrapperService.fixKeyboardShift($element.find('.text-input mention-textarea textarea')[0], scrollToBottom);
        }


        function destroy() {
            $element.off('click', '.post-tag', showMentionProfile);

            if (ctrl.btnHammer) {
                ctrl.btnHammer.destroy();
            }
        }
    }
})();
