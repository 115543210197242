(() => {
    'use strict';

    angular.module('App')
        .service('MultiplePermissionSelectorService', [MultiplePermissionSelectorService])


    function MultiplePermissionSelectorService() {
        return {
            getRequiredPermissionId: getRequiredPermissionId
        }

        function getRequiredPermissionId() {
            return {
                none: null,
                departments: 1,
                userGroups: 2,
                departmentsAndUserGroups: 3,
                departmentsOrUserGroups: 4
            }
        }
    }
})();