(function () {
    'use strict';

    angular
        .module('App')
        .factory('ProfileOverviewService', ['ActionSheetService', 'BasicHelper', 'Page', ProfileOverviewService]);

    function ProfileOverviewService(ActionSheetService, BasicHelper, Page) {
        return {
            prepareProfile: prepareProfile,
            initContactButtons: initContactButtons
        };

        function prepareProfile(profile) {
            return {
                name: profile.Name,
                bio: profile.Bio,
                title: profile.Title,
                alias: profile.Alias,
                enableInternalId: profile.EnableInternalId,
                isEditingTitleEnabled: profile.IsEditingTitleEnabled,
                email: profile.Email,
                phone: _.get(profile, 'PhoneNumber.Phone'),
                phoneCountryId: _.get(profile, 'PhoneNumber.CountryId'),
                allowSmsNotification: profile.AllowSmsNotification,
                defaultImage: profile.DefaultImage,
                shareEmail: profile.ShareEmail,
                sharePhone: profile.SharePhone,
                cultureId: (profile.CultureId) ? profile.CultureId.toString() : '0',
                secondaryEmail: profile.SecondaryEmail,
                businessPhone: _.get(profile, 'BusinessPhoneNumber.Phone'),
                businessPhoneCountryId: _.get(profile, 'BusinessPhoneNumber.CountryId'),
                shareSecondaryEmail: profile.ShareSecondaryEmail,
                shareBusinessPhone: profile.ShareBusinessPhone,
                ImageId: profile.Image ? profile.Image.MediaId : null
            }
        }

        function getPhoneNumber(profile) {
            if (isPhoneSet(profile)) {
                return '+' + profile.PhoneCode + ' ' + profile.PhoneNumber.Phone;
            } else if (isBusinessPhoneSet(profile)) {
                return '+' + profile.BusinessPhoneCode + ' ' + profile.BusinessPhoneNumber.Phone;
            }

            return null;
        }

        function initContactButtons(profile, isOwnProfile, closeCallback) {

            var actions = initContactButtonsActions(profile);

            return [
                {
                    title: 'PROFILE.PHONE_CALL',
                    icon: 'phone',
                    text: getPhoneNumber(profile),
                    show: (profile.SharePhone || profile.ShareBusinessPhone) && actions.phoneButtons.length > 0,
                    action: ($event) => {
                        if (actions.phoneButtons.length > 1) {
                            ActionSheetService.create(actions.phoneButtons, $event.currentTarget, null, { name: 'profile-overview' }).show();
                        } else {
                            actions.phoneButtons[0].onClick();
                        }
                    }
                },
                {
                    title: 'PROFILE.SEND_SMS',
                    icon: 'sms',
                    text: getPhoneNumber(profile),
                    show: (profile.SharePhone || profile.ShareBusinessPhone) && actions.smsButtons.length > 0,
                    action: ($event) => {
                        if (actions.smsButtons.length > 1) {
                            ActionSheetService.create(actions.smsButtons, $event.currentTarget, null, { name: 'profile-overview' }).show();
                        } else {
                            actions.smsButtons[0].onClick();
                        }
                    }
                },
                {
                    title: 'PROFILE.SEND_EMAIL',
                    icon: 'email',
                    text: profile.Email || profile.SecondaryEmail,
                    show: (isEmailSet(profile) || isSecondaryEmailSet(profile)) && actions.emailButtons.length > 0,
                    action: ($event) => {
                        if (actions.emailButtons.length > 1) {
                            ActionSheetService.create(actions.emailButtons, $event.currentTarget, null, { name: 'profile-overview' }).show();
                        } else {
                            actions.emailButtons[0].onClick();
                        }
                    }
                },
                {
                    title: 'PROFILE.START_CHAT',
                    icon: 'comment',
                    show: isChatAvailable(profile, isOwnProfile),
                    action: () => {
                        startChat(profile, closeCallback);
                    }
                },
                {
                    title: 'PROFILE.START_MS_TEAMS_CHAT',
                    svgIcon: 'teams',
                    show: isTeamsChatAvailable(profile, isOwnProfile),
                    action: () => {
                        window.open("https://teams.microsoft.com/l/chat/0/0?users=" + profile.TeamsEmail);
                    }
                }
            ];
        }

        function initContactButtonsActions(profile) {
            var actions = {
                phoneButtons: [],
                smsButtons: [],
                emailButtons: []
            };

            if (isPhoneSet(profile)) {
                actions.phoneButtons.push({
                    text: '+' + profile.PhoneCode + ' ' + profile.PhoneNumber.Phone,
                    subText: 'PROFILE_VIEWER.MOBILE',
                    icon: 'phone',
                    onClick: function () {
                        window.location.href = 'tel:+' + profile.PhoneCode + '-' + profile.PhoneNumber.Phone;
                    }
                });
                actions.smsButtons.push({
                    text: '+' + profile.PhoneCode + ' ' + profile.PhoneNumber.Phone,
                    subText: 'PROFILE_VIEWER.MOBILE',
                    icon: 'sms',
                    onClick: function () {
                        window.location.href = 'sms:+' + profile.PhoneCode + '-' + profile.PhoneNumber.Phone;
                    }
                });
            }

            if (isBusinessPhoneSet(profile)) {
                actions.phoneButtons.push({
                    text: '+' + profile.BusinessPhoneCode + ' ' + profile.BusinessPhoneNumber.Phone,
                    subText: 'PROFILE_VIEWER.MOBILE_BUSINESS',
                    icon: 'phone',
                    onClick: function () {
                        window.location.href = 'tel:+' + profile.BusinessPhoneCode + '-' + profile.BusinessPhoneNumber.Phone;
                    }
                });
                actions.smsButtons.push({
                    text: '+' + profile.BusinessPhoneCode + ' ' + profile.BusinessPhoneNumber.Phone,
                    subText: 'PROFILE_VIEWER.MOBILE_BUSINESS',
                    icon: 'sms',
                    onClick: function () {
                        window.location.href = 'tel:+' + profile.BusinessPhoneCode + '-' + profile.BusinessPhoneNumber.Phone;
                    }
                });
            }

            if (isEmailSet(profile)) {
                actions.emailButtons.push({
                    text: profile.Email,
                    subText: 'PROFILE_VIEWER.EMAIL',
                    icon: 'email',
                    onClick: function () {
                        BasicHelper.mailTo(profile.Email);
                    }
                });
            }

            if (isSecondaryEmailSet(profile)) {
                actions.emailButtons.push({
                    text: profile.SecondaryEmail,
                    subText: 'PROFILE_VIEWER.EMAIL_SECONDARY',
                    icon: 'email',
                    onClick: function () {
                        BasicHelper.mailTo(profile.SecondaryEmail);
                    }
                });
            }

            return actions;
        }

        function isPhoneSet(profile) {
            return profile.SharePhone && profile.PhoneNumber.Phone && profile.PhoneNumber.Phone.length > 0;
        }

        function isBusinessPhoneSet(profile) {
            return profile.ShareBusinessPhone && profile.BusinessPhoneNumber.Phone && profile.BusinessPhoneNumber.Phone.length > 0;
        }

        function isEmailSet(profile) {
            return profile.ShareEmail && profile.Email && profile.Email.length > 0;
        }

        function isSecondaryEmailSet(profile) {
            return profile.ShareSecondaryEmail && profile.SecondaryEmail && profile.SecondaryEmail.length > 0;
        }

        function isChatAvailable(profile, isOwnProfile) {
            return isOwnProfile === false && profile.ChatModuleToken && profile.ChatModuleToken.length > 0;
        }

        function isTeamsChatAvailable(profile, isOwnProfile) {
            return isOwnProfile === false && profile.TeamsEmail && profile.TeamsEmail.length > 0
        }

        function startChat(profile, closeCallback) {
            Page.stateGo('chat',
                profile.ChatModuleToken,
                null,
                Page.getPageTransition(),
                {
                    Name: profile.Name,
                    UserToken: profile.UserToken
                }
            );
            
            closeCallback();
        }
    }
})();

