(function () {
    'use strict';

    angular
        .module('App')
        .directive('noContent', [() => {
            return {
                restrict: 'E',
                scope: {
                    icon: '@',
                    iconClass: '@',
                    title: '@',
                    text: '@'
                },
                template: require('./Templates/NoContent.tpl.html'),
                link: function (scope, element, attrs) {
                    if (attrs.icon == undefined || attrs.icon == null || attrs.length == 0)
                        scope.icon = "book-open-page-variant";
                    if (attrs.text == undefined || attrs.text == null || attrs.length == 0)
                        scope.text = "NO_CONTENT.DEFAULT";
                    if (attrs.title == undefined || attrs.title == null || attrs.length == 0)
                        scope.title = "NO_CONTENT.DEFAULT_TITLE";
                }
            }
        }]);
})();