(function () {
    'use strict';

    angular.module('App')
        .component('chatSettings', {
            template: require('./ChatSettingsComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$timeout', '$filter', '$element', 'ChatPopupsService', 'ChatConnectionService',
                'ChatDataService', 'ProfileViewerService', 'ConfirmPopupService', 'ToastFactory', 'ActionSheetService',
                'Profile', 'ContentSafetyWarningService', ChatSettingsController],
            bindings: {
                channel: '<',
                closeConversation: '<'
            }
        });

    function ChatSettingsController($rootScope, $timeout, $filter, $element, ChatPopupsService, ChatConnectionService,
                                    ChatDataService, ProfileViewerService, ConfirmPopupService, ToastFactory,
                                    ActionSheetService, Profile, ContentSafetyWarningService) {
        const ctrl = this,
            pushOpenWatcher = $rootScope.$on('CHAT.OPEN_FROM_PUSH', () => close());

        let popup;

        ctrl.currentUser = Profile.getProfile()
        ctrl.isCurrentUserAdmin = isAdmin(ctrl.currentUser.UserToken);
        ctrl.editingTitle = false;
        ctrl.saving = false;
        ctrl.showAllMembers = false;

        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onOpenPopup = onOpenPopup;
        ctrl.isAdmin = isAdmin;
        ctrl.titleFocus = titleFocus;
        ctrl.editIconClick = editIconClick;
        ctrl.updateTitle = updateTitle;
        ctrl.setNotificationSetting = setNotificationSetting;
        ctrl.orderByCreator = orderByCreator;
        ctrl.isLeaveEnabled = isLeaveEnabled;
        ctrl.leaveChannel = leaveChannel;
        ctrl.showActions = showActions;
        ctrl.openAddUsers = openAddUsers;
        ctrl.close = close;
        ctrl.isBlockedUser = isBlockedUser;

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function onOpenPopup() {
            ChatConnectionService.onChannelItemEvents(
                ctrl.channel.item.channelId,
                channelEventsCallback
            )

            ctrl.chatCreator = ctrl.channel.members.find(member => member.userId === ctrl.channel.item.creatorUserId);
            ctrl.channelName = ctrl.channel.item.name;
        }

        function channelEventsCallback(channel) {
            ctrl.channel = channel
        }

        function isAdmin(token) {
            return !!ctrl.channel.item.adminUserIds.find(userId => {
                return userId === token
            });
        }

        function isLeaveEnabled() {
            return ctrl.channel.item.adminUserIds.filter(userId => {
                return userId !== ctrl.currentUser.UserToken
            }).length || ctrl.channel.members.length === 1
        }

        function openAddUsers() {
            ChatPopupsService.openAddUsersPopup(ctrl.channel);
        }

        function leaveChannel() {
            if (!isLeaveEnabled()) {
                ConfirmPopupService.open({
                    message: $filter('translate')('CHAT.LEAVE_LAST_ADMIN_WARNING'),
                    yesText: $filter('translate')('GOT_IT'),
                    isAlert: true
                });

            } else {
                ConfirmPopupService.open({
                    message: $filter('translate')('CHAT.EVENT.LEAVE_CONFIRM'),
                    class: 'chat-leave-confirm'
                }).then(function () {
                    ChatDataService.leaveChannel(ctrl.channel.item.channelId)
                        .then(() => {
                            $rootScope.$broadcast('CHAT.CHANNEL_REMOVED', ctrl.channel.item.channelId)
                            closeThisAndPreviousPopups(true);
                        });
                });
            }
        }

        function titleFocus(e) {
            if (ctrl.editingTitle) return;

            const el = e.currentTarget;
            ctrl.editingTitle = true;

            $timeout(function () {
                el.setSelectionRange(ctrl.channelName, ctrl.channelName);
            });
        }

        function editIconClick() {
            if (ctrl.editingTitle) return;
            angular.element('.chat-title input').focus();
        }

        function updateTitle(title) {
            ctrl.saving = true;
            ChatDataService
                .updateChannelTitle(ctrl.channel.item.channelId, title, ctrl.ignoreContentSafetyWarning)
                .then(() => {
                    ctrl.ignoreContentSafetyWarning = false;
                    ctrl.channel.item.name = ctrl.channelName;
                })
                .catch(resp => {
                    if (resp?.data?.ShowContentSafetyWarning) {
                        ContentSafetyWarningService
                            .openPopup(resp.data.SeverityLevel, resp.data.Action, 'CHAT.UPDATE_TITLE', () => {
                                ctrl.ignoreContentSafetyWarning = true;
                                updateTitle(title);
                            })
                    }
                })
                .finally(() => {
                    ctrl.saving = false;
                    ctrl.editingTitle = false;

                });
        }

        function showActions(user) {
            if (user.userId === ctrl.currentUser.UserToken) {
                return false;
            }

            var buttons = [
                {
                    text: 'CHAT.SHOW_PROFILE',
                    icon: 'user',
                    onClick: function () {
                        ProfileViewerService.showProfileCard(user.userId);
                    }
                },
                {
                    text: 'CHAT.MESSAGE',
                    icon: 'comment',
                    onClick: function () {
                        createChat(user);
                    }
                }
            ];

            if (ctrl.isCurrentUserAdmin && !isAdmin(user.userId)) {
                buttons.push({
                    text: 'CHAT.MAKE_AN_ADMINISTRATOR',
                    icon: 'user-admin',
                    onClick: function () {
                        addAdminRights(user.userId);
                    }
                });
            }

            if (ctrl.isCurrentUserAdmin && isAdmin(user.userId)) {
                buttons.push({
                    text: 'CHAT.REMOVE_ADMINISTRATOR_RIGHTS',
                    icon: 'remove',
                    onClick: function () {
                        removeAdminRights(user.userId);
                    }
                });
            }

            if (ctrl.isCurrentUserAdmin) {
                buttons.push({
                    text: 'CHAT.REMOVE_FROM_GROUP',
                    icon: 'delete',
                    iconClass: 'red label-color',
                    onClick: function () {
                        removeMember(user.userId);
                    }
                });
            }

            var actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, {name: 'chat-settings'});
            actionSheet.show();
        }

        function addAdminRights(userId) {
            ChatDataService.addChannelAdminRights(ctrl.channel.item.channelId, userId);
        }

        function removeAdminRights(userId) {
            ChatDataService.removeChannelAdminRights(ctrl.channel.item.channelId, userId);
        }

        function createChat(user) {
            ChatDataService.createChannel([user])
                .then(function (channelId) {
                    closeThisAndPreviousPopups();
                    $rootScope.$broadcast('CHAT.CHANNEL_CREATED', channelId);
                })
        }

        function removeMember(userId) {
            ConfirmPopupService.open({
                message: $filter('translate')('CHAT.EVENT.REMOVE_MEMBER_CONFIRM'),
                class: 'chat-leave-confirm'
            }).then(() => {
                ChatDataService.removeUsersFromChannel(ctrl.channel.item.channelId, [userId]);
            });
        }

        function orderByCreator(user) {
            return user.userId !== ctrl.channel.item.creatorUserId;
        }

        function setNotificationSetting() {
            ChatDataService
                .setChannelNotificationSettings(ctrl.channel.item.channelId, ctrl.channel.item.notificationsEnabled)
                .catch(err => {
                    ToastFactory.errorTranslated('ERROR.GENERAL')
                    ctrl.channel.item.notificationsEnabled = !ctrl.channel.item.notificationsEnabled;
                });
        }

        function close() {
            ChatConnectionService.removeChannelItemEvents(channelEventsCallback);
            pushOpenWatcher();
            popup.remove();
            $element.remove();
        }

        function closeThisAndPreviousPopups(isRemoved) {
            close();
            ctrl.closeConversation && ctrl.closeConversation(isRemoved);
        }

        function isBlockedUser(user) {
            return ctrl.channel.blockedUsers.find((blockedUserId) => blockedUserId === user.userId);
        }
    }
})();