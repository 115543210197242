(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('Profile', ['$http', '$q', '$translate', 'Menu', '$rootScope', 'events', 'Page',
        'NativeFactory', 'PointsLogService', '$timeLocale',
        function ($http, $q, $translate, Menu, $rootScope, events, Page, NativeFactory, PointsLogService, $timeLocale) {
            var profile = null, popupModal, desktopMenuDropdownVisibility,
                isSavingPush = false,
                savePushTokenQueue = [],
                lastSentPushToken = null;

            // Listen for Push Token events
            $rootScope.$on(events.PUSH_NEWTOKEN, function (ev, data) {
                console.log('PUSH_NEWTOKEN', data);
                if (profile) {
                    f.savePushToken(data);
                } else {
                    // Try again in 10 seconds and see if the user is logged in then
                    setTimeout(function () {
                        if (profile) {
                            f.savePushToken(data);
                        }
                    }, 10000);
                }
            });

            var f = {
                getDesktopMenuDropdownVisibility: function () {
                    return desktopMenuDropdownVisibility;
                },
                toggleDesktopMenuDropdownVisibility: function (visible) {
                    if (_.isBoolean(visible)) {
                        desktopMenuDropdownVisibility = visible
                    } else {
                        desktopMenuDropdownVisibility = !desktopMenuDropdownVisibility;
                    }
                },
                mergeWithCurrentProfile: function (data) {
                    data.UserToken = profile.UserToken;
                    data.AccountToken = profile.AccountToken;
                    f.setProfile(data, true);
                },
                setProfile: function (data, mergeData) {
                    if (mergeData && profile && data) {
                        data = mergeProfileData(data, profile);
                    }

                    profile = data;
                    if (data) {
                        setCulture();
                        saveCachedProfile(data);
                        NativeFactory.user.isLoggedIn((data.AllowPushNotification || data.AllowNotification));
                    } else {
                        // Logout user

                        removeCachedProfile();
                        NativeFactory.user.isLoggedOut();
                    }
                },
                getProfile: () => profile,
                getCachedProfile: getCachedProfile,
                getProfileImageUrl: getProfileImageUrl,
                loadProfile: function () {
                    var d = $q.defer();

                    $http.get('/Profile/Load')
                        .then(function (response) {
                            f.setProfile(response.data);
                            d.resolve(response);
                        }, function () {
                            d.resolve(false);
                        });

                    return d.promise;
                },
                saveProfile: function (renderProfile) {
                    var d = $q.defer();

                    $http.post(
                        '/Profile/Save', renderProfile)
                        .then(function (response) {
                            if (response.data.saved) {
                                // Add date to local profile
                                f.setProfile(response.data.profile);
                            }
                            d.resolve(response.data);
                        }, function () {
                            d.resolve(false);
                        });

                    return d.promise;
                },
                reloadPoints: function () {
                    if (profile) {
                        $http.get('Profile/GetPoints').then(function (response) {
                            if (response && response.data && response.data.Points) {
                                // Set data
                                profile.Points = response.data.Points;
                            }
                        }, function () {
                            // Failed to load data
                        });
                    }
                },
                showPointLog: () => PointsLogService.openPopup(),
                setSelectedMultipleDepartment: function (id, triggerEvents) {
                    var d = $q.defer();

                    if (!triggerEvents)
                        triggerEvents = false;

                    $http.post('/Profile/SetMultipleDepartments', { id: id }).then(function (response) {
                        // Set profile data
                        f.setProfile(response.data.profile);

                        Page.getSettings().UserDepartmentHierarchyPath = response.data.userDepartmentHierarchyPath;
                        Page.renderBodyClasses();

                        // Register events and trigger reloads
                        if (triggerEvents) {
                            Menu.loadMenus(false, true).then(function () {
                                $rootScope.$broadcast(events.DEPARTMENT_CHANGED, true);
                                d.resolve();
                            });
                        } else {
                            // Resolve straight away
                            d.resolve();
                        }
                    }, function (response) {
                        d.reject(response);
                    });

                    return d.promise;
                },
                clearCache: () => f.setProfile(null),
                isProfilePage: () => Page.getCurrentPage().name === 'profile',
                isAllDataLoaded: function (profileObject) {
                    if (_.isObject(profileObject) === false) {
                        profileObject = profile;
                    }

                    if (profileObject === null) {
                        return false;
                    }

                    return profileObject.hasOwnProperty('ShowBadgesOnProfile');
                },

                savePushToken: function (data) {
                    if (isValidPushToken(data)) {
                        savePushTokenQueue.push(data);

                        if (isSavingPush === false) {
                            handleSavePushQueue();
                        }
                    }
                },

                getNotificationSettings: function () {
                    return $http.get('/Profile/GetNotificationSettings').then(function (response) {
                        return response.data;
                    }, function (e) {
                        return e;
                    });
                },
                saveNotificationSettings: function (notificationSettings) {
                    var d = $q.defer();

                    $http.post('/Profile/SaveNotificationSettings', notificationSettings).then(function (response) {
                        d.resolve(response);
                    }, function (e) {
                        d.reject(e);
                    });

                    return d.promise;
                },
                SaveNotificationSettingsForModule: function (accountModuleId, state) {
                    var d = $q.defer();

                    $http.post('/Profile/SaveNotificationSettingsForAccountModule', {
                        accountModuleId: accountModuleId,
                        isEnabled: state
                    }).then(function (response) {
                        d.resolve(response);
                    }, function (e) {
                        d.reject(e);
                    });

                    return d.promise;
                },
                closePopupModal: closePopupModal,
                setPopupModal: setPopupModal
            };

            function setCulture() {
                // Set language for user
                if (profile.UICulture !== undefined && profile.UICulture !== null) {
                    // Set label language
                    $('html').attr('lang', profile.UICulture.Code);
                    $translate.use(profile.UICulture.Code);
                    // Set datetime language
                    $('html').attr('data-i18n', profile.UICulture.i18n);
                    $('html').data('i18n', profile.UICulture.i18n);
                    $timeLocale.setLocale(profile.UICulture.i18n);
                }
            }

            function isValidPushToken(data) {
                if (_.isObject(data) == false) {
                    return false;
                }

                var hasPushToken = hasPropertyValue(data, 'newPushToken')
                    || hasPropertyValue(data, 'NewPushToken')
                    || hasPropertyValue(data, 'newpushtoken');

                if (hasPushToken) {
                    return true;
                }

                var hasProviderIdentifier = hasPropertyValue(data, 'providerIdentifier')
                    || hasPropertyValue(data, 'ProviderIdentifier')
                    || hasPropertyValue(data, 'provideridentifier');

                if (hasProviderIdentifier == false) {
                    return false;
                }

                hasPushToken = hasPropertyValue(data, 'pushToken')
                    || hasPropertyValue(data, 'PushToken')
                    || hasPropertyValue(data, 'pushtoken');

                return hasPushToken;
            }

            function hasPropertyValue(data, propertyName) {
                if (propertyName in data) {
                    return _.isString(data[propertyName]) && data[propertyName] != null && data[propertyName].trim().length > 0;
                }
                return false;
            }

            function handleSavePushQueue() {
                if (savePushTokenQueue.length == 0) {
                    isSavingPush = false;
                    return;
                }

                isSavingPush = true;
                var pushData = savePushTokenQueue[0];

                if (lastSentPushToken != null && _.isEqual(pushData, lastSentPushToken)) {
                    savePushTokenQueue.shift();
                    handleSavePushQueue();
                    return;
                }

                // save
                $http.post('/Profile/SavePushToken', pushData).then(function () {
                    savePushTokenQueue.shift();
                    lastSentPushToken = pushData;
                    handleSavePushQueue();
                }, function () {
                    lastSentPushToken = null; // Important to set as null, as we failed last, so we should try any new token we get
                    savePushTokenQueue.shift();
                    handleSavePushQueue();
                });
            }

            function closePopupModal() {
                if (popupModal) {
                    popupModal.close();
                }
            }

            function setPopupModal(modal) {
                popupModal = modal;
            }

            function saveCachedProfile(profileData) {
                const prevCacheItem = getCachedProfile();
                var newCacheItem = createCacheObject(profileData);
                localStorage.setItem('profile', JSON.stringify(newCacheItem));

                try {
                    createCachedProfileImage(profileData, prevCacheItem).then(cachedProfileImage => {
                        if (cachedProfileImage) {
                            newCacheItem.ProfileImageBase64 = cachedProfileImage;
                            localStorage.setItem('profile', JSON.stringify(newCacheItem));
                        }
                    });
                }
                catch (e) {
                    console.error(e);
                }
            }

            function getCachedProfile() {
                var cache = localStorage.getItem('profile');
                if (cache) {
                    return JSON.parse(cache);
                }
                return null;
            }

            function removeCachedProfile() {
                localStorage.removeItem('profile');
            }

            function createCacheObject(profileData) {
                return {
                    UserToken: profileData.UserToken,
                    Name: profileData.Name,
                    Image: profileData.Image,
                    DefaultImage: profileData.DefaultImage
                };
            }

            function createCachedProfileImage(profileData, prevCacheItem) {
                var d = $q.defer();

                if (prevCacheItem 
                    && prevCacheItem.ProfileImageBase64 
                    && getProfileImageUrl(profileData) == getProfileImageUrl(prevCacheItem)) {
                    
                    d.resolve(prevCacheItem.ProfileImageBase64);
                    return d.promise;
                }

                var url = getProfileImageUrl(profileData);
                if (url) {
                    $http.get(url, { responseType: 'blob', withCredentials: true }).then((response) => {
                        var fr = new FileReader();
                        fr.onload = () => {
                            d.resolve(fr.result);
                        };

                        fr.onerror = () => {
                            d.reject();
                        }
                        
                        fr.readAsDataURL(response.data);
                    }, () => {
                        d.reject();
                    })
                } else {
                    d.reject();
                }

                return d.promise;
            }

            function getProfileImageUrl(profileData) {
                if (profileData) {
                    if (profileData.Image && profileData.Image.ImageFormats && profileData.Image.ImageFormats.W250) {
                        return profileData.Image.ImageFormats.W250;
                    }

                    if (profileData.DefaultImage && profileData.DefaultImage.ImageFormats && profileData.DefaultImage.ImageFormats.W250) {
                        return profileData.DefaultImage.ImageFormats.W250;
                    }
                }

                return null;
            }

            function mergeProfileData(source, target) {
                if (source.AccountToken !== target.AccountToken || source.UserToken !== target.UserToken) {
                    return source;
                }

                return _.merge(target, source);
            }

            return f;
        }]);
})();