(function () {
    'use strict';

    angular.module('App')
        .component('fillingPreview', {
            template: require('./FillingPreviewComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$filter', '$scope', '$rootScope', 'ServiceFormRestService', 'ServiceFormRenderService',
                'PopupWrapperService', 'ToastFactory', 'PostReadRegistrationService', 'MODULE_ID', 'ConfirmPopupService',
                'FillingPreviewService', 'FILLING_VIEW', FillingPreviewContentController],
            bindings: {
                data: '<',
                close: '&',
                onDeleteFilling: '&',
                isCollaborationEnabled: '<',
                isStagesEnabled: '<',
                activeView: '<',
                openCommentsPopup: '=?',
                openFormFeedbackComments: '=?',
                showOriginal: '=?',
                showTranslationButton: '=?',
                moduleToken: '@'
            }
        });

    function FillingPreviewContentController($timeout, $filter, $scope, $rootScope, ServiceFormRestService, ServiceFormRenderService,
        PopupWrapperService, ToastFactory, PostReadRegistrationService, MODULE_ID,
        ConfirmPopupService, FillingPreviewService, FILLING_VIEW) {
        var ctrl = this, readTimeout;

        ctrl.FILLING_VIEW = FILLING_VIEW;
        ctrl.settings = ServiceFormRenderService.fillingInteractionSettings;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.$onChanges = onChange;
        ctrl.postReaction = postReaction;
        ctrl.openViewsPopup = openViewsPopup;
        ctrl.openCommentsPopup = openCommentsPopup;
        ctrl.openReactionsViewer = openReactionsViewer;
        ctrl.openFormFeedbackComments = openFormFeedbackComments;
        ctrl.rateReport = rateReport;

        ctrl.deleteFilling = deleteFilling;
        ctrl.editFilling = editFilling;
        ctrl.onStageClick = onStageClick;

        ctrl.toggleTranslation = toggleTranslation;

        const deleteFillingEvent = $scope.$on('deleteFilling', deleteFilling);
        const editFillingEvent = $scope.$on('editFilling', editFilling);
        const continueFillingEvent = $scope.$on('continueFilling', editFilling);
        const fillingUpdatedEvent = $scope.$on('fillingUpdated', fillingUpdated);
        const formFeedbackUpdatedEvent = $scope.$on('formFeedbackUpdated', formFeedbackUpdated);
        const goToLimitedReportsEvent = $rootScope.$on('goToLimitedReports', ctrl.close);

        function init() {
            ctrl.hasMedia = !!_.find(ctrl.data.Filling.Fields, function (field) {
                return field.MediaValues && field.MediaValues.length;
            });

            readTimeout = $timeout(() => {
                ServiceFormRestService.registerRead(ctrl.data.Filling.ServiceFormFillingId)
            }, 2000);

            const {
                showTranslationButton,
                showOriginalLabel,
                showOriginal
            } = FillingPreviewService.initTranslationSettings(ctrl.data);

            ctrl.showTranslationButton = showTranslationButton;
            ctrl.showOriginalLabel = showOriginalLabel;
            ctrl.showOriginal = showOriginal;
        }

        function toggleTranslation() {
            ctrl.loadTranslation = !ctrl.isTranslated;
            FillingPreviewService.toggleTranslation(ctrl.isTranslated, ctrl.data, ctrl.isStagesEnabled)
                .then(data => {
                    if (data.wasTranslated) {
                        ctrl.isTranslated = true;
                        ctrl.showOriginalLabel = true;
                        ctrl.showOriginal = false;
                    } else {
                        ctrl.showOriginalLabel = !ctrl.showOriginalLabel;
                        ctrl.showOriginal = !ctrl.showOriginal;
                    }
                    $scope.$broadcast('comments:toggleTranslate', { showTranslated: !ctrl.showOriginal })
                })
                .finally(() => {
                    ctrl.loadTranslation = false;
                })
        }

        function destroy() {
            if (ctrl.data.Filling.TrackViews) {
                $timeout.cancel(readTimeout);
            }
            
            deleteFillingEvent();
            editFillingEvent();
            continueFillingEvent();
            fillingUpdatedEvent();
            formFeedbackUpdatedEvent();
            goToLimitedReportsEvent();
        }

        function onChange() {
            $timeout(() => {
                angular.element('filling-preview').scrollTop(0);
            });
        }

        function postReaction() {
            ServiceFormRestService.postReaction(ctrl.data.Filling.ServiceFormFillingToken).then(() => {
                ctrl.data.Filling.ReactionCount++;
                ctrl.data.Filling.IsReactedByRequestingUser = true;
            }, () => {
                ToastFactory.errorTranslated('ERROR.GENERAL');
            });
        }

        function deleteFilling() {
            ConfirmPopupService
                .open({ message: $filter('translate')('SERVICEFORM.FILLING.DELETE_CONFIRMATION') })
                .then(() => {
                    ServiceFormRestService
                        .deleteFilling(ctrl.data.Filling.ServiceFormFillingToken)
                        .then(function (success) {
                            ToastFactory.success("Deleted");
                            if (ctrl.onDeleteFilling) {
                                ctrl.onDeleteFilling()
                            }
                            if (ctrl.close) {
                                ctrl.close();
                            }
                        });
                });
        }

        function editFilling() {
            if (ctrl.data.PageNavigation?.length) {
                if (ctrl.close) {
                    ctrl.close();
                }
                PopupWrapperService
                    .getPopup(ServiceFormRenderService.updateFillingPopupId)
                    .open({
                        Filling: ctrl.data.Filling,
                        RuleActions: ctrl.data.RuleActions,
                        PageNavigation: ctrl.data.PageNavigation[0].Pages,
                        FieldsWithRuleActionsToLimitDataListUsage: ctrl.data.FieldsWithRuleActionsToLimitDataListUsage
                        
                    });  
            } else {
                ToastFactory.errorTranslated('SERVICEFORM.FORM_EMPTY');
            }
        }

        function fillingUpdated(ev, data) {
            if (data.Filling.ServiceFormFillingToken === ctrl.data.Filling.ServiceFormFillingToken) {
                if (ctrl.isStagesEnabled) {
                    ctrl.data.Stages = ServiceFormRestService.putStagesIntoGroups(data.Stages)
                    ctrl.data.Filling = data.Filling
                }
            }
        }

        function formFeedbackUpdated(ev, data) {
            ctrl.data.FeedbackData.FillingFeedback = data;
        }

        function openViewsPopup() {
            PostReadRegistrationService.showPopup(
                ctrl.data.Filling.ServiceFormFillingToken,
                ctrl.data.Filling.TotalViews + ' ' + $filter('translate')('SOCIAL.VIEWS'),
                MODULE_ID.SERVICE_FORM
            );
        }

        function openCommentsPopup(commentId) {
            FillingPreviewService.openCommentsPopup(ctrl.data.Filling, ctrl.data.Filling.ServiceFormFillingToken, commentId, ctrl.moduleToken)
        }

        function openReactionsViewer() {
            FillingPreviewService.openReactionsViewer(ctrl.settings, ctrl.data.Filling.ServiceFormFillingToken)
        }

        function openFormFeedbackComments(commentId) {
            FillingPreviewService.openFormFeedbackComments(ctrl.data.FeedbackData.FillingFeedback, null, commentId);
        }

        function rateReport() {
            const fields = ctrl.data.Stages?.length > 0 ? ctrl.data.Stages.map(stage => stage.Fields).flat() : ctrl.data.Filling.Fields;
            const averageScore = ServiceFormRenderService.getAverageFeedbackRating(fields);

            FillingPreviewService.rateReport(ctrl.data.Filling.ServiceFormFillingId, resp => {
                ctrl.data.FeedbackData.FillingFeedback = resp.data
                ctrl.data.Filling.Rating = resp.data.Rating;
                ctrl.data.Filling.ServiceFormFeedbackRangeId = resp.data.ServiceFormFeedbackRangeId;
            }, averageScore);
        }

        function onStageClick(stage) {
            FillingPreviewService.onStageClick(stage, ctrl.data, stages => {
                ctrl.data.Stages = stages;
            })
        }
    }
})();