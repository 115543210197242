(function () {
    'use strict';

    angular.module('App')
        .component('contents', {
            template: require('./ContentComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$http', '$state', '$timeout', '$element', '$interval', 'Profile', 'TimeLocale',
                'NotificationFactory', 'Menu', 'ContentWrapperService', 'ReactionsService', 'ToastFactory', 'MentionService',
                'ContentCreateService', 'CookieService', ContentController],
            bindings: {
                settings: '<',
                contents: '=?',
                categories: '<',
                navigationBadge: '<',
                allowCreation: '<',
                categoryToken: '<',
                showReadCount: '<',
                accountModuleToken: '<',
                openCreatePopup: '=',
                scrollToToken: '<',
                onContentRead: '<',
                allowPostPinning: '<',
                includeFuturePosts: '<',
                isRemindersEnabled: '<',
                isLiveTile: '<',
                openComments: '=?',
                nextOffset: '<',
                sortType: '=?',
                contentLoading: '<',
                startDate: '<',
                endDate: '<',
                archivePostsOnly: '<',
                sortingOptions: '<',
                sortingLabel: '@',
                sortingTitle: '@',
            }
        });

    function ContentController($scope, $http, $state, $timeout, $element, $interval, Profile, TimeLocale,
        NotificationFactory, Menu, ContentWrapperService, ReactionsService, ToastFactory,
        MentionService, ContentCreateService, CookieService) {
        const ctrl = this;
        let destroyPostUpdateWatcher, contentItemsWatcher;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.loadPosts = loadPosts;
        ctrl.paging = paging;
        ctrl.swipeContent = swipeContent;
        ctrl.readContent = readContent;
        ctrl.swipeCategory = swipeCategory;
        ctrl.createContent = createContent;
        ctrl.onRemoved = onRemoved;
        ctrl.onEdit = onEdit;
        ctrl.openReactionsViewer = openReactionsViewer;
        ctrl.updateBadges = updateBadges;
        ctrl.getCurrentLocale = ContentWrapperService.selectCurrentLocale;
        
        function init() {
            ctrl.loadingItems = false;
            ctrl.time = TimeLocale;
            ctrl.settings.accountModuleToken = ctrl.accountModuleToken;

            if (ctrl.openCreatePopup) {
                ctrl.createContent();
            }

            ContentWrapperService.resetContentIdsToExclude();

            const removeLoadingWatcher = $scope.$watch('ctrl.contentLoading', (contentLoading) => {
                if (contentLoading === false) {
                    ctrl.scrollToToken && scrollToContentItem(ctrl.scrollToToken);
                    removeLoadingWatcher();
                }
            })
            
            contentItemsWatcher = $scope.$watch('ctrl.contents', (contents) => {
                if (contents?.length && ctrl.settings.SwipeCategory && !ctrl.nextOffset) {
                    countReadPosts();
                }
            })

            destroyPostUpdateWatcher = $scope.$watch('[ctrl.sortType, ctrl.includeFuturePosts, ctrl.startDate, ctrl.endDate, ctrl.archivePostsOnly]', (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    CookieService.set('sortTypeId-' + $state.params.token, ctrl.sortType);
                    ContentWrapperService.resetContentIdsToExclude();
                    loadPosts();
                }
            });


            ctrl.category = ctrl.categories?.find(category => category.ContentCategoryToken === ctrl.categoryToken)
        }

        function createContent() {
            $http.get('/ContentModule/GetContentCreatePage/' + $state.params.token + '?category=' + ($state.params.extra || ctrl.categoryToken))
                .then(contentCreatePage => {
                    contentCreatePage.data.navigationBadge = ctrl.navigationBadge;
                    contentCreatePage.data.mode = 'create';
                    ContentCreateService.openCreatePopup(loadPosts, contentCreatePage.data, ctrl.accountModuleToken)
                });
        }

        function paging() {
            loadPosts(10, true)
        }

        function loadPosts(limit, isPaging) {
            ctrl.loadingItems = true;
            !isPaging && ContentWrapperService.resetContentIdsToExclude();
            
            return ContentWrapperService.getContents({
                token: ctrl.accountModuleToken,
                categoryToken: ctrl.categoryToken,
                offset: isPaging ? ctrl.nextOffset : 0,
                limit: limit,
                sortType: ctrl.sortType,
                contentIdsToExclude: ContentWrapperService.getContentIdsToExclude(),
                startTime: ctrl.startDate,
                endTime: ctrl.endDate,
                includeFuturePosts: ctrl.includeFuturePosts,
                archivePostsOnly: ctrl.archivePostsOnly
            }).then(data => {
                if (data.Items) {
                    data.Items = data.Items.map(item => setCurrentCulture(item));
                }
                
                if (isPaging) {
                    ctrl.contents = [
                        ...ctrl.contents,
                        ...data.Items.map(item => parseMentions(item))
                    ];
                } else {
                    ctrl.contents = data.Items.map(item => parseMentions(item));
                }
                ctrl.nextOffset = data.NextOffset
                ctrl.loadingItems = false;
                if (!ctrl.nextOffset) {
                    countReadPosts();
                }
            }).catch(() => {
                ctrl.loadingItems = false;
                ctrl.nextOffset = null;
                ToastFactory.error('Could not load more as an error occured');
            });
        }
        
        function setCurrentCulture(item) {
            item.TranslatableEntity._currentCulture = ContentWrapperService.selectCurrentLocale(item.TranslatableEntity);

            if (item.ContentCategoryTranslatableEntity) {
                item.ContentCategoryTranslatableEntity._currentCulture = ContentWrapperService.selectCurrentLocale(item.ContentCategoryTranslatableEntity);
            }
        
            return item
        }
        
        function parseMentions(item) {
            if (item.BodyText) {
                item.BodyText = parse(item.BodyText);
            }

            if (item.TranslatableEntity?.LocalizedLocale?.BodyText) {
                item.TranslatableEntity.LocalizedLocale.BodyText = parse(item.TranslatableEntity.LocalizedLocale.BodyText);
                
                if (item.TranslatableEntity.LocalizedAlternativeLocale?.BodyText) {
                    item.TranslatableEntity.LocalizedAlternativeLocale.BodyText = parse(item.TranslatableEntity.LocalizedAlternativeLocale.BodyText);
                }
            }

            function parse(text) {
                return MentionService.parseMentionsInText(
                    text,
                    item.TaggedUsers,
                    item.TaggedDepartments,
                    item.TaggedUserGroups,
                    false
                )
            }

            return item
        }

        function swipeCategory() {
            const contentToSwipe = [];
            ctrl.contents.forEach(content => {
                if (!content.IsSwiped) {
                    contentToSwipe.push(content.ContentId);
                    setSwiped(content.ContentId);
                }
            });

            return $http.post('/ContentModule/SwipeContentCategory', {
                contentIds: contentToSwipe
            }).then(resp => {
                Menu.removeActivity(resp.data.Subtract, "content", ctrl.accountModuleToken, ctrl.categoryToken);
                Menu.removeLandingBadge(resp.data.Subtract, ctrl.navigationBadge.AccountModuleId, ctrl.navigationBadge.ModuleId, ctrl.navigationBadge.ModuleItemId);
            });
        }

        function swipeContent(content) {
            return $http.post('/ContentModule/SwipeContent', {
                contentId: content.ContentId
            }).then(resp => {
                ContentWrapperService.addContentToExclude(content.ContentId);
                setSwiped(content.ContentId);
                updateBadges(resp.data, content)
            })
        }

        function readContent(content) {
            $http.post('/ContentModule/MarkAsSeen', {
                contentId: content.ContentId
            }).then(resp => {
                ContentWrapperService.addContentToExclude(content.ContentId);
                updateBadges(resp.data, content)
            })
        }

        function updateBadges(data, content) {
            const contentCategoryToken = ctrl.settings.IsCategoryViewEnabled ? null : content.ContentCategoryToken;
            // Remove activity
            Menu.removeActivity(data.Subtract, "content", ctrl.accountModuleToken, contentCategoryToken);
            Menu.removeLandingBadge(data.Subtract, ctrl.navigationBadge.AccountModuleId, ctrl.navigationBadge.ModuleId, ctrl.navigationBadge.ModuleItemId);
            // Update notification center
            NotificationFactory.markNotifications(data.NotificationStatus);
        }

        function scrollToContentItem(contentToken) {
            const interval = $interval(() => {
                const element = angular.element($element).find('[data-token=' + contentToken + ']');
                if (element.length) {
                    angular.element('html').scrollTop(element.offset().top - 60);
                    $interval.cancel(interval);
                }
            }, 100);
        }

        function openReactionsViewer(token) {
            ReactionsService.openReactionsViewer(token, ctrl.settings?.AllowExtendedReactions, 'ContentModule');
        }

        function setSwiped(contentId) {
            ctrl.contents.find(item => item.ContentId === contentId).isSwiped = true
        }

        function onRemoved(post) {
            ctrl.contents = ctrl.contents.filter(item => item.ContentToken !== post.ContentToken)
        }

        function onEdit(data) {
            data.mode = 'edit';
            ContentCreateService.openCreatePopup(loadPosts, data, ctrl.accountModuleToken);
        }

        function countReadPosts() {
            ctrl.isCategoryRead = ctrl.contents.every(content => content.IsSwiped);
        }

        function destroy() {
            destroyPostUpdateWatcher && destroyPostUpdateWatcher();
            contentItemsWatcher && contentItemsWatcher();
        }
    }
})();
