(() => {
    'use strict';

    angular.module('App')
        .component('profileOverview', {
            template: require('./ProfileOverviewComponent.tpl.html'),
            controller: ['$scope', '$filter', 'MODULE_STATE', 'MODULE_ID', 'Page', 'Profile', 'BookmarkService',
                'WalkthroughFactory', 'HeaderButtonsFactory', 'PointsLogService', 'IconService', 'ResponsiveService',
                'ProfileOverviewService', 'ConfirmPopupService', 'AttachmentsViewerService', '$rootScope',
                'ProfileSettingsService', 'DepartmentSwapService', 'events', ProfileOverviewController],
            controllerAs: 'ctrl',
            bindings: {
                token: '<',
                isOverview: '<',
                closePopup: '<',
                profileData: '<'
            }
        });

    function ProfileOverviewController($scope, $filter, MODULE_STATE, MODULE_ID, Page, Profile, BookmarkService,
        WalkthroughFactory, HeaderButtonsFactory, PointsLogService, IconService, ResponsiveService,
        ProfileOverviewService, ConfirmPopupService, AttachmentsViewerService, $rootScope,
        ProfileSettingsService, DepartmentSwapService, events) {

        var ctrl = this,
            screenResizeWatcher,
            departmentSwapWatcher;

        ctrl.ResponsiveService = ResponsiveService;
        ctrl.DepartmentSwapService = DepartmentSwapService;

        ctrl.$onInit = onInit;
        ctrl.$onDestroy = onDestroy;

        ctrl.openProfileImage = openProfileImage;
        ctrl.openPointLog = PointsLogService.openPopup;
        ctrl.openHighScore = openHighScore;

        ctrl.logout = logout;

        if (!ctrl.token) {
            ctrl.profile = Profile.getProfile();
        }

        function onInit() {
            ctrl.isDesktop = ResponsiveService.isDesktop();
            screenResizeWatcher = $scope.$watch(() => ResponsiveService.isDesktop(), (isDesktop) => {
                if (isDesktop !== ctrl.isDesktop) {
                    ctrl.isDesktop = isDesktop;
                    initScreenSizeData();
                }
            });

            ctrl.BookmarkService = BookmarkService;
            ctrl.isAllDataLoaded = false;

            if (!ctrl.token) {
                initData(ctrl.profile);

                ctrl.isLoadingProfileData = true;
                ProfileSettingsService.getFullProfile().then(res => {
                    initData(res);
                    ctrl.isLoadingProfileData = false;
                }).catch(() => {
                    ctrl.isLoadingProfileData = false;
                });
            } else {
                initData(ctrl.profileData.data);
            }
        }

        function initData(data) {
            ctrl.profile = data;

            initScreenSizeData();

            const currenUserToken = Profile.getProfile().UserToken;
            const isOwnProfile = ctrl.profile.UserToken === currenUserToken;

            ctrl.contactLinks = [];
            if (!ctrl.isOverview) {
                ctrl.contactLinks = _.filter(ProfileOverviewService.initContactButtons(ctrl.profile, isOwnProfile, contactLinkCloseCallback), { show: true });
            }

            ctrl.showContact = ctrl.contactLinks.length > 0;

            ctrl.highscoreLevel = null;
            if (ctrl.profile.HighscoreLevelId && ctrl.profile.HighscoreLevelName && ctrl.profile.HighscoreLevelName.length > 0) {
                ctrl.highscoreLevel = {
                    show: true,
                    style: {
                        backgroundColor: ctrl.profile.HighscoreLevelColor
                    }
                };
            }

            ctrl.showUserCustomFields = false;

            if (ctrl.profile.UserCustomFields && ctrl.profile.UserCustomFields.length > 0) {
                ctrl.showUserCustomFields = _.some(ctrl.profile.UserCustomFields, 'Value');
            }

            if (isOwnProfile && ctrl.isOverview) {
                initModuleLinks();

                ctrl.showDepartmentSwap = ctrl.profile.HasMultiDepartment === true;
                if (ctrl.showDepartmentSwap) {
                    ctrl.filteredDepartmentName = ctrl.profile.FilterDepartmentName;
                    ctrl.isDepartmentSwapped = ctrl.filteredDepartmentName && ctrl.filteredDepartmentName.length;

                    departmentSwapWatcher = $rootScope.$on(events.DEPARTMENT_CHANGED, () => {
                        ctrl.filteredDepartmentName = Profile.getProfile().FilterDepartmentName;
                        ctrl.isDepartmentSwapped = ctrl.filteredDepartmentName && ctrl.filteredDepartmentName.length;
                    });
                }
            } else{
                ctrl.showDepartmentSwap = false;
            }

            ctrl.isAllDataLoaded = Profile.isAllDataLoaded(ctrl.profile);
        }

        function initModuleLinks() {
            ctrl.moduleLinks = [];

            if (ctrl.BookmarkService.isEnabled()) {
                ctrl.moduleLinks.push({
                    name: 'BOOKMARK.TITLE',
                    icon: 'bookmark',
                    showOnDesktop: false,
                    iconPrefix: BookmarkService.getBookmarks().length > 0 ? 'fas' : '',
                    onClick: BookmarkService.openPopup
                });
            }

            if (ctrl.profile.IsRemindersEnabled) {
                ctrl.moduleLinks.push({
                    name: 'REMINDERS.REMINDERS',
                    icon: 'alarm-clock',
                    showOnDesktop: true,
                    onClick: () => {
                        Page.stateGo('reminders', null, null, Page.getPageTransition());
                    }
                });
            }

            if (ctrl.profile.ModuleLinks && ctrl.profile.ModuleLinks.length > 0) {
                _.forEach(ctrl.profile.ModuleLinks, link => {
                    ctrl.moduleLinks.push({
                        name: link.Name,
                        icon: IconService.getIconByModuleId(link.ModuleId),
                        showOnDesktop: true,
                        onClick: () => openModuleLink(link)
                    });
                });
            }
        }

        function initScreenSizeData() {
            if (ctrl.isOverview) {
                if (ctrl.isDesktop) {
                    HeaderButtonsFactory.resetButtonsList();
                } else {
                    HeaderButtonsFactory.createButtonsList(() => [
                        {
                            icon: 'settings',
                            onClick: openSettings
                        }
                    ]);
                }
            }

            if (ctrl.profile.ShowBadgesOnProfile) {
                const showCount = ctrl.isDesktop ? 5 : 4;
                const initialBadges = _.take(ctrl.profile.Badges, showCount);

                ctrl.trainingBadges = {
                    show: initialBadges.length > 0 || ctrl.isOverview,
                    showCount: showCount,
                    canShowAll: initialBadges.length !== ctrl.profile.Badges.length,
                    showAll: false,
                    badges: initialBadges,
                    toggleShowAll: () => {
                        if (ctrl.trainingBadges.canShowAll) {
                            ctrl.trainingBadges.showAll = !ctrl.trainingBadges.showAll

                            if (ctrl.trainingBadges.showAll) {
                                ctrl.trainingBadges.badges = ctrl.profile.Badges;
                            } else {
                                ctrl.trainingBadges.badges = _.take(ctrl.profile.Badges, ctrl.trainingBadges.showCount);
                            }
                        }
                    }
                };
            } else {
                ctrl.trainingBadges = {
                    show: false
                };
            }
        }

        function onDestroy() {
            HeaderButtonsFactory.resetButtonsList();
            screenResizeWatcher();
            departmentSwapWatcher();
        }

        function openProfileImage(media) {
            AttachmentsViewerService.openViewerWithMedia([media]);
        }

        function openHighScore() {
            if (ctrl.isOverview && ctrl.profile.DefaultHighscoreModuleToken) {
                Page.stateGo(MODULE_STATE[MODULE_ID.HIGHSCORE], ctrl.profile.DefaultHighscoreModuleToken, null, Page.getPageTransition());
            }
        }

        function openModuleLink(link) {
            switch (link.ModuleId) {
                case MODULE_ID.WALKTHROUGH:
                    WalkthroughFactory.loadWalkthrough();
                    break;
                default:
                    Page.stateGo(MODULE_STATE[link.ModuleId], link.AccountModuleToken, null, Page.getPageTransition());
            }
        }

        function logout() {
            if (ctrl.isOverview) {
                var opts = {
                    message: $filter('translate')('PROFILE.LOGOUT_LABEL')
                };

                ConfirmPopupService.open(opts).then(function () {
                    Page.stateGo('logout');
                });
            }
        }

        function contactLinkCloseCallback() {
            ctrl.closePopup && ctrl.closePopup();
            $rootScope.$broadcast('ClosePopups');
        }

        function openSettings() {
            Page.stateGo('profileSettings');
        }
    }
})();