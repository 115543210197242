(function () {
    'use strict';
    angular
        .module('App')
        .controller('BaseController', ['$window', '$location', '$rootScope', '$scope', '$state', 'Page', 'Profile',
            'Menu', '$timeout', 'WalkthroughFactory', 'AddToHome', 'ProfileNavigationFactory', 'NotificationFactory',
            'ResponsiveService', 'SurveyQuestionFactory', 'BookmarkService', '$element', 'BasicHelper', 'events', 'TemplateFactory',
            'ChatPopupsService', 'ChatConnectionService', '$filter', 'ConfirmPopupService',
            'NativeFactory', '$sce', 'DepartmentSwapService',
            function ($window, $location, $rootScope, $scope, $state, Page, Profile, Menu, $timeout,
                      WalkthroughFactory, AddToHome, ProfileNavigationFactory, NotificationFactory, ResponsiveService,
                      SurveyQuestionFactory, BookmarkService, $element, BasicHelper, events, TemplateFactory, ChatPopupsService,
                      ChatConnectionService, $filter, ConfirmPopupService, NativeFactory, $sce, DepartmentSwapService) {

                var ctrl = this, menuElement, updateMenuWatchers, responsiveWatcher;

                ctrl.isTest = window.location.href.indexOf('relesystest.net') !== -1;

                ctrl.getChatBadge = ChatConnectionService.getChatBadge;
                ctrl.isRTL = $rootScope.isRTL;

                ctrl.isBtnVisible = isBtnVisible;
                ctrl.isLandingPage = Page.isLandingPage;
                ctrl.OnProfileIconClick = OnProfileIconClick;
                ctrl.ShowMenuBadge = ShowMenuBadge;
                ctrl.ToggleMenu = ToggleMenu;
                ctrl.MenuGo = MenuGo;
                ctrl.Back = Back;
                ctrl.OpenTeams = OpenTeams;
                ctrl.GlobalFormCancel = GlobalFormCancel;
                ctrl.GlobalFormSubmit = GlobalFormSubmit;
                ctrl.isActiveNav = isActiveNav;
                ctrl.OpenNativePage = OpenNativePage;
                ctrl.createChat = createChat;
                ctrl.openHomePage = openHomePage;
                ctrl.openNotificationPage = openNotificationPage;

                ctrl.refreshPage = function () {
                    document.location.reload(true);
                };

                ctrl.ShowSurveyOptions = function (e) {
                    if (ctrl.surveyPollToken) {
                        SurveyQuestionFactory.showOptions(e, ctrl.surveyPollToken);
                    } else {
                        SurveyQuestionFactory.showOptions(e, ctrl.surveyToken);
                    }
                }

                $scope.$on('$stateChangeSuccess', function () {
                    ctrl.isSurveyPage = $location.url().includes('/survey/') ? true : false;
                });

                $scope.$on('getSurveyToken', function (event, surveyToken) {
                    ctrl.surveyToken = surveyToken;
                });

                $scope.$on('getSurveyPollToken', function (event, surveyPollToken) {
                    ctrl.surveyPollToken = surveyPollToken;
                });

                $scope.$on('ToggleMenu', function (event, val) {
                    Page.setPageContentInert(val);

                    checkIfTranslateFinished(val);

                    if (!ResponsiveService.isDesktop()) {
                        menuElement.inert = !val;
                    }
                });

                $scope.$on('$destroy', function () {
                    updateMenuWatchers();
                    responsiveWatcher();
                    document.querySelector('.menu-list-root-wrap').removeEventListener('scroll', onMenuScrolling);
                    $window.removeEventListener("resize", onResizeWindow);
                });

                InitController();

                // Init
                function InitController() {
                    $timeout(function () {
                        menuElement = $element.find('.menu').get(0);
                        if (menuElement && !ResponsiveService.isDesktop()) {
                            menuElement.inert = true;
                        }
                        toggleGradient(document.querySelector('.menu-list-root-wrap'));
                    });

                    document
                        .querySelector('.menu-list-root-wrap')
                        .addEventListener('scroll', onMenuScrolling);

                    updateMenuWatchers = $rootScope.$on(events.MENU_UPDATED, function () {
                        ctrl.Menu = Menu.sidebarMenu();
                    });

                    responsiveWatcher = ResponsiveService.watch(function (data) {
                        if (menuElement) {
                            menuElement.inert = data !== 'desktop';
                        }
                    });

                    $scope.$on(events.DEPARTMENT_CHANGED, updateMenu);
                    $scope.$on(events.MENU_UPDATED, updateMenu);

                    // Remove the initial loading
                    Page.removeInitialLoading();

                    try {
                        // If we are in a native wrapper, we do a version check
                        if (Page.getSettings().NativeSettings.IsNativeWrapper === true) {
                            NativeFactory.checkVersion();
                        }
                    } catch (ex) { /* We don't handle errors */ }

                    if (!ResponsiveService.isDesktop()) {
                        // Check Add To Home
                        AddToHome.checkAddToHome();
                    }

                    // Show Base if Walkthrough is not being shown
                    if (WalkthroughFactory.isChecked()) {
                        $timeout(function () {
                            Page.showBase();
                        });
                    }

                    if (ResponsiveService.isDesktop()) {
                        if (BookmarkService.isEnabled() && !BookmarkService.isLoaded() && !BookmarkService.isLoading()) {
                            BookmarkService.loadBookmarks();
                        }
                    }

                    // Base Setup
                    $rootScope.IsFrontPage = false;

                    ctrl.ProfileOpen = false;
                    ctrl.Page = Page;
                    ctrl.Profile = Profile;
                    ctrl.Menu = Menu.sidebarMenu();


                    ctrl.Settings = Page.getSettings();
                    ctrl.NoMenuChildren = true;
                    ctrl.showDownloadButton = ctrl.Settings.NativeSettings &&
                        ctrl.Settings.NativeSettings.IsNativeAppEnabled &&
                        ctrl.Settings.NativeSettings.IsMenuDownloadEnabled &&
                        !ctrl.Settings.NativeSettings.IsNativeWrapper;

                    TemplateFactory.setTemplate(Page.getSettings().Templates.Navigation);
                    ctrl.template = TemplateFactory.getTemplate();

                    $scope.WalkthroughFactory = WalkthroughFactory;
                    $scope.ProfileNavigationFactory = ProfileNavigationFactory;
                    $scope.NotificationFactory = NotificationFactory;
                    $scope.Bookmarks = BookmarkService;
                    $scope.Notifications = NotificationFactory;
                    ctrl.DepartmentSwapService = DepartmentSwapService;

                    ctrl.isDesktop = ResponsiveService.isDesktop();

                    // Calculate if the menu doesn't have any children
                    if (ctrl.Menu && ctrl.Menu.length) {
                        for (var i = 0; i < ctrl.Menu.length; i++) {
                            var item = ctrl.Menu[i];
                            if (item.Children.length > 0 && !item.ShowOnlyChildren) {
                                ctrl.NoMenuChildren = false;
                                break;
                            }
                        }
                    }
                }

                function onMenuScrolling(e) {
                    const el = e.currentTarget;
                    $scope.$apply(() => {
                        toggleGradient(el);
                    })
                }

                function toggleGradient(el) {
                    ctrl.showTopGradient = el.scrollTop > 15;
                    ctrl.showBottomGradient = el.scrollHeight > (el.scrollTop + el.offsetHeight);
                }

                function findFocusable(element) {
                    return angular.element(element).find('a[href], area[href], input, select, textarea, button, iframe, object, embed, *[tabindex], *[contenteditable]')
                        .not('[tabindex=-1], [disabled], :hidden');
                }

                // Methods
                function ShowMenuBadge() {
                    var count = 0;

                    if (ctrl.Menu && ctrl.Settings.MenuBurgerBadge) {
                        for (var i = 0; i < ctrl.Menu.length; i++) {
                            if (ctrl.Menu[i].Badge > 0) {
                                count = count + ctrl.Menu[i].Badge;
                            }
                        }
                    }

                    return count;
                }

                function ToggleMenu(show) {

                    $timeout(function () {
                        findFocusable(menuElement).first().focus();
                    }, 300);

                    Page.toggleMenu(show);
                }

                function MenuGo(item, $index) {

                    if (item.PreUrl) {
                        Page.stateGoLink(item);
                        return;
                    }

                    if (item.Children.length === 0) {

                        // removing stored navigation
                        Page.setClearStoredNavState(true);
                        Page.clearStoredNav();

                        if (item.State === 'logout') {
                            var opts = {
                                message: $filter('translate')('PROFILE.LOGOUT_LABEL')
                            };
                            ConfirmPopupService.open(opts).then(function () {
                                Page.stateGo('logout');
                            });
                        } else if (item.current === true) {
                            Page.stateReload(); 
                        } else {
                            Page.stateGo(item.State, item.Token, item.Extra);
                        }

                    } else {
                        // Close all other menus first
                        var openMenu = !ctrl.Menu[$index].open;

                        for (var i = 0; i < ctrl.Menu.length; i++) {
                            ctrl.Menu[i].open = false;
                        }

                        // Open the menu if supposed to
                        ctrl.Menu[$index].open = openMenu
                    }
                }

                function Back(e) {
                    $rootScope.$broadcast('GlobalBackClick');
                    e.currentTarget.blur();
                }

                function OpenTeams() {
                    var isItIos = NativeHelper.isIOS() || BasicHelper.isIOS();
                    var isItAndroid = NativeHelper.isAndroid() || BasicHelper.isAndroid();
                    if (isItIos) {
                        window.location.href = $sce.trustAsResourceUrl("msteams://teams.microsoft.com/l/chat/0/0");
                    } else {
                        if (isItAndroid) {
                            window.open("https://teams.microsoft.com/l/chat/0/0");
                        } else {
                            window.location.href = $sce.trustAsResourceUrl("msteams://teams.microsoft.com/l/chat/0/0");
                        }
                    }
                };

                function GlobalFormCancel() {
                    $rootScope.$broadcast('GlobalFormCancel');
                }

                function GlobalFormSubmit() {
                    $rootScope.$broadcast('GlobalFormSubmit');
                }

                function OnProfileIconClick() {
                    if (ResponsiveService.isDesktop()) {
                        Profile.toggleDesktopMenuDropdownVisibility();
                    } else {
                        ProfileNavigationFactory.toggleNavigation();
                        $element.find('.profile-navigation').get(0).inert = false;
                        Page.setPageContentInert(true);
                        $timeout(function () {
                            findFocusable($element.find('.profile-navigation').get(0)).first().focus();
                        }, 300);
                    }
                }

                function isBtnVisible() {
                    return !Profile.isProfilePage();
                }

                function OpenNativePage() {
                    window.location.href = '/Native/?redirect=false';
                }

                function isActiveNav(key) {
                    if (key === $state.current.name) {
                        return true;
                    }
                }

                function checkIfTranslateFinished(isOpened) {
                    const menu = document.querySelector('.menu'),
                        menuBg = document.querySelector('.menu-bg'),
                        base = document.querySelector('.base'),
                        baseTop = document.querySelector('.base-top'),
                        transitionOverflow = () => {
                            if (!isOpened) {
                                $timeout(() => {
                                    menuBg.classList.remove('menu-opened');
                                    base.classList.remove('disable-overflow', 'menu-opened');
                                    baseTop.classList.remove('disable-overflow');
                                    menu.removeEventListener('transitionend', transitionOverflow);
                                }, 100)
                            }
                        };

                    menuBg.classList.add('menu-opened');
                    base.classList.add('disable-overflow', 'menu-opened');
                    baseTop.classList.add('disable-overflow');
                    menu.addEventListener('transitionend', transitionOverflow)
                }

                function openHomePage() {
                    Page.stateGo('home');
                    Page.setTitle('');
                    Page.clearBackButton();
                }

                function openNotificationPage() {
                    Page.stateGo('notifications');
                }

                function createChat() {
                    ChatPopupsService.openCreateChatPopup();
                }

                function updateMenu() {
                    ctrl.Menu = Menu.sidebarMenu();
                }

                function onResizeWindow() {
                    $timeout(function () {
                        TemplateFactory.setTemplate(Page.getSettings().Templates.Navigation);
                    })
                }

                // Listen for resize changes
                $window.addEventListener("resize", onResizeWindow, false);
            }
        ]);
})();