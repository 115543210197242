(() => {
    'use strict';

    angular.module('App')
        .component('profileSettings', {
            template: require('./ProfileSettingsComponent.tpl.html'),
            controller: ['ProfileSettingsService', 'Page', ProfileSettingsComponentController],
            controllerAs: 'ctrl'
        });

    function ProfileSettingsComponentController(ProfileSettingsService, Page) {
        var ctrl = this,
            departmentSwapWatcher;

        ctrl.$onInit = onInit;
        ctrl.$onDestroy = onDestroy;

        ctrl.openPersonalInformation = openPersonalInformation;
        ctrl.openSecurity = openSecurity;

        ctrl.openSharedInformation = openSharedInformation;
        ctrl.openCommunication = openCommunication;
        ctrl.openCelebrations = openCelebrations;
        ctrl.openBlockedUsers = openBlockedUsers;
        ctrl.openPrivacyPolicy = openPrivacyPolicy;

        ctrl.openNotifications = openNotifications;
        ctrl.openLanguage = openLanguage;
        ctrl.openAccessibility = openAccessibility;

        function onInit() {
            const baseSettings = Page.getSettings().Base;

            ctrl.hasAccessibility = baseSettings.DyslexiaFontEnabled;
            ctrl.hasCelebrations = false;
            ctrl.canChangeLanguage = false;

            ctrl.isLoadingProfileData = true;
            ProfileSettingsService.getFullProfile().then(res => {
                initData(res)
                ctrl.isLoadingProfileData = false;
            }).catch(() => {
                ctrl.isLoadingProfileData = false;
            });
        }

        function onDestroy() {
            if (departmentSwapWatcher) {
                departmentSwapWatcher();
            }
        }

        function initData(data) {
            ctrl.profile = data;
            ctrl.canChangeLanguage = ctrl.profile.CanChangeCulture;
            ctrl.hasCelebrations = ctrl.profile.CelebrationsFeatureEnabled;
        }


        // Account
        function openPersonalInformation() {
            Page.stateGo('profileSettingsPersonalInformation');
        }

        function openSecurity() {
            Page.stateGo('profileSettingsSecurity');
        }

        // Privacy
        function openSharedInformation() {
            Page.stateGo('profileSettingsSharedInformation');
        }

        function openCommunication() {
            Page.stateGo('profileSettingsCommunication');
        }

        function openCelebrations() {
            Page.stateGo('profileSettingsCelebrations');
        }

        function openBlockedUsers() {
            Page.stateGo('blockedUsers', null, null, Page.getPageTransition());
        }

        function openPrivacyPolicy() {
            Page.stateGo('policyView', null, null, Page.getPageTransition());
        }

        // Preferences
        function openNotifications() {
            Page.stateGo('notificationsSettings', null, null, Page.getPageTransition());
        }

        function openLanguage() {
            Page.stateGo('profileSettingsLanguage');
        }

        function openAccessibility() {
            Page.stateGo('profileSettingsAccessibility');
        }
    }
})();