(() => {
    'use strict';

    angular
        .module('App')
        .component('taskAssignees', {
            template: require('./TaskAssigneesComponent.tpl.html'),
            controller: ['$scope', '$timeout', 'AssigneesPopupService',
            TaskAssigneesController],
            controllerAs: 'ctrl',
            require: {
                ngModel: 'ngModel'
            },
            bindings: {
                permissions: '<',
                issueToken: '<',
                needAssignees: '<'
            }
        });

    function TaskAssigneesController($scope, $timeout, AssigneesPopupService){
        const ctrl = this;
        ctrl.$onInit = init;
        ctrl.assignUsers = assignUsers;

        function init() {
            setValidation();
        }

        function setValidation() {
            ctrl.ngModel.$isEmpty = (val) => {
                return val === undefined || !val.length
            }
        }

        function assignUsers() {
            AssigneesPopupService.openAssigneesPopup({
                considerPermissions: true,
                token: ctrl.issueToken,
                permissions: ctrl.permissions,
                selectedUsers: ctrl.ngModel.$viewValue,
                canModerate: true,
                privateTask: ctrl.needAssignees,
                onSave: (selectedUsers) => {
                    ctrl.ngModel.$setViewValue(_.cloneDeep(selectedUsers))
                }
            })
        }
    }
})();