(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('instoreGuide', {
                    parent: 'base',
                    url: '/instore/guide/:token',
                    templateUrl: '/Instore/Guide',
                    resolve: {
                        guide: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/Instore/GetGuide/' + $stateParams.token);
                            } return;
                        }]
                    },
                    controller: 'InstoreGuideController as InstoreGuideCtrl'
                })
                .state('instorePlan', {
                    parent: 'base',
                    url: '/instore/plan/:token/:extra',
                    templateUrl: '/Instore/Plan',
                    resolve: {
                        plan: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/Instore/GetPlan/' + $stateParams.token + "/?user=" + $stateParams.extra);
                            } return;
                        }]
                    },
                    controller: 'InstorePlanController as InstorePlanCtrl'
                })
                .state('instoreUpload', {
                    parent: 'base',
                    url: '/instore/upload/:token',
                    templateUrl: '/Instore/Upload',
                    resolve: {
                        uploadList: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/Instore/GetUploadList/' + $stateParams.token);
                            } return;
                        }]
                    },
                    controller: 'InstoreUploadController as InstoreUploadCtrl'
                });
        }
    ]);

    app.controller('InstoreGuideController', ['Page', '$scope', 'guide', 'events',
        function (Page, $scope, guide, events) {
            var ctrl = this;
            $scope.guide = guide.data.Guide;
            Page.setTitle(guide.data.PageTitle);
            Page.trackPageView();
            Page.showBackButton('home');

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
              Page.stateReload();
            });
        }
    ]);

    app.controller('InstorePlanController', ['$scope', 'Page', 'plan', '$stateParams', 'events',
        function ($scope, Page, plan, $stateParams, events) {
            var ctrl = this;
            $scope.plan = plan.data;

            Page.setTitle(plan.data.PageTitle);

            if (plan.data.IsPersonal) {
                Page.showBackButton('instorePlan', $stateParams.token);
            } else {
                Page.showBackButton('home');
            }
            
            $scope.ShowPlan = function (user) {
                Page.stateGo('instorePlan', $stateParams.token, user.UserToken);
            };

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
              if ($scope.plan.IsPersonal) {
                Page.stateGoBack('instorePlan', $stateParams.token);
              } else {
                Page.stateReload();
              }
            });
        }
    ]);

    app.controller('InstoreUploadController', ['$scope', 'Page', 'uploadList', 'Upload', '$http', 'toastr', 'events',
        function ($scope, Page, uploadList, Upload, $http, toastr, events) {
            var ctrl = this;
            $scope.uploadList = uploadList.data;
            Page.showBackButton('home');

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
              Page.stateReload();
            });

            // Init
            $scope.postModel = {
                Text: '',
                Task: null,
                Media: [],
                Images: []
            };

            $scope.Post = function () {

                if ($scope.postModel.Media.length >= 3 && $scope.postModel.Task !== null && $scope.postModel.Task !== undefined && $scope.postModel.Task !== '') {
                    $http.post('/Instore/UploadPost', $scope.postModel).then(function (response) {
                        if (response.data.Success) {
                            $scope.uploadList.Tasks = response.data.Tasks;
                            $scope.postModel = {
                                Text: '',
                                Task: null,
                                Media: [],
                                Images: []
                            };
                            $translate('INSTORE.SUCCESS').then(function (translation) {
                                toastr.Success(translation);
                            });
                        } else {
                            $translate('UPLOAD.WRONG_FILE').then(function (translation) {
                                toastr.error(translation);
                            });
                        }
                    });
                } else {
                    $translate('INSTORE.MISSING').then(function (translation) {
                        toastr.error(translation);
                    });
                }
            };

            $scope.PostImageUploaded = function (media) {
                $scope.postModel.Media.push({ MediaId: media.MediaId });
                $scope.postModel.Images.push(media.ImageFormats.W100);
            };

            $scope.RemoveImage = function (index) {
                $scope.postModel.Media.splice(index, 1);
                $scope.postModel.Images.splice(index, 1);
            };
        }
    ]);

})();