(function () {
    'use strict';

    angular
        .module('App')
        .component('formsOverview', {
            template: require('./FormsOverviewComponent.html'),
            controller: ['Page', '$stateParams', FormsOverviewController],
            controllerAs: 'ctrl',
            bindings: {
                forms: '<',
                options: '<'
            }
        });

    function FormsOverviewController(Page, $stateParams) {
        const ctrl = this;

        ctrl.openForm = openForm;

        function openForm({ServiceFormId, Badge}) {
            Page.stateGo(
                'serviceForm',
                $stateParams.token,
                `?ServiceFormId=${ServiceFormId}`,
                Page.getPageTransition(),
                Object.assign({badge: Badge}, ctrl.options)
            );
        }
    }
})();


