(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('highscore', {
                    parent: 'base',
                    url: '/highscore/:token',
                    templateUrl: '/HighScore',
                    params: {
                        backState: null
                    },
                    resolve: {
                        highScoreData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/HighScore/GetData/' + $stateParams.token);
                            }
                            return;
                        }]
                    },
                    controller: 'HighScoreController as HighScoreCtrl'
                });
        }]);

    app.controller('HighScoreController', ['$rootScope', '$scope', '$state', '$http', '$stateParams', 'Page',
        'highScoreData', '$translate', 'DepartmentViewerService', 'ProfileViewerService', 'events',
        function ($rootScope, $scope, $state, $http, $stateParams, Page, highScoreData, $translate,
                  DepartmentViewerService, ProfileViewerService, events) {
            var stepCount = 20;
            // Bind methods
            $scope.scroll = Scroll;
            $scope.tabSelected = TabSelected;
            $scope.showProfile = ShowProfile;
            $scope.showDepartment = ShowDepartment;
            $scope.tabsInit = tabsInit;
            $scope.isLoading = false;

            // Init
            InitController();

            function InitController() {

                if ($state.params.backState) {
                    Page.showBackButton($state.params.backState.state, $state.params.backState.token, $state.params.backState.extra);
                } else {
                    Page.showBackButton('home');
                }

                Page.setTitle(highScoreData.data.PageTitle);
                Page.trackPageView();

                $scope.allUsers = highScoreData.data.AllUsers;
                $scope.departmentUsers = highScoreData.data.DepartmentUsers;
                $scope.departments = highScoreData.data.Departments;

                $scope.currentUser = highScoreData.data.CurrentUser;
                $scope.currentDepartment = highScoreData.data.CurrentDepartment;

                $scope.settings = highScoreData.data.Settings;

                // Translate Headlines
                // All
                if ($scope.settings.UserHeadline.length < 1) {
                    $translate('HIGHSCORE.ALL').then(function (translation) {
                        $scope.settings.UserHeadline = translation;
                    });
                }
                if ($scope.settings.UserPointName.length < 1) {
                    $translate('HIGHSCORE.POINTS').then(function (translation) {
                        $scope.settings.UserPointName = translation;
                    });
                }
                // Store
                if ($scope.settings.UserDepartmentHeadline.length < 1) {
                    $translate('CONTACT.STORE').then(function (translation) {
                        $scope.settings.UserDepartmentHeadline = translation;
                    });
                }
                if ($scope.settings.UserDepartmentPointName.length < 1) {
                    $translate('HIGHSCORE.POINTS').then(function (translation) {
                        $scope.settings.UserDepartmentPointName = translation;
                    });
                }
                // Stores
                if ($scope.settings.DepartmentHeadline.length < 1) {
                    $translate('CONTACT.STORES').then(function (translation) {
                        $scope.settings.DepartmentHeadline = translation;
                    });
                }
                if ($scope.settings.DepartmentPointName.length < 1) {
                    $translate('HIGHSCORE.AVG_POINTS').then(function (translation) {
                        $scope.settings.DepartmentPointName = translation;
                    });
                }

                // Limit shown users/departments
                $scope.allUsersLimit = stepCount;
                $scope.departmentUsersLimit = stepCount;
                $scope.departmentsLimit = stepCount;

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });
            }

            // Methods
            function Scroll(type) {
                if (type == undefined || type == null || type.length == 0) {
                    type = $scope.tabType;
                }

                switch (type) {
                    case 'user': // Users
                        if ($scope.allUsersLimit < $scope.allUsers.length) {
                            $scope.allUsersLimit += stepCount;
                        }
                        break;
                    case 'departmentUser': // Department Users
                        if ($scope.departmentUsersLimit < $scope.departmentUsers.length) {
                            $scope.departmentUsersLimit += stepCount;
                        }
                        break;
                    case 'department': // Departments
                        if ($scope.departmentsLimit < $scope.departments.length) {
                            $scope.departmentsLimit += stepCount;
                        }
                        break;
                }
            }

            function TabSelected(type) {
                $scope.tabType = type;
                loadData(type);
            }

            function ShowProfile(token) {
                ProfileViewerService.showProfileCard(token);
            }

            function ShowDepartment(token) {
                DepartmentViewerService.showDepartmentCard(token);
            }

            function loadData(type) {
                switch (type) {
                    case 'user':
                        if (!$scope.allUsers.length) {
                            $scope.isLoading = true;
                            $http.get('/HighScore/GetAllList/' + $stateParams.token).then(function (resp) {
                                $scope.allUsers = resp.data.AllUsers;
                                $scope.currentUser = resp.data.CurrentUser;
                                $scope.isLoading = false;
                            })
                        }
                        break;
                    case 'departmentUser':
                        if (!$scope.departmentUsers.length) {
                            $scope.isLoading = true;
                            $http.get('/HighScore/GetMyStoreList/' + $stateParams.token).then(function (resp) {
                                $scope.departmentUsers = resp.data.DepartmentUsers;
                                $scope.currentDepartment = resp.data.CurrentDepartment;
                                $scope.isLoading = false;
                            })
                        }
                        break;
                    case 'department':
                        if (!$scope.departments.length) {
                            $scope.isLoading = true;
                            $http.get('/HighScore/GetDepartmentList/' + $stateParams.token).then(function (resp) {
                                $scope.departments = resp.data.Departments;
                                $scope.currentDepartment = resp.data.CurrentDepartment;
                                $scope.isLoading = false;
                            })
                        }
                        break;
                }
            }

            function tabsInit(tabs) {
                if (highScoreData.data.Settings.ShowAll && highScoreData.data.Settings.ShowMyStore) {
                    highScoreData.data.Settings.HighscoreRankingFilterType &&
                    tabs.select(highScoreData.data.Settings.HighscoreRankingFilterType - 1);
                }
            }
        }
    ]);
})();
