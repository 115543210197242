(() => {
    
    'use strict';
    
    angular
        .module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('userManagementCreate', {
                parent: 'base',
                url: '/userManagement/:token/Create/:extra',
                template: require('./UserManagementCreate.html'),
                resolve: {
                    userSettings: ['$http', 'base', '$stateParams', function ($http, base, $stateParams) {
                        if (base) {
                            return $http.get('/UserManagement/GetSettings?token=' + $stateParams.token);
                        }
                    }]
                },
                controller: 'UserManagementCreateController as UserManagementCreateCtrl'
            });
        }])
        .controller('UserManagementCreateController', ['$rootScope', '$scope', '$stateParams', 'Page', 'userSettings',
            '$translate', '$http', 'ModalService', 'events', 'ToastFactory', 'UserManagementService',
            function ($rootScope, $scope, $stateParams, Page, userSettings, $translate, $http, ModalService,
                      events, ToastFactory, UserManagementService) {

                // Init
                initController();

                function initController() {
                    // Show the form to be submitable
                    $rootScope.ShowFormSubmit = true;
                    // Reset validation data
                    $scope.validationData = [];

                    // Hook up to for submit event
                    var userSubmit = $rootScope.$on('GlobalFormSubmit', function () {
                        $scope.validationData = UserManagementService.validate($scope.userCreateModel, $scope.settings);
                        if ($scope.validationData.length === 0) {
                            createUser();
                        }
                    });
                    var userCancel = $rootScope.$on('GlobalFormCancel', function () {
                        Page.stateGoPrevious('userManagement', $stateParams.token);
                    });
                    $scope.$on('$destroy', userSubmit);
                    $scope.$on('$destroy', userCancel);

                    // Page Title
                    $translate('USER_MANAGEMENT.CREATE').then(function (translation) {
                        Page.setTitle(translation);
                    });

                    // Create Model
                    var country = userSettings.data.Country;
                    $scope.userCreateModel = new UserCreateModel(
                        userSettings.data.DepartmentId,
                        country.CountryId,
                        country.PhoneCode,
                        userSettings.data.Settings.AccountModuleId,
                        userSettings.data.Settings.DefaultRoleList
                    );

                    // handle custom user alias label (can be defined under UserModuleSettings)
                    if (!(userSettings.data.UserAliasLabel)) {
                        $translate('USER_MANAGEMENT.ALIAS').then(function (translation) {
                            userSettings.data.Settings.UserAliasLabel = translation;
                        });
                    } else {
                        userSettings.data.Settings.UserAliasLabel = userSettings.data.UserAliasLabel;
                    }

                    // add "formatet" array with job titles
                    userSettings.data.Settings.PredefinedJobTitleOptionsList = userSettings.data.PredefinedJobTitleOptionsList

                    // Data
                    $scope.settings = userSettings.data.Settings;
                    $scope.additionalDepartmentsEnabled = userSettings.data.AdditionalDepartmentsEnabled;
                    $scope.departmentSettings = {
                        departments: userSettings.data.AllowedDepartments
                    };
                    $scope.userGroupSettings = {
                        usergroups: userSettings.data.AllowedUserGroups,
                        locked: userSettings.data.Settings.DefaultRoleList,
                        selected: userSettings.data.Settings.DefaultRoleList,
                    };

                    // Listen for events
                    $scope.$on(events.DEPARTMENT_CHANGED, function () {
                        Page.stateGoBack('userManagement', $stateParams.token);
                    });
                }

                // Methods
                function pickPhoneCode() {
                    ModalService.showModal({
                        templateUrl: '/Profile/PhoneCodePicker',
                        controller: 'PhoneCodePickerController',
                        inputs: {countryId: $scope.userCreateModel.PhoneCountryId}
                    }).then(function (modal) {
                        // Returns the modal for further options if needed
                        modal.scope.$watch('selectedCountry', function (newVal) {
                            if (newVal !== null) {
                                $scope.userCreateModel.PhoneCountryId = newVal.CountryId;
                                $scope.userCreateModel.PhoneCode = newVal.PhoneCode;
                            }
                        });
                    });
                }

                function createUser() {
                    if (!$scope.isSaving) {

                        $scope.isSaving = true;
                        Page.startLoading();
                        $scope.validationData = [];

                        $http.post('/UserManagement/CreateUser', $scope.userCreateModel).then(function (response) {
                            $scope.isSaving = false;
                            Page.stopLoading();

                            ToastFactory.successTranslated('USER_MANAGEMENT.SAVED');

                            // Success, move on
                            Page.stateGoPrevious('userManagement', $stateParams.token);

                        }, function (response) {
                            $scope.isSaving = false;
                            Page.stopLoading();

                            // Failed, show form validations if possible
                            if (response.status === 400) {
                                $scope.validationData = response.data;
                                ToastFactory.warningTranslated('ERROR.FORM_VALIDATION');
                            } else {
                                ToastFactory.errorTranslated('ERROR.GENERAL');
                            }
                        });
                    }
                }

                // Objects
                function UserCreateModel(departmentId, countryId, phoneCode, accountModuleId, userGroups) {
                    this.AccountModuleId = accountModuleId;
                    this.DepartmentId = departmentId;
                    this.Name = '';
                    this.Title = '';
                    this.Phone = '';
                    this.PhoneCountryId = countryId;
                    this.PhoneCode = phoneCode;
                    this.Email = '';
                    this.Alias = '';
                    this.UserGroups = userGroups;
                    this.SendLoginInfo = true;
                    this.AdditionalDepartments = [];
                }

                // Bindings
                $scope.pickPhoneCode = pickPhoneCode;
            }
        ]);

})();
