(function () {
    'use strict';

    angular.module('App')
        .factory('ChatDataService', ['$http', '$stateParams', '$q', 'Profile', ChatDataService]);

    function ChatDataService($http, $stateParams, $q, Profile) {
        
        let blockedIds = [];

        return {
            createChannel: createChannel,
            getChannelMessages: getChannelMessages,
            hide: hide,
            leaveChannel: leaveChannel,
            markChannelsAsRead: markChannelsAsRead,
            getUserChannels: getUserChannels,
            getChannel: getChannel,
            getMedias: getMedias,
            updateChannelTitle: updateChannelTitle,
            setChannelNotificationSettings: setChannelNotificationSettings,
            addChannelAdminRights: addChannelAdminRights,
            removeChannelAdminRights: removeChannelAdminRights,
            removeUsersFromChannel: removeUsersFromChannel,
            addUsersToChannel: addUsersToChannel,
            searchUsers: searchUsers,
            getChannelCompanion: getChannelCompanion,
            setBlockedIds: setBlockedIds,
            getBlockedIds: getBlockedIds,
        };


        function createChannel(users, appLinkId, hideAuthor) {
            return $http.post('/chat/v2/createChannel', {
                name: generateChannelName(users),
                userIds: users.map(item => item.userId),
                appLinkId: appLinkId,
                hideAuthor: hideAuthor,
            }).then(resp => resp.data.channelId)
        }

        function generateChannelName(users) {
            let chatName = users.length > 1 ? (Profile.getProfile().Name + ', ') : '';
            users.forEach((user, key) => {
                chatName += key !== (users.length - 1) ? (user.name + ', ') : user.name;
            });

            return chatName.length > 60 ? chatName.slice(0, 60) + '...' : chatName;
        }

        function getChannelMessages(channelId, {
            afterMessageId = null,
            beforeMessageId = null,
            aroundMessageId = null,
            limit = '50'
        }) {
            const params = new URLSearchParams({beforeMessageId, afterMessageId, aroundMessageId, limit}),
                url = `/chat/v2/getMessages/${channelId}?${params.toString()}`;

            return $http.get(url).then(resp => resp.data)
        }

        function hide(channelId) {
            return $http.post('/chat/v2/hideChannel', {channelId: channelId});
        }

        function leaveChannel(channelId) {
            return $http.post('/chat/v2/leaveChannel', {channelId: channelId});
        }

        function markChannelsAsRead(channelId) {
            return $http.post('/chat/v2/markChannelAsRead', {
                channelId: channelId
            }).then(resp => resp.data)
        }

        function getUserChannels() {
            return $http.get('/chat/v2/getChannelList').then(resp => resp.data)
        }

        function getChannel(channelId) {
            return $http.get('/chat/v2/getChannel/' + channelId).then(resp => resp.data)
        }

        function getMedias(mediaIds) {
            return $http.post('/chat/v2/getMedias', {
                mediaIds: mediaIds
            }).then(resp => resp.data)
        }

        function updateChannelTitle(channelId, name, ignoreContentSafetyWarning) {
            return $http.post('/chat/v2/updateChannel', {
                channelId: channelId,
                name: name,
                IgnoreContentSafetyWarning: ignoreContentSafetyWarning
            }).then(resp => resp.data)
        }

        function setChannelNotificationSettings(channelId, enabled) {
            return $http.post('/chat/v2/setChannelNotificationSettings', {
                channelId: channelId,
                enabled: enabled
            })
        }

        function addChannelAdminRights(channelId, userId) {
            return $http.post('/chat/v2/addChannelAdminRights', {
                channelId: channelId,
                userId: userId
            })

        }

        function removeChannelAdminRights(channelId, userId) {
            return $http.post('/chat/v2/removeChannelAdminRights', {
                channelId: channelId,
                userId: userId
            })
        }

        function removeUsersFromChannel(channelId, userIds) {
            return $http.post('/chat/v2/removeUsersFromChannel', {
                channelId: channelId,
                userIds: userIds
            })
        }

        function addUsersToChannel(channelId, userIds, shareHistory) {
            return $http.post('/chat/v2/addUsersToChannel', {
                channelId: channelId,
                userIds: userIds,
                shareHistory
            })
        }

        function searchUsers(users, searchString) {
            return _.filter(users, function (user) {
                return (user.Name + user.DepartmentName).toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
            });
        }

        function getChannelCompanion(channel) {
            return channel.members.filter(member => member.userId !== Profile.getProfile().UserToken)[0]
        }
        
        function getBlockedIds() {
            return blockedIds;
        }
        
        function setBlockedIds(ids) {
            blockedIds = ids;
        }
    }
})();