(() => {
    'use strict';

    angular
        .module('App')
        .component('myTasks', {
            template: require('./MyTasksComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$scope', '$document', 'BasicHelper', 'ToastFactory', 'Profile', MyTasksController],
            transclude: true,
            bindings: {
                issues: '<',
                overdueAmount: '=',
                feedLink: '<',
                openOverdue: '<',
                paging: '<'
            }
        });

    function MyTasksController($rootScope, $scope, $document, BasicHelper, ToastFactory, Profile) {
        var ctrl = this;

        ctrl.todayIssues = [];
        ctrl.rootScopeListener = null;

        ctrl.ifAllCompleted = ifAllCompleted;
        ctrl.ifNoTasks = ifNoTasks;
        ctrl.ifHaveTasks = ifHaveTasks;
        ctrl.copyFeedUrl = copyFeedUrl;
        ctrl.$onInit = onInit;
        ctrl.$onDestroy = onDestroy;

        ctrl.loadMoreMyTasks = true;
        ctrl.loadMoreManagerTasks = true;
        ctrl.isLoadingMyTasks = false;
        ctrl.isLoadingManagerTasks = false;
        ctrl.myTasksPaging = myTasksPaging;
        ctrl.managerTasksPaging = managerTasksPaging;

        function findTodayTasks(issues) {
            return _.filter(issues, function (issue) {
                if (issue.IsTodayIssue && issue.IsUserAllowedToCompleteIssue) {
                    return issue;
                }
            });
        }

        function findMyTasks(issues) {
            return _.filter(issues, function (issue) {
                var issueIsBeforeNow = moment(issue.EndTimeLocal).isBefore(moment());

                if (issue.IsUserAllowedToCompleteIssue && !issueIsBeforeNow) {
                    return issue;
                }
            });
        }

        function findManagerIssues(issues) {
            return _.filter(issues, function (issue) {
                var issueIsBeforeNow = moment(issue.EndTimeLocal).isBefore(moment().startOf('day'));

                if (!issue.IsUserAllowedToCompleteIssue && !issueIsBeforeNow) {
                    return issue;
                }
            });
        }

        function copyFeedUrl() {
            BasicHelper.CopyClipbord($document.find("#feed-url"));
            ToastFactory.successTranslated('TASK_MANAGEMENT.FEED_COPIED');
        }

        function onInit() {
            var profile = Profile.getProfile();

            ctrl.profile = {
                name: profile.Name,
                image: (profile.Image) ? profile.Image : profile.DefaultImage
            };

            var tz = profile.TimeZone.IANA;
            tz = (_.isString(tz)) ? tz : 'Europe/Paris';
            var currDate = moment().tz(tz);

            ctrl.feedUrl = window.location.origin + ctrl.feedLink;

            $scope.$watchCollection('ctrl.issues', function (issues) {
                ctrl.todayIssues = findTodayTasks(issues);
                ctrl.myTasks = findMyTasks(issues);
                ctrl.managerIssues = findManagerIssues(issues);

                updatePercentCompleted();
            });

            ctrl.current = {
                day: currDate.format('ddd'),
                date: currDate.date(),
                month: currDate.format('MMM'),
                year: currDate.year()
            };

            ctrl.rootScopeListener = $rootScope.$on('TaskManagement:taskUpdated', updatePercentCompleted);
        }

        function onDestroy() {
            ctrl.rootScopeListener && ctrl.rootScopeListener();
        }

        function ifAllCompleted() {
            if (!ctrl.todayIssues.length) {
                return false;
            }

            return ctrl.todayIssues
                .filter(function (issue) {
                    return issue.IsCompletedByCurrentUser === true;
                })
                .length === ctrl.todayIssues.length;
        }

        function ifNoTasks() {
            return !ctrl.todayIssues.length
        }

        function ifHaveTasks() {
            return ctrl.todayIssues.length > 0;
        }

        function updatePercentCompleted() {
            ctrl.percentCompleted = ctrl.todayIssues.length ?
                Math.round(ctrl.todayIssues.filter(function (issue) {
                    return issue.IsCompletedByCurrentUser
                }).length * 100 / ctrl.todayIssues.length)
                : 0;
        }

        function myTasksPaging() {
            console.log('Load more! - My Tasks');
            ctrl.loadMoreMyTasks = false;
            ctrl.isLoadingMyTasks = true;
        }

        function managerTasksPaging() {
            console.log('Load more! - Manager Tasks');
            ctrl.loadMoreManagerTasks = false;
        }
    }
})();