(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('TimeLocale', ['$q', 'Profile', function ($q, Profile) {
        var f = {
            getUserLocale: function () {
                var lang = '';
                if (navigator.languages && navigator.languages.length) {
                    // latest versions of Chrome and Firefox set this correctly
                    lang = navigator.languages[0];
                } else if (navigator.userLanguage) {
                    // IE only
                    lang = navigator.userLanguage;
                } else {
                    // latest versions of Chrome, Firefox, and Safari set this correctly
                    lang = navigator.language;
                }
                return lang;
            },
            dateTimeFormat: function (jsonDate, format, locale, removeOffset) {
                var momentDate = f.getMomentDate(jsonDate, locale, removeOffset);
                return momentDate.format(format);
            },
            timeAgo: function (jsonDate, locale) {
                var momentDate = f.getMomentDate(jsonDate, locale);
                return momentDate.fromNow();
            },
            getMomentDate: function (jsonDate, locale, removeOffset) {
                if (jsonDate && jsonDate[0] === '/') {
                    var jsonDateString = parseInt(jsonDate.substr(6));
                    if (removeOffset) {
                        var startingPoint = new Date(jsonDateString);
                        jsonDateString = jsonDateString + (startingPoint.getTimezoneOffset() * 1000 * 60);
                    }
                    jsonDate = new Date(jsonDateString);
                }

                var tz = Profile.getProfile().TimeZone.IANA;
                tz = (_.isString(tz)) ? tz : 'Europe/Paris';
                var momentDate = (jsonDate) ? moment.tz(jsonDate, tz) : moment().tz(tz);
                if (locale) {
                    momentDate.locale(locale);
                }
                return momentDate;
            },
            timeDiff: function (jsonDate, unit, locale) {
                var momentDate = f.getMomentDate(jsonDate, locale);
                var currentMomentDate = f.getMomentDate(new Date(), locale);
                return Math.abs(momentDate.diff(currentMomentDate, unit || 'hours'));
            }
        };

        return f;
    }]);
})();