(function () {
    'use strict';

    angular.module('App')
        .factory('StagesService', ['PopupWrapperService', function (PopupWrapperService) {
            return {
                openStagesPopup: openStagesPopup
            };

            function openStagesPopup(stages) {
                PopupWrapperService
                    .createDynamic('<stages-popup stages="stages"></stages-popup>',
                        {
                            stages: _.cloneDeep(stages)
                        });
            }
        }]);
})();