(function () {
    'use strict';

    angular.module('App')
        .component('channelsList', {
            template: require('./ChannelsListComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$scope', '$element', '$filter', 'ChatPopupsService', 'ToastFactory',
                'ChatConnectionService', 'ChatDataService', 'ConfirmPopupService', 'Profile', 'ResponsiveService',
                'ActionSheetService', 'MentionService', ChannelsListController],
            bindings: {
                channels: '<',
                blockedUsers: '<'
            }
        });

    function ChannelsListController($rootScope, $scope, $element, $filter, ChatPopupsService, ToastFactory,
                                    ChatConnectionService, ChatDataService, ConfirmPopupService, Profile,
                                    ResponsiveService, ActionSheetService, MentionService) {

        let ctrl = this, touchElementHammer;

        ctrl.isDesktop = ResponsiveService.isDesktop();
        ctrl.currentUserToken = Profile.getProfile().UserToken
        ctrl.requestTokens = [];
        ctrl.requestGroupTokens = [];
        ctrl.channels = sortChannels(ctrl.channels);
        ctrl.isRTL = $rootScope.isRTL;
        ctrl.$onInit = init;
        ctrl.openChannel = openChannel;
        ctrl.leaveChannel = leaveChannel;
        ctrl.hideChannel = hideChannel;
        ctrl.openChannelOptions = openChannelOptions;
        ctrl.$onDestroy = destroy;
        ctrl.getChannelCompanion = ChatDataService.getChannelCompanion;
        ctrl.createChannel = ChatPopupsService.openCreateChatPopup;
        ctrl.isUserBlocked = isUserBlocked;
        ctrl.isUserNotBlocked = isUserNotBlocked;
        ctrl.parseMentions = parseMentions;

        const destroyBadgeWatch = $scope.$watch(getBadgeCount, (newVal, oldVal) => {
                ChatConnectionService.setChatBadge(newVal);
            }),
            destroyChannelCreatedWatch = $rootScope.$on('CHAT.CHANNEL_CREATED', (ev, channelId) => {
                const channel = ctrl.channels.find(channel => channel.item.channelId === channelId);
                ChatConnectionService.openChannel(channelId, { ...channel, blockedUsers: ctrl.blockedUsers });
            }),
            destroyChannelOpened = $rootScope.$on('CHAT.CHANNEL_MARKED_AS_READ', (ev, channelId) => {
                const channel = ctrl.channels.find(channel => channel.item.channelId === channelId);
                channel && (channel.item.unreadMessagesCount = 0);
            }),
            destroyChannelRemoved = $rootScope.$on('CHAT.CHANNEL_REMOVED', (ev, channelId) =>
                removeChannelFromList(channelId));

        function init() {
            if (!ctrl.isDesktop) {
                initTouchEvents();
            }

            ChatConnectionService.onChannelListEvents(getUpdateChannelList);
            ChatConnectionService.onChannelLatMessageUpdate(updateLastMessage);
        }
        
        function parseMentions(message, members) {
            return message && MentionService.parseMentionToText(message, members);
        }

        function sortChannels(channelsData) {
            return _.orderBy(channelsData.channels ?? channelsData, function (channel) {
                return channel.item.lastMessageDate || channel.item.dateJoined;
            }, 'desc');
        }

        function getUpdateChannelList(event) {
            ChatDataService.getUserChannels().then(channels => {
                ctrl.channels = sortChannels(channels);
            })
        }

        function getBadgeCount() {
            return ctrl.channels.filter(channel => {
                return channel.item.unreadMessagesCount > 0
            }).length
        }

        function openChannel(channel) {
            ChatConnectionService.openChannel(channel.item.channelId, { ...channel, blockedUsers: ctrl.blockedUsers });
            $element.find('.channel-wrapper').removeClass('active');
        }

        function updateLastMessage(ev) {
            const updatedChannel = ctrl.channels.find(channel => {
                return channel.item.channelId === ev.channelId;
            });

            if (updatedChannel) {
                $scope.$applyAsync(() => {
                    updatedChannel.item.lastMessageAuthorId = ev.authorId;
                    updatedChannel.item.lastMessageDate = ev.dateCreated;
                    updatedChannel.item.lastMessageId = ev.messageId;
                    updatedChannel.item.lastMessageText = ev.text;
                    updatedChannel.item.unreadMessagesCount = ev.unreadMessagesCount;
                    updatedChannel.item.lastMessageIsDeleted = ev.isDeleted;
                    ctrl.channels = sortChannels(ctrl.channels);
                });
            } else {
                getUpdateChannelList();
            }
        }

        function leaveChannel($event, channel) {
            $event && $event.stopPropagation();

            ConfirmPopupService.open({
                message: $filter('translate')('CHAT.EVENT.LIST_LEAVE_CONFIRM'),
                class: 'chat-leave-confirm'
            }).then(() =>
                ChatDataService.leaveChannel(channel.item.channelId)
                    .then(() => {
                        removeChannelFromList(channel.item.channelId);
                    })
                    .catch(resp => {
                        if (resp.data.code === 'LAST_ADMIN_CANNOT_LEAVE') {
                            ConfirmPopupService.open({
                                message: $filter('translate')('CHAT.LEAVE_LAST_ADMIN_WARNING'),
                                yesText: $filter('translate')('GOT_IT'),
                                isAlert: true
                            }).then(() => {
                                $element.find('.channel-wrapper').removeClass('active');
                            });
                        } else {
                            $element.find('.channel-wrapper').removeClass('active');
                            ToastFactory.error(resp.data.error);
                        }
                    })
            );
        }

        function hideChannel($event, channel) {
            $event && $event.stopPropagation();

            ConfirmPopupService
                .open({
                    message: $filter('translate')('CHAT.EVENT.LIST_HIDE_CONFIRM'),
                    yesText: $filter('translate')('GOT_IT'),
                    isAlert: true
                })
                .then(() => {
                    ChatDataService.hide(channel.item.channelId);
                    removeChannelFromList(channel.item.channelId);
                });

            return false;
        }

        function removeChannelFromList(channelId) {
            ctrl.channels = ctrl.channels.filter(channelItem => channelItem.item.channelId !== channelId);
        }

        function initTouchEvents() {
            var touchElement = $element.get(0);

            touchElementHammer = new Hammer(touchElement);
            touchElementHammer.get('pan').set({direction: Hammer.DIRECTION_HORIZONTAL, threshold: 50});


            if ($rootScope.isRTL == true) {
                touchElementHammer.on('panright', (ev) => {
                    if (Math.abs(ev.deltaY) < 50) {
                        ev.preventDefault();
                        $(ev.target).parents('.channel-wrapper').addClass('active');
                    }
                });

                touchElementHammer.on('panleft', (ev) => {
                    if (Math.abs(ev.deltaY) < 50 && $(ev.target).parents('.channel-wrapper').hasClass('active')) {
                        ev.preventDefault();
                        $(ev.target).parents('.channel-wrapper').removeClass('active');
                    }
                });
            }
            else {
                touchElementHammer.on('panleft', (ev) => {
                    if (Math.abs(ev.deltaY) < 50) {
                        ev.preventDefault();
                        $(ev.target).parents('.channel-wrapper').addClass('active');
                    }
                });

                touchElementHammer.on('panright', (ev) => {
                    if (Math.abs(ev.deltaY) < 50 && $(ev.target).parents('.channel-wrapper').hasClass('active')) {
                        ev.preventDefault();
                        $(ev.target).parents('.channel-wrapper').removeClass('active');
                    }
                });
            }
        }

        function openChannelOptions(event, channel) {
            event.stopPropagation();

            ActionSheetService.create([
                {
                    text: 'Hide',
                    icon: 'eye-crossed',
                    onClick: function () {
                        hideChannel(null, channel)
                    }
                },
                {
                    text: 'Delete',
                    icon: 'delete',
                    iconClass: 'red',
                    onClick: function () {
                        leaveChannel(null, channel)
                    }
                }], event.currentTarget, null, { name: 'channels-list'}).show();
        }
        
        function isUserBlocked(userId) {
            return ctrl.blockedUsers?.includes(userId);
        } 
        
        function isUserNotBlocked(member) {
            return !ctrl.blockedUsers?.includes(member.userId);
        } 
        
        function destroy() {
            touchElementHammer && touchElementHammer.destroy();
            destroyBadgeWatch();
            destroyChannelCreatedWatch();
            destroyChannelOpened();
            destroyChannelRemoved();
        }
    }
})();