(() => {
    'use strict';

    angular
        .module('App')
        .component('reactionIcon', {
            template: require('./ReactionIconComponent.tpl.html'),
            controllerAs: 'ctrl',
            bindings: {
                typeId: '<'
            },
            controller: ['$scope', 'ReactionsService', 'REACTION_TYPE_ID', ReactionIconController]
        });

    function ReactionIconController($scope, ReactionsService, REACTION_TYPE_ID) {
        const ctrl = this;

        ctrl.REACTION_TYPE_ID = REACTION_TYPE_ID;

        $scope.$watch('ctrl.typeId', function () {
            ctrl.reaction = ReactionsService.getByTypeId(ctrl.typeId);
        })
    }
})();