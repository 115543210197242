(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('chat', {
                    parent: 'base',
                    url: '/chat/:token',
                    template: '<chat new-channel-data="ctrl.newChannelData" selected-channel-id="ctrl.selectedChannel"></chat>',
                    controller: 'ChatController as ctrl',
                    params: {
                        backState: null,
                        extra: null,
                        data: {}
                    },
                    resolve: {
                        chatConnection: ['base', 'ChatConnectionService', (base, ChatConnectionService) => {
                            if (base) {
                                return ChatConnectionService.connect()
                            }
                        }]
                    }

                });
        }])
        .controller('ChatController', ['$rootScope', '$stateParams', 'Page', 'Profile', 'TemplateFactory',
            'ChatConnectionService', 'ResponsiveService', 'events',
            function ($rootScope, $stateParams, Page, Profile, TemplateFactory, ChatConnectionService,
                      ResponsiveService, events) {

                const ctrl = this;
                let destroyUnHideWatcher, destroyOnCatExit;


                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else if (TemplateFactory.getTemplate().key !== 'native') {
                    Page.showBackButton('home');
                }
                Page.setTitle(Profile.getProfile().ChatModuleName);


                if ($stateParams.data.Name) {
                    ctrl.newChannelData = {
                        user: {
                            name: $stateParams.data.Name,
                            userId: $stateParams.data.UserToken,
                        },
                        appLinkId: $stateParams.data.AppLinkId,
                        hideAuthor: $stateParams.data.HideAuthor
                    };
                }
                if ($stateParams.data.ChannelId) {
                    ctrl.selectedChannel = $stateParams.data.ChannelId;
                }


                if (!ResponsiveService.isDesktop()) {
                    $rootScope.HideProfileButton = true;
                }
                $rootScope.ShowCreateChat = true;
                destroyUnHideWatcher = $rootScope.$on(events.APP_UNHIDE, () => reconnect());

                function reconnect() {
                    ChatConnectionService && !ChatConnectionService.isConnected() && ChatConnectionService.reconnect();
                }

                destroyOnCatExit = $rootScope.$on('$stateChangeSuccess', function (event, toState) {
                    if (toState.name !== 'chat') {
                        ChatConnectionService.destroy();
                        $rootScope.ShowCreateChat = false;
                        $rootScope.HideProfileButton = false;
                        destroyOnCatExit();
                        destroyUnHideWatcher && destroyUnHideWatcher();
                    }
                });
            }
        ]);
})();