(() => {
    'use strict';

    angular
        .module('App')
        .component('contentLibraryList', {
            template: require('./ContentLibraryList.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', 'BookmarkService', 'ContentWrapperService',
                'ActionSheetService', 'RemindersService', 'MODULE_ID', 'CopyLinkService', ContentLibraryListController],
            bindings: {
                contentData: '<',
                onOpen: '<',
                onLoadMore: '<',
                showReminder: '<'
            }
        });

    function ContentLibraryListController($element, BookmarkService, ContentWrapperService,
                                          ActionSheetService, RemindersService, MODULE_ID, CopyLinkService) {
        var ctrl = this,
            isBookmarkUpdating = false;

        ctrl.BookmarkService = BookmarkService;
        ctrl.settings = ctrl.contentData.Settings;
        ctrl.prefetTranslated = ctrl.settings.AllowAutomaticTranslation && ctrl.settings.PreferTranslatedPosts;

        ctrl.OpenItem = ctrl.onOpen;
        ctrl.$onInit = onInit;
        ctrl.openReminder = openReminder;
        ctrl.ShowComments = showComments;
        ctrl.ToggleBookmark = toggleBookmark;
        ctrl.showOptions = showOptions;
        ctrl.getButtonStatus = getButtonStatus;
        ctrl.selectCurrentLocale = selectCurrentLocale;

        function onInit() {
            if (ctrl.contentData.Settings.SwipeToRead && ctrl.contentData.Settings.DefaultSortTypeId === 1) {
                ctrl.contentData.Items = _.sortBy(ctrl.contentData.Items, function (item) {
                    return item.readingTotal >= 99 || item.readingProgress >= 99;
                })
            }
        }

        function selectCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale
        }

        function showComments(item) {
            ContentWrapperService.showCommentsModal(item, ctrl.contentData.Settings, null, ctrl.contentData.AccountModuleToken)
        }

        function toggleBookmark(item) {
            if (!isBookmarkUpdating) {
                if (item.BookmarkId) {
                    // Delete
                    isBookmarkUpdating = true;
                    BookmarkService.deleteBookmark(item.BookmarkId).then(success => {
                        if (success) {
                            isBookmarkUpdating = false;
                            item.BookmarkId = null;
                        }
                    });
                } else {
                    // Create
                    isBookmarkUpdating = true;
                    BookmarkService.createBookmark(
                        item.AccountModuleId,
                        MODULE_ID.CONTENT,
                        item.ContentId,
                        selectCurrentLocale(item.TranslatableEntity).Title
                    ).then(bookmarkId => {
                        if (bookmarkId) {
                            isBookmarkUpdating = false;
                            item.BookmarkId = bookmarkId;
                        }
                    });
                }
            }
        }

        function openReminder(item) {
            RemindersService.openSetReminders(
                ctrl.contentData.AccountModuleToken,
                item.ContentId,
                MODULE_ID.CONTENT,
                _.get(item, 'Reminder.ReminderDateTimeLocal') ?
                    moment(_.get(item, 'Reminder.ReminderDateTimeLocal')).toDate() : null,
                function (reminder) {
                    item.Reminder = reminder;
                })
        }

        function showOptions(event) {
            const buttons = [{
                text: 'COPY_LINK',
                icon: 'link',
                onClick: () => {
                    copyContentLibraryLink(event);
                }
            }];

            ActionSheetService
                .create(buttons, event.currentTarget, null, {
                    name: 'content-library',
                    token: ctrl.contentData.AccountModuleToken
                })
                .show();
        }

        function copyContentLibraryLink(event) {
            CopyLinkService.copy(
                `/ContentModule/GenerateLink/${event.currentTarget.value}?AccountModuleToken=${ctrl.contentData.AccountModuleToken}`,
                $element.find(".app-link-url")
            );
        }

        function getButtonStatus(item) {
            if (!item.IsSwiped && item.Swipe && item.readingProgress > 0 || (!item.IsSwiped && !item.Swipe && item.readingProgress < 99 && item.readingProgress !== 0)) {
                return 'LIBRARY_VIEW.CONTINUE';
            } else if (item.IsSwiped || (!item.Swipe && item.readingProgress >= 99)) {
                return 'LIBRARY_VIEW.WAS_READ';
            } else {
                return 'LIBRARY_VIEW.READ'
            }
        }

    }
})();