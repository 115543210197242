(function () {
    'use strict';

    angular.module('App')
        .component('bookmarksDropdown', {
            template: require('./BookmarksDropdownComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['BookmarkService', BookmarksDropdownController],
            bindings: {}
        });

    function BookmarksDropdownController(BookmarkService) {
        var ctrl = this;
        ctrl.BookmarkService = BookmarkService;
    }
})();