(() => {
    'use strict';

    angular
        .module('App')
        .component('filterFillings', {
            template: require('./FilterFillingsComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$filter', 'PopupWrapperService', 'ServiceFormRestService', 'ServiceFormRenderService', 'TimeLocale',
                'ToastFactory', 'FILLING_FILTER_TYPE', 'DATALIST_TYPE', FilterFillingsController],
            bindings: {
                popupId: '<',
                filters: '<',
                onGetNewFillings: '<',
                onUpdateAppliedFilters: '<',
                performanceRanges: '<',
                maxPossibleFillingScore: '<',
                stages: '<',
                serviceFormId: '<',
                statuses: '<'
            }
        });

    function FilterFillingsController($scope, $filter, PopupWrapperService, ServiceFormRestService,
                                      ServiceFormRenderService, TimeLocale, ToastFactory, FILLING_FILTER_TYPE, DATALIST_TYPE) {
        var ctrl = this, initialFilters;
        let currentFilters = [];

        ctrl.performanceRanges = ctrl.performanceRanges || [];

        ctrl.$onInit = init;
        ctrl.lazyLoadItems = lazyLoadItems;
        ctrl.updateAppliedFiltersCount = updateAppliedFiltersCount;
        ctrl.getFieldDataList = getFieldDataList;
        ctrl.resetFilters = resetFilters;
        ctrl.onClose = onClose;
        ctrl.onOpen = onOpen;
        ctrl.applyFilters = applyFilters;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.wasFiltered = false;
            ctrl.EntityNamePlural = ServiceFormRenderService.getLabelSettings().TranslatedEntityNamePlural || ServiceFormRenderService.getLabelSettings().EntityNamePlural;
            ctrl.FILLING_FILTER_TYPE = FILLING_FILTER_TYPE;
            ctrl.DATALIST_TYPE = DATALIST_TYPE;
            ctrl.stagesList = getStagesList();
            ctrl.statuses = getStatusesList();
            ctrl.feedbackStatuses = getFeedbackStatusesList();
            ctrl.feedbackRating = getFeedbackRating();

            ctrl.filters.forEach(function (filter) {
                filter.inputModel = [];
                filter.model = [];

                if (filter.FilterType === FILLING_FILTER_TYPE.DRAFTS) {
                    filter.model = true;
                } else if (filter.FilterType === FILLING_FILTER_TYPE.NEW_REPORTS) {
                    filter.model = false;
                } else if (filter.FilterType === FILLING_FILTER_TYPE.FILLING_SCORE) {
                    filter.modelMax = ctrl.maxPossibleFillingScore;
                }
            });

            initialFilters = _.cloneDeep(ctrl.filters);
            currentFilters = initialFilters;

            ServiceFormRestService.applyFilters(formatData(ctrl.filters));
            watchCascade();
        }

        function onOpen() {
            currentFilters = _.cloneDeep(ctrl.filters);
        }

        function formatData(filters) {
            var outputFilters = {
                DataListFilters: [],
                CascadingFilters: []
            };

            filters.forEach(function (filter) {
                switch (filter.FilterType) {
                    case FILLING_FILTER_TYPE.DATA_LIST:
                        if (filter.ServiceFormFieldId) {
                            outputFilters.DataListFilters = outputFilters.DataListFilters.concat(
                                filter.model.map(function (item) {
                                    return {
                                        ServiceFormFieldId: filter.ServiceFormFieldId,
                                        DataListItemId: item.DataListItemId
                                    }
                                }));
                        } else if (filter.CascadinglyFilteredServiceFormFieldId) {
                            outputFilters.CascadingFilters = outputFilters.CascadingFilters.concat(
                                filter.model.map(function (item) {
                                    return {
                                        CascadinglyFilteredServiceFormFieldId: filter.CascadinglyFilteredServiceFormFieldId,
                                        DataListItemId: item.DataListItemId
                                    }
                                }));
                        }
                        break;
                    case FILLING_FILTER_TYPE.ONLY_MY_FILLINGS:
                        outputFilters.OnlyMyFillings = filter.model;
                        break;
                    case FILLING_FILTER_TYPE.ONLY_MY_AREA:
                        outputFilters.OnlyMyArea = filter.model;
                        break;
                    case FILLING_FILTER_TYPE.CREATED_BY_USER_LIST:
                        outputFilters.CreatedByUserList = mapDataList(filter.model);
                        break;
                    case FILLING_FILTER_TYPE.CREATED_BY_DEPARTMENT_LIST:
                        outputFilters.CreatedByDepartmentList = mapDataList(filter.model);
                        break;
                    case FILLING_FILTER_TYPE.CREATED_BY_USER_GROUP_LIST:
                        outputFilters.CreatedByUserGroupList = mapDataList(filter.model);
                        break;
                    case FILLING_FILTER_TYPE.PERFORMANCE_RANGE:
                        outputFilters.PerformanceRangeList = filter.model && filter.model.length ? filter.model.map(function (item) {
                            return item.ServiceFormPerformanceRangeId;
                        }) : [];
                        break;
                    case FILLING_FILTER_TYPE.FILLING_SCORE:
                        outputFilters.MinFillingScore = filter.model;
                        outputFilters.MaxFillingScore = filter.modelMax;
                        break;
                    case FILLING_FILTER_TYPE.DATE_CREATED:
                        if (_.get(filter, 'model.from')) {
                            outputFilters.DateCreatedFrom = TimeLocale.dateTimeFormat(filter.model.from, 'YYYY-MM-D HH:mm');
                        }
                        if (_.get(filter, 'model.to')) {
                            outputFilters.DateCreatedTo = TimeLocale.dateTimeFormat(filter.model.to, 'YYYY-MM-D HH:mm');
                        }
                        break;
                    case FILLING_FILTER_TYPE.DRAFTS:
                        outputFilters.IncludeDrafts = filter.model;
                        break;
                    case FILLING_FILTER_TYPE.NEW_REPORTS:
                        outputFilters.NewReports = filter.model;
                        break;
                    case FILLING_FILTER_TYPE.STAGES:
                        outputFilters.StagesList = _.map(filter.model, function (stage) {
                            return stage.ServiceFormStageId
                        })
                        break;
                    case FILLING_FILTER_TYPE.FEEDBACK_RATING:
                        outputFilters.FeedbackRangeList = filter.model.map(item => item.ServiceFormFeedbackRangeId)
                        break;
                    case FILLING_FILTER_TYPE.FEEDBACK_STATUS:
                        outputFilters.HasFeedback = _.get(filter.model[0], 'HasFeedback')
                        break;
                    case FILLING_FILTER_TYPE.CHOOSE_STATUS:
                        outputFilters.StatusId = filter.model[0]?.StatusId
                        break;
                }
            });

            return outputFilters;
        }

        function mapDataList(model) {
            return model.map(function (item) {
                return item.DataListItemId;
            })
        }

        function resetFilters() {
            ctrl.filters = _.cloneDeep(initialFilters);
            currentFilters = ctrl.filters;

            ctrl.performanceRanges.forEach(function (item) {
                item.selected = false;
            });

            ctrl.feedbackRating.forEach(function (item) {
                item.selected = false;
            });
        }

        function watchCascade() {
            var cascadeFilters = _.filter(ctrl.filters, function (item) {
                return !_.isNull(item.CascadinglyFilteredServiceFormFieldId)
            });

            _.forEach(cascadeFilters, function (filter) {
                $scope.$watch(function () {
                    return filter.model
                }, function (newVal, oldVal) {
                    var filterField = _.find(ctrl.filters, {
                        'ServiceFormFieldId': filter.CascadinglyFilteredServiceFormFieldId
                    });
                    filterField.inputModel = [];
                    filterField.model = [];

                    filterField.dataFilter = newVal;
                });
            });
        }

        function lazyLoadItems(filter) {
            if (filter.DataListId) {
                return ServiceFormRestService.loadDataList(filter.DataListId, filter.ServiceFormFieldId, false).then(function (data) {
                    return data.Items;
                });
            } else {
                return ServiceFormRestService.loadDataListByType(filter.DataListType).then(function (data) {
                    return data.Items;
                });
            }
        }

        function applyFilters(filters) {
            currentFilters = _.cloneDeep(filters);
            const formattedData = formatData(filters);

            ServiceFormRestService.loadFilteredFillings(formattedData, ctrl.serviceFormId).then(function (data) {
                if (data.Fillings.length > 0) {
                    ctrl.wasFiltered = true;
                    ctrl.onGetNewFillings(data);
                    PopupWrapperService.getPopup(ctrl.popupId).close();
                    ctrl.updateAppliedFiltersCount();
                } else {
                    ToastFactory.warning($filter('translate')('SERVICEFORM.FILTER.EMPTYLIST'));
                }
            });
        }

        function updateAppliedFiltersCount() {
            var count = 0;
            ctrl.filters.map(function (filter) {
                count = filter.model.length ? count + 1 : count;
            });

            ctrl.onUpdateAppliedFilters(count);
        }


        function getFieldDataList(id) {
            if (id) {
                return ServiceFormRenderService.getFieldDataList(id);
            }
        }

        function getStagesList() {
            return _.map(ctrl.stages, function (stage) {
                return {
                    Title: stage.Title,
                    DataListItemId: stage.ServiceFormStageId,
                    ServiceFormStageId: stage.ServiceFormStageId
                }
            })
        }

        function getStatusesList() {
            return ctrl.statuses.map(({
                                          Title,
                                          Label,
                                          ServiceFormStatusId
                                      }, index) => ({
                Title: Title || $filter('translate')(Label),
                DataListItemId: index,
                StatusId: ServiceFormStatusId
            }));
        }

        function getFeedbackStatusesList() {
            return [
                {
                    Title: $filter('translate')('SERVICEFORM.FEEDBACK.HAS_FEEDBACK'),
                    DataListItemId: 1,
                    HasFeedback: true
                },
                {
                    Title: $filter('translate')('SERVICEFORM.FEEDBACK.NEEDS_FEEDBACK'),
                    DataListItemId: 2,
                    HasFeedback: false
                }
            ]
        }

        function getFeedbackRating() {
            return ServiceFormRenderService.getFeedbackRange().map(feedbackRange => ({
                Title: `${feedbackRange.MinRange} - ${feedbackRange.MaxRange}`,
                Icon: feedbackRange.Icon,
                ServiceFormFeedbackRangeId: feedbackRange.ServiceFormFeedbackRangeId,
            }))
        }

        function onClose(data, popup) {
            ctrl.filters = _.cloneDeep(currentFilters);
            popup.hide()
        }

        function destroy() {
            ServiceFormRestService.destroyData();
        }
    }
})();