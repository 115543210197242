(function () {
    'use strict';
    angular.module('App').factory('UserManagementService', [
        '$stateParams', '$http', 'PopupWrapperService', 'ConfirmPopupService',
        function ($stateParams, $http, PopupWrapperService, ConfirmPopupService) {
            return {
                validate: validate,
                openFiltersPopup: openFiltersPopup,
                approveUser: approveUser,
                declineUser: declineUser,
                getCleanFilterModel: getCleanFilterModel,
                getSortingOptions: getSortingOptions,
                getActivityOptions: getActivityOptions
            }

            function validate(model, settings) {
                const validationData = [];

                !model.Name &&
                validationData.push({
                    Message: "USER_MANAGEMENT.ERROR.NAME_REQUIRED",
                    Name: "Name",
                    Type: 2
                });

                model.SendLoginInfoSms && !model.Phone &&
                validationData.push({
                    Message: "USER_MANAGEMENT.ERROR.PHONE_REQUIRED",
                    Name: "Phone",
                    Type: 2
                });

                model.SendLoginInfoEmail && !model.Email &&
                validationData.push({
                    Message: "USER_MANAGEMENT.ERROR.EMAIL_REQUIRED",
                    Name: "Email",
                    Type: 2
                });

                if (!settings.DefaultRoleList.length) {
                    (!model.UserGroups || model.UserGroups.length === 0) &&
                    validationData.push({
                        Message: "USER_MANAGEMENT.ERROR.USERGROUP_REQUIRED",
                        Name: "UserGroups",
                        Type: 2
                    });
                }

                !model.DepartmentId &&
                validationData.push({
                    Message: "USER_MANAGEMENT.ERROR.DEPARTMENT_REQUIRED",
                    Name: "DepartmentId",
                    Type: 2
                });

                return validationData;
            }

            function openFiltersPopup(allowedDepartments, filterModel, onFiltered, activityManagerViewEnabled) {
                PopupWrapperService.createDynamic(
                    `<user-management-filters-popup 
                                        on-filtered="onFiltered"
                                        allowed-departments="allowedDepartments"
                                        model="filterModel"
                                        activity-manager-view-enabled="activityManagerViewEnabled"
                                   ></user-management-filters-popup>`,
                    {
                        onFiltered: onFiltered,
                        allowedDepartments: allowedDepartments,
                        filterModel: filterModel,
                        activityManagerViewEnabled: activityManagerViewEnabled
                    }
                )
            }

            function approveUser(userToken) {
                return $http.post('/UserManagement/ApproveDeclineUser', {
                    AccountModuleToken: $stateParams.token,
                    UserToken: userToken,
                    Approve: true
                }).then(resp => resp.data)
            }

            function declineUser(userToken) {
                return ConfirmPopupService.open({
                    title: 'USER_MANAGEMENT.DECLINE_APPLICATION',
                    message: 'USER_MANAGEMENT.DECLINE_TEXT',
                    yesText: 'USER_MANAGEMENT.DECLINE',
                    noText: 'CANCEL'
                }).then(() => {
                    return $http.post('/UserManagement/ApproveDeclineUser', {
                        AccountModuleToken: $stateParams.token,
                        UserToken: userToken,
                        Decline: true
                    }).then(resp => resp.data)
                })
            }

            function getCleanFilterModel() {
                return {
                    sorting: {
                        SortProperty: getSortingOptions()[0]
                    },
                    filters: {
                        ActivityStatus: getActivityOptions()[0]
                    }
                }
            }

            function getSortingOptions() {
                return [
                    {id: 0, name: 'USER_MANAGEMENT.FILTERS.NAME', value: 1, icon: 'user', class: ''},
                    {id: 1, name: 'USER_MANAGEMENT.FILTERS.ACTIVITY', value: 2, icon: 'clock', class: ''},
                ]
            }

            function getActivityOptions() {
                return [
                    {id: 0, name: 'USER_MANAGEMENT.FILTERS.ALL', value: null, icon: 'clock', prefix: 'fas', class: 'all'},
                    {id: 1, name: 'USER_MANAGEMENT.FILTERS.ACTIVE_TODAY', value: 1, icon: 'clock', prefix: 'fas', class: 'today'},
                    {id: 2, name: 'USER_MANAGEMENT.FILTERS.ACTIVE_LAST_WEEK', value: 2, icon: 'clock', prefix: 'fas', class: 'week'},
                    {id: 3, name: 'USER_MANAGEMENT.FILTERS.ACTIVE_LAST_MONTH', value: 3, icon: 'clock', prefix: 'fas', class: 'month'},
                    {id: 4,name: 'USER_MANAGEMENT.FILTERS.HAD_ACTIVITY',value: 4,icon: 'clock', prefix: 'fas',class: 'had-activity'},
                    {id: 5, name: 'USER_MANAGEMENT.FILTERS.NO_ACTIVITY', value: 5, icon: 'clock', prefix: 'fas', class: 'no-activity'}
                ]
            }
        }]);
})();
