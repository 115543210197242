(function () {
    'use strict';

    angular
        .module('App')
        .component('fillingList', {
            template: require('./FillingListComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['ServiceFormRestService', FillingListController],
            bindings: {
                fillings: '<',
                hasMoreFillings: '=',
                isMultipleColumn: '<',
                noFillingsLabel: '<',
                hasPerformanceRanges: '<',
                serviceFormId: '<'
            }
        });

    function FillingListController(ServiceFormRestService) {
        let ctrl = this;

        ctrl.$onInit = init;
        ctrl.paging = paging;

        function init() {
            ctrl.isLoadingData = false;
        }

        function paging() {
            if (!ctrl.isLoadingData) {
                ctrl.isLoadingData = true;
                ServiceFormRestService.lazyLoadFillings(12, ctrl.fillings.length, ctrl.serviceFormId).then(function (data) {
                    ctrl.fillings = ctrl.fillings.concat(data.Fillings);
                    ctrl.hasMoreFillings = data.HasMoreFillings;
                    ctrl.isLoadingData = false;
                });
            }
        }
    }
})();